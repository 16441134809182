/* eslint-disable import/no-cycle */
import { ChangeEventHandler, useEffect, useState } from 'react';
import { Box, Select, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { QueryItem, TreatmentTypeSetting } from 'data/generated/graphql';
import {
  AllessehContentQueryBody,
  AllessehQueryRuleTerm,
  AllessehQueryRuleTerms,
  DEFAULT_TOPICS_QUERY
} from 'hooks/useAllessehContentQuery';
import { TopicsResponse, useTopicsQuery } from 'hooks/useTopicsQuery';
import { safelyParseContent } from 'utils/temp';
import styles from './Feed.module.scss';
import { QueryProps } from '../Query';

enum BarronsTerms {
  SubjectCode = 'SubjectCode',
  BarronsDisplayBrand = 'BarronsDisplayBrand'
}

const DEFAULT_TOPIC = {
  url: '',
  seoDisplayName: '',
  djmlKeyword: '',
  subject: false,
  brand: false
};

interface FeedProps extends QueryProps {
  hierarchyId: string;
  isHistory: boolean;
  treatmentTypeSetting?: TreatmentTypeSetting;
  // modifyEntity: (hierarchyId: string, callback: (entity: QueryItem) => void) => void;
}

// TODO: this component is very barrons specific, when more feeds are added, this should be refactored
export const Feed = ({ data: query, hierarchyId, isHistory, onContentLoaded, treatmentTypeSetting }: FeedProps) => {
  const parsedQuery: AllessehContentQueryBody = query.attributes.query;
  const [filteredOptions, setFilteredOptions] = useState<TopicsResponse[]>([]);
  const [option, setOption] = useState<TopicsResponse>(DEFAULT_TOPIC);
  const { data: topicsData } = useTopicsQuery();
  const { modifyEntity } = useDataModelContext();
  // const hierarchyId = generateHierarchyId(query, parentHierarchyId, index);
  useEffect(() => {
    if (topicsData) {
      setFilteredOptions(topicsData);
      const option = parsedQuery.query?.and?.find((item) => {
        const termsItem = item as AllessehQueryRuleTerms;
        const termItem = item as AllessehQueryRuleTerm;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (termItem.term) {
          return [BarronsTerms.SubjectCode, BarronsTerms.BarronsDisplayBrand].includes(
            termItem.term.key as BarronsTerms
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (termsItem.terms) {
          return [BarronsTerms.SubjectCode, BarronsTerms.BarronsDisplayBrand].includes(
            termsItem.terms.key as BarronsTerms
          );
        }
        return null;
      });
      setOption(
        topicsData.find((topic) => {
          if (option) {
            if ('term' in option) {
              return option.term.value === topic.djmlKeyword;
            }
            if ('terms' in option) {
              return option.terms.value.join(',') === topic.djmlKeyword;
            }
          }
          return null;
        }) ?? topicsData[0]
      );
    }
  }, [parsedQuery, parsedQuery.query?.and, topicsData]);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selectedOption = filteredOptions.find((option) => option.djmlKeyword === e.target.value) as TopicsResponse;
    setOption(selectedOption);
    const parsedTopicsQuery: AllessehContentQueryBody = safelyParseContent(DEFAULT_TOPICS_QUERY);
    const termKey = selectedOption.brand ? BarronsTerms.BarronsDisplayBrand : BarronsTerms.SubjectCode;
    parsedTopicsQuery.query?.and?.push({ terms: { key: termKey, value: e.target.value.split(',') } });
    const newJsonQueryStr = JSON.stringify(parsedTopicsQuery);
    modifyEntity(hierarchyId, (entity) => {
      const query = entity as QueryItem;
      query.attributes.isFeed = true;
      query.attributes.feedTitle = selectedOption.seoDisplayName;
      query.attributes.feedUrl = selectedOption.url;
      query.attributes.query = JSON.parse(newJsonQueryStr) as Record<string, unknown>;
      // default count to 5 as we don't have a way rn to determine the count of items in a feed
      query.attributes.query.count = 5;
    });
  };

  useEffect(() => {
    if (onContentLoaded) {
      onContentLoaded(0, treatmentTypeSetting?.maxRequiredContentItems ?? 0);
    }
  }, [onContentLoaded, treatmentTypeSetting?.maxRequiredContentItems]);

  return (
    <Box data-model-hierarchy-id={hierarchyId} className={styles.cardContainer}>
      <Wrapper padding={{ all: 'sm' }} className={styles.cardHeader}>
        <Select value={option.djmlKeyword} onChange={handleChange} disabled={isHistory}>
          {filteredOptions.map((option) => (
            <option key={option.seoDisplayName} value={option.djmlKeyword}>
              {option.seoDisplayName}
            </option>
          ))}
        </Select>
      </Wrapper>
    </Box>
  );
};

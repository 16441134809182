import { Button, Token, Typography, Wrapper } from '@screentone/core';

import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { PublicationSetting } from 'data/generated/graphql';
import styles from './TitleHeader.module.scss';
import { NewsletterScheduleDialog } from '../newsletter-schedule-dialog/NewsletterScheduleDialog';
import { NewsletterTestDialog } from '../newsletter-send-test-dialog/NewsletterSendTestDialog';

interface TitleHeaderProps {
  newsletterName?: string;
  readOnly: boolean;
  isScheduled?: boolean;
  newsletterId?: string;
  handleSendNowClick?: () => void;
  handleScheduleClick: (publishUtc: number) => void;
  handleSendTestClick: (emails: string) => void;
  publicationSetting?: PublicationSetting;
}

export const TitleHeader = ({
  newsletterName,
  handleSendNowClick,
  readOnly,
  handleScheduleClick,
  isScheduled = false,
  handleSendTestClick,
  newsletterId,
  publicationSetting
}: TitleHeaderProps) => (
  <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.titleHeader}>
    <div className={styles.row} data-testid="newsletter-title">
      <Typography variant="header2" margin={{ bottom: 'none' }} data-testid="newsletter-title-header">
        {newsletterName}
      </Typography>
      {readOnly && <Token color={isScheduled ? 'blurple' : 'emerald'}>{isScheduled ? 'Schedule' : 'Sent'}</Token>}
    </div>
    <div className={styles.row}>
      <ExternalLink
        contentId={newsletterId ?? ''}
        type={ExternalLinkContentType.COLLECTION}
        isPublished={readOnly && !!isScheduled}
      />
      {(!readOnly || isScheduled) && (
        <>
          <NewsletterTestDialog sendTestEmail={handleSendTestClick} />
          <Button secondary onClick={handleSendNowClick} data-testid="newsletter-send-now">
            Send Now
          </Button>
          <NewsletterScheduleDialog
            reschedule={isScheduled}
            handleSchedule={handleScheduleClick}
            publicationSetting={publicationSetting}
          />
        </>
      )}
    </div>
  </Wrapper>
);

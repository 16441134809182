import { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Button, IconEye, IconRefresh, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { useIssue } from 'contexts/issue/useIssue';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import {
  AvailableLayoutModule,
  ItpIssuePageType,
  ItpPage,
  Newsletter,
  Page,
  PageModule,
  PageTypeSetting,
  UiModuleType
} from 'data/generated/graphql';
import { NumberOrNothing } from 'features/issues/types';
import { DISPLAY_KEY as ITP_SECTION_DISPLAY_KEY } from 'features/issues/util';
import { DRAGGABLE_PREFIXES, DROPPABLE_ID_PREFIXES, DROPPABLE_TYPES } from 'features/page-edit/dragUtils';
import { usePagePreview } from 'features/page-edit/hooks/usePagePreview';
import { useElementDimensions } from 'hooks/dom/useElementDimensions';
import { AddModuleButton } from './components/add-module-button/AddModuleButton';
import { ITPPageNameChange } from './components/itp-page-name-change/ITPPageNameChange';
import { PageModuleList } from './components/page-module-list/PageModuleList';
import styles from './PageDraftSection.module.scss';
import { deDupePage } from './PageDraftSectionUtils';
import pageEditStyles from '../../PageEdit.module.scss';

interface PageDraftSectionProps {
  page?: Page;
  itpPage?: ItpPage;
  newsletter?: Newsletter;
  droppableId?: string;
  disableAltSumm?: boolean;
  pageTypeSetting?: PageTypeSetting;
  pageIndex?: number;
  isNewsletter?: boolean;
  removeITPModuleItem?: ({
    moduleListIndex,
    pageIndex,
    newPageModule
  }: {
    moduleListIndex: NumberOrNothing;
    pageIndex: NumberOrNothing;
    newPageModule: PageModule;
  }) => void;
}

const getAllUiModuleTypes = (pageModules?: PageModule[]): UiModuleType[] =>
  (pageModules ?? []).reduce<UiModuleType[]>((acc, curr) => {
    if (curr.uiModuleType === UiModuleType.UiTwoColumnModuleType) {
      return acc
        .concat(getAllUiModuleTypes(curr.uiModuleFields.twoColumnModule?.leftModules))
        .concat(getAllUiModuleTypes(curr.uiModuleFields.twoColumnModule?.rightModules));
    }
    return acc.concat([curr.uiModuleType]);
  }, []);

export const PageDraftSection = ({
  itpPage,
  droppableId,
  isNewsletter,
  disableAltSumm,
  newsletter,
  page,
  pageTypeSetting,
  pageIndex,
  removeITPModuleItem
}: PageDraftSectionProps) => {
  if (page && itpPage) {
    throw new Error('You cannot pass both a `page` prop and an `itpPage` prop to <PageDraftSection>');
  }

  const [isWrapperHeightGreaterThanMaxHeight, setIsWrapperHeightGreaterThanMaxHeight] = useState(false);
  const { handlePreviewPage, isLoading: isPreviewPageLoading } = usePagePreview();
  const { handlePartialPageChange, setAllContentIds } = usePagePublish();
  const { issue, handlePartialIssueChange } = useIssue();
  const { elementRef, dimensions } = useElementDimensions<HTMLDivElement>();
  const isITP = !!itpPage;
  const treatmentTypeSettings = pageTypeSetting?.treatmentTypeSettings;
  const currentLayout = isITP
    ? pageTypeSetting?.layouts[0]
    : page && pageTypeSetting?.layouts.filter((l) => l.id === page.layoutId)[0];
  const allUsedUiModuleTypes = getAllUiModuleTypes(page?.pageModules ?? []);
  const isMobileAppScreen = page?.isMobileAppScreen;

  let addableUiModules: AvailableLayoutModule[] | undefined;
  let removableUiModuleTypes: UiModuleType[];
  if (isITP) {
    addableUiModules = currentLayout?.availableLayoutModules;
    removableUiModuleTypes = [UiModuleType.UiBasicModuleType];
  } else {
    addableUiModules = currentLayout?.availableLayoutModules.filter((availableModule) => {
      const availableUiModuleType = availableModule.uiModuleType;
      const usedUiModulesForType = allUsedUiModuleTypes.filter(
        (usedUiModuleType) => usedUiModuleType === availableUiModuleType
      );
      return usedUiModulesForType.length < availableModule.maximumAllowed;
    });
    const removableUiModules = [
      ...(currentLayout?.availableLayoutModules.filter((availableModule) => {
        const availableUiModuleType = availableModule.uiModuleType;
        const usedUiModulesForType = allUsedUiModuleTypes.filter(
          (usedUiModuleType) => usedUiModuleType === availableUiModuleType
        );
        return usedUiModulesForType.length > availableModule.minimumAllowed;
      }) ?? []),
      ...(currentLayout?.availableRequiredLayoutModules ?? [])
    ];
    removableUiModuleTypes = removableUiModules.map((availableUiModule) => availableUiModule.uiModuleType);
  }

  useEffect(() => {
    if (!elementRef.current) return;
    const maxHeightStr = getComputedStyle(elementRef.current).maxHeight.replace('px', '');
    const maxHeight = Number(maxHeightStr);
    if (dimensions.scrollHeight > maxHeight) {
      setIsWrapperHeightGreaterThanMaxHeight(true);
    } else {
      setIsWrapperHeightGreaterThanMaxHeight(false);
    }
  }, [dimensions.scrollHeight, elementRef]);

  const handleResetToDefault = () => {
    handlePartialPageChange({ pageModules: currentLayout?.requiredLayoutModules as PageModule[] });
    setAllContentIds(() => ({}));
  };

  const handlePageModuleListChange = (newPageModuleList: PageModule[]) => {
    if (isITP && itpPage.pageType) {
      const issuePages = issue!.pages;
      const itpPageIndex = ITP_SECTION_DISPLAY_KEY[itpPage.pageType].order - 1;
      issuePages[itpPageIndex].pageModules = newPageModuleList;
      handlePartialIssueChange({ pages: issuePages });
    } else {
      handlePartialPageChange({ pageModules: newPageModuleList });
    }
  };

  const deDupe = () => {
    if (page) {
      const deDupedPage = deDupePage(page);
      handlePartialPageChange(deDupedPage);
    }
  };

  const pageModules: PageModule[] = page?.pageModules ?? itpPage?.pageModules ?? newsletter?.pageModules ?? [];

  return (
    <div data-testid="page-draft-section-container">
      <Wrapper data-testid="page-draft-section-header" margin={{ bottom: 'sm' }} className={styles.topHeaderWrapper}>
        {itpPage && !page && (
          <ITPPageNameChange
            data-testid="page-draft-section-header-type"
            pageType={itpPage.pageType}
            itpPage={itpPage}
          />
        )}
        {page && !isITP && !isNewsletter && !isMobileAppScreen && (
          <Wrapper data-testid="page-draft-section-title" className={styles.topLeftHeader}>
            <Typography data-testid="page-draft-section-title-label" variant="header3" margin={{ bottom: 'none' }}>
              {page.publishUtc ? `Editing: ${format(page.publishUtc, 'MMM d, yyyy h:mm aaa z')}` : 'New Page'}
            </Typography>
          </Wrapper>
        )}
        {!isITP && !page?.isMobileAppScreen && !isNewsletter && (
          <Wrapper data-testid="page-draft-section-actions">
            <Button
              data-testid="page-draft-section-dedupe-button"
              tertiary
              className={styles.dedupeButton}
              icon={IconRefresh as SvgComponent}
              onClick={deDupe}
            >
              Dedupe
            </Button>
            <Button
              data-testid="page-draft-section-preview-button"
              tertiary
              icon={IconEye as SvgComponent}
              onClick={() => handlePreviewPage(page as Page)}
              disabled={isPreviewPageLoading}
            >
              Preview
            </Button>
          </Wrapper>
        )}
      </Wrapper>
      <Wrapper data-testid="page-draft-section-body" className={styles.moduleList}>
        <div
          data-testid="page-draft-section-background"
          className={isNewsletter ? styles.moduleListBackground + styles.noColor : styles.moduleListBackground}
        />
        <div
          data-testid="page-draft-section-scrollable"
          className={classnames(
            itpPage ? '' : pageEditStyles.draftScrollable,
            isWrapperHeightGreaterThanMaxHeight ? styles.wrapperOverflow : ''
          )}
          ref={elementRef}
        >
          <Droppable
            droppableId={itpPage && droppableId ? droppableId : DROPPABLE_ID_PREFIXES.MODULES}
            type={DROPPABLE_TYPES.MODULE}
          >
            {(droppableProvided) => (
              <div
                data-testid="page-draft-section-content"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className={styles.droppableContainer}
              >
                <PageModuleList
                  isNewsletter={isNewsletter}
                  isMobileAppScreen={page?.isMobileAppScreen}
                  data-testid="page-module-item-list"
                  isITP={isITP}
                  itpPageType={itpPage?.pageType as ItpIssuePageType}
                  pageIndex={pageIndex}
                  disableAltSumm={disableAltSumm}
                  pageModules={pageModules}
                  onPageModuleListChange={handlePageModuleListChange}
                  removableUiModuleTypes={removableUiModuleTypes}
                  layout={currentLayout}
                  treatmentTypeSettings={treatmentTypeSettings}
                  droppableModuleItemListPrefix={
                    (itpPage && droppableId ? `${droppableId}-` : '') + DROPPABLE_ID_PREFIXES.MODULE_ITEMS
                  }
                  draggableModuleItemPrefix={
                    (itpPage && droppableId ? `${droppableId}-` : '') + DRAGGABLE_PREFIXES.MODULE_ITEM
                  }
                  addableUiModules={addableUiModules}
                  removeITPModuleItem={removeITPModuleItem}
                />
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
          {!isITP && !isNewsletter && (
            <div data-testid="page-draft-section-footer" className={styles.buttonsSection}>
              <AddModuleButton addableUiModules={addableUiModules} pageModules={pageModules} />
              <Button
                data-testid="page-reset-default-button"
                tertiary
                icon={IconRefresh as SvgComponent}
                color="lava"
                onClick={handleResetToDefault}
              >
                Reset to default
              </Button>
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

import { Button, IconArrowUp, Wrapper } from '@screentone/core';
import { isEqual } from 'lodash';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ArticleItem } from 'data/generated/graphql';
import { AllessehContent, AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
import { QUERY_CONTENT_ID_KEY } from 'utils/queryUtils';

interface MoveQueryArticleToManualArticleProps {
  article: ArticleItem;
  hierarchyId: string;
  jsonQueryStr: string;
  allessehContent: AllessehContent;
  setJsonQueryStr: (newQueryStr: string) => void;
}

export const MoveQueryArticleToManualArticle = ({
  article,
  hierarchyId,
  jsonQueryStr,
  allessehContent,
  setJsonQueryStr
}: MoveQueryArticleToManualArticleProps) => {
  const { insertEntity } = useDataModelContext();

  const handleExclude = () => {
    const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
    parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
    parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
    const newTerm = {
      term: {
        key: QUERY_CONTENT_ID_KEY,
        value: allessehContent.data.id
      }
    };
    if (parsedJsonQuery.query.not.findIndex((rule: AllessehQueryRule) => isEqual(rule, newTerm)) < 0) {
      parsedJsonQuery.query.not.push(newTerm);
    }

    setJsonQueryStr(JSON.stringify(parsedJsonQuery));
  };

  const handleMove = () => {
    handleExclude();
    insertEntity(hierarchyId, article);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconArrowUp as SvgComponent} onClick={handleMove}>
        Move to Top of List
      </Button>
    </Wrapper>
  );
};

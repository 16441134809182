import { ReactElement } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import classnames from 'classnames';

import { CollectionCard } from 'components/collection-card/CollectionCard';
import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { CollectionDto, CollectionsDtoQuery, PagesQuery } from 'data/generated/graphql';
import { useElementWidth } from 'hooks/dom';
import { AllessehContentQueryResponse } from 'hooks/useAllessehContentQuery';
import styles from './CollectionsList.module.scss';

interface CollectionsListProps {
  className?: string;
  collectionPages: CollectionsDtoQuery[];
  emptyListMessage?: string;
  renderCollectionCardWrapper?: (
    collectionCard: ReactElement,
    collection: CollectionDto,
    index: number
  ) => ReactElement;
  droppableId?: string;
  nextPageProps: {
    fetchNextPage: (
      options?: FetchNextPageOptions | undefined
    ) => Promise<InfiniteQueryObserverResult<PagesQuery | CollectionsDtoQuery | AllessehContentQueryResponse>>;
    isFetchingNextPage: boolean;
    hasNextPage?: boolean;
  };
}

export const CollectionsList = ({
  className,
  collectionPages,
  emptyListMessage,
  renderCollectionCardWrapper,
  droppableId,
  nextPageProps
}: CollectionsListProps) => {
  const { elementRef, elementWidth } = useElementWidth();

  if (collectionPages.length === 0 || collectionPages[0].collectionsDTO.edges.length === 0) {
    return <NoSearchResults title="Collections" bodyText={emptyListMessage} />;
  }

  const collections: CollectionDto[] = collectionPages.reduce((acc: CollectionDto[], curr: CollectionsDtoQuery) => {
    const collections = curr.collectionsDTO.edges.map((collectionEdge) => {
      const { node: collection } = collectionEdge;
      return collection;
    });
    return acc.concat(collections as CollectionDto[]);
  }, []);

  const list = (
    <div
      ref={elementRef}
      className={classnames(styles.collectionList, droppableId ? '' : className)}
      data-testid="collection-list-container"
    >
      {collections.map((collection, index) => (
        <CollectionCard
          key={collection.idCreatedUtc}
          collection={collection}
          containerWidth={elementWidth}
          index={index}
          isOnClickEnabled={!droppableId}
          renderCollectionCardWrapper={renderCollectionCardWrapper}
        />
      ))}
      <InfiniteScrollWaypoint nextPageProps={nextPageProps} />
    </div>
  );

  if (droppableId) {
    return (
      <Droppable droppableId={droppableId}>
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={className}>
            {list}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  return list;
};

/* eslint-disable import/no-cycle */
import { Button, IconMinusCircle, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';

interface RemoveEntityProps {
  hierarchyId: string;
  text: string;
}

export const RemoveEntity = ({ hierarchyId, text }: RemoveEntityProps) => {
  const { removeEntity } = useDataModelContext();

  return (
    <Wrapper>
      <Button
        tertiary
        icon={IconMinusCircle as SvgComponent}
        color="lava"
        data-testid="page-content-card-remove-button"
        onClick={() => removeEntity(hierarchyId)}
      >
        {text}
      </Button>
    </Wrapper>
  );
};

import { Button, Group, IconRefresh, IconTrash, Loader, Wrapper } from '@screentone/core';

import { getIMImageAspectRatio, resizeIMImage } from 'utils/url';
import styles from '../MediaInput.module.scss';
import { MediaTypes } from '../MediaInput.utils';

import { ControlledAltSumm } from 'components/altsumm-modal/AltSummModal.utils';

type Props = {
  computedAspectRatio: number;
  onDelete: () => void;
  onResetPreview: (type: MediaTypes) => void;
  previewUrl: string;
  onUrlLoaded: () => void;
  isUrlLoaded: boolean;
  imgRef: React.RefObject<HTMLImageElement>;
  controlledAltSum: ControlledAltSumm;
  isVisible: boolean;
  previewType?: MediaTypes;
};

function getAltText(controlledAltSum: ControlledAltSumm, previewType?: MediaTypes): string {
  if (!previewType) {
    return '';
  }
  if (previewType === 'video') {
    return controlledAltSum.video[0]?.description;
  }
  if (previewType === 'chart') {
    return controlledAltSum.chart;
  }

  return controlledAltSum.image[0]?.caption;
}

export const MediaInputPreview = ({
  computedAspectRatio,
  onDelete,
  onResetPreview,
  previewUrl,
  controlledAltSum,
  onUrlLoaded,
  isUrlLoaded,
  imgRef,
  previewType,
  isVisible
}: Props) => {
  const shouldResizeAndStyle = ['image', 'chart'].includes(previewType ?? '');
  const altText: string = getAltText(controlledAltSum, previewType);
  const imageAspectRatio = getIMImageAspectRatio(previewUrl);
  const resizedImage = resizeIMImage(previewUrl, {
    width: 270,
    height: imageAspectRatio ? 270 / imageAspectRatio : undefined
  });
  const wrapperStyles = {
    aspectRatio: shouldResizeAndStyle ? imageAspectRatio ?? computedAspectRatio : '16 / 9',
    backgroundColor: shouldResizeAndStyle ? 'transparent' : 'black'
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Wrapper data-testid="media-input-preview" className={styles.imagePreviewWrapper} style={wrapperStyles}>
      {previewType !== 'video' && (
        <img
          data-testid="media-input-preview-image"
          className={styles.imagePreview}
          src={previewType === 'image' ? resizedImage : previewUrl}
          alt={altText}
          onLoad={onUrlLoaded}
          ref={imgRef}
        />
      )}
      {previewType === 'video' && (
        <iframe
          title={altText}
          data-testid="media-input-preview-iframe"
          src={previewUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      {!isUrlLoaded && (
        <Group
          data-testid="media-input-preview-loader"
          align="center"
          valign="center"
          fullWidth
          fullHeight
          className={styles.imageSkeletonLoader}
        >
          <Loader data-testid="media-input-preview-loader-icon" size="lg" />
        </Group>
      )}
      <Wrapper data-testid="media-input-preview-icons" className={styles.previewIcons}>
        <Button
          data-testid="media-input-preview-icon"
          className={styles.previewIcon}
          icon={IconRefresh as SvgComponent}
          margin={{ right: 'sm' }}
          tertiary
          onClick={() => onResetPreview(previewType ?? 'image')}
        />
        <Button
          data-testid="media-input-preview-icon"
          className={styles.previewIcon}
          icon={IconTrash as SvgComponent}
          tertiary
          onClick={onDelete}
        />
      </Wrapper>
    </Wrapper>
  );
};

import { Button, IconPlus, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ModuleContainer } from 'data/generated/graphql';

interface AddEntityProps {
  hierarchyId: string;
  entity: ModuleContainer;
  textDisplay?: string;
}

export const AddEntityToCollection = ({ hierarchyId, entity, textDisplay = 'Add to collection' }: AddEntityProps) => {
  const { insertEntityToCollection } = useDataModelContext();

  return (
    <Wrapper>
      <Button tertiary icon={IconPlus as SvgComponent} onClick={() => insertEntityToCollection(hierarchyId, entity)}>
        {textDisplay}
      </Button>
    </Wrapper>
  );
};

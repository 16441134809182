import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { useAlert } from 'contexts/alert/useAlert';
import {
  DeleteAllessehCollectionInput,
  PagesDtoForCollectionIdQuery,
  useCollectionAllessehDeleteMutation
} from 'data/generated/graphql';

type Variant = 'collections' | 'off-platform';

type UseDeleteCollectionHookProps = {
  collectionId: string;
  variant: Variant;
  pagesForCollection?: PagesDtoForCollectionIdQuery;
};

export const deleteHints = {
  default: 'No connected pages.',
  connected: (pages: string[]) =>
    `Connected to the ${pages.join(
      ', '
    )}. You cannot delete a collection with connected pages. There may be additional pages connected to this collection.`
};

// 3. Test that the variant works as expected
export const useDeleteCollection = ({ collectionId, variant, pagesForCollection }: UseDeleteCollectionHookProps) => {
  const { currentProperty } = useAuth();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();

  const { mutateAsync: deleteAllessehCollectionAsync, isLoading: isDeleteLoading } =
    useCollectionAllessehDeleteMutation();

  const [deleteHint, setDeleteHint] = useState<string>(deleteHints.default);

  const connectedPages = !!pagesForCollection?.pagesDTOForCollectionId.length;
  const handleNavigationAfterDelete = (input: DeleteAllessehCollectionInput) => {
    navigate(`/${input.publicationKey}/${variant}`);
  };

  const deleteCollection = async () => {
    const input: DeleteAllessehCollectionInput = {
      allessehCollectionId: collectionId,
      publicationKey: currentProperty ?? ''
    };
    let deleteResponse;
    try {
      deleteResponse = await deleteAllessehCollectionAsync({ deleteAllessehCollectionInput: input });
    } catch (error) {
      console.error('Failed to delete collection: ', error);
      setDeleteHint('Failed to delete collection');
      throw error;
    }

    if (deleteResponse.deleteAllessehCollection.statusCode === 202) {
      handleNavigationAfterDelete(input);
      setDeleteHint(deleteHints.default);
      // I get here so it gets called
      alertSuccess('Collection deleted successfully, it may take some time for it to reflect in Collection Search.');
    } else {
      alertError('Failed to delete collection.');
      throw new Error('Failed to delete collection.');
    }
  };

  const handleDeleteCollection = async () => {
    await deleteCollection();
    setShowDeleteModal(false);
  };

  useEffect(() => {
    const currentDeleteHint = connectedPages
      ? deleteHints.connected(
          pagesForCollection.pagesDTOForCollectionId.map(({ latestPage }) => latestPage.metadata.name)
        )
      : deleteHints.default;
    if (currentDeleteHint !== deleteHint) {
      setDeleteHint(currentDeleteHint);
    }
  }, [pagesForCollection, connectedPages, deleteHint]);

  return {
    showDeleteModal,
    setShowDeleteModal,
    deleteHint,
    canDelete: !connectedPages,
    isDeleteLoading,
    handleDeleteCollection,
    handleNavigationAfterDelete
  };
};

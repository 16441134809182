import { useCallback, useEffect, useState } from 'react';
import { IconInfo } from '@screentone/core';

import { formatMillisecondsToHHMMSS, TIMES } from 'utils/time';
import styles from './Countdown.module.scss';

interface CountdownProps {
  timeInMilliseconds: number;
  atZeroMessage: string;
  timerMessage: string;
}

const Countdown = ({ timeInMilliseconds, atZeroMessage, timerMessage }: CountdownProps) => {
  const calculateTimeLeft = useCallback(() => Math.max(timeInMilliseconds - Date.now(), 0), [timeInMilliseconds]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft);
    }, TIMES.ONE_SECOND);

    return () => clearInterval(timerId);
  }, [timeInMilliseconds, calculateTimeLeft]);

  const currentTimeToDisplay = formatMillisecondsToHHMMSS(timeLeft);
  return (
    <div data-testid="countdown" className={styles.countdown}>
      <IconInfo size="md" className={styles.icon} />
      {currentTimeToDisplay !== '00:00:00' ? `${timerMessage} ${currentTimeToDisplay}` : atZeroMessage}
    </div>
  );
};

export default Countdown;

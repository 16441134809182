import { useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader } from 'components';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import {
  ContextMenuActionsProvider,
  MenuActions,
  MenuActionsKeys
} from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { DataModelProvider, DataModelType } from 'contexts/datamodel/DataModelContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { ArticleItem, NewsletterDto, useGetScheduledContentByAllessehIdQuery } from 'data/generated/graphql';
import { ReadOnlyNewsletter } from 'features/newsletters/ReadOnlyNewsletter';
import { useConvertedProperty } from 'hooks';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';

const contextActions: MenuActions = {
  [MenuActionsKeys.DraftArticle]: [
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    }
  ]
};

const ScheduledNewsletterPage = () => {
  const { campaignId, publishUtc: currentPublishUtc } = useParams();
  const currentProperty = useConvertedProperty();

  const { data, isLoading, isError } = useGetScheduledContentByAllessehIdQuery({
    allessehId: campaignId ?? '',
    publishUtc: Number(currentPublishUtc),
    publicationKey: currentProperty ?? ''
  });

  const isStillScheduled = Number(currentPublishUtc) > Date.now();

  if (isLoading) {
    return <ResponsiveLoader />;
  }

  if (
    isError ||
    (data.scheduledContentByAllessehId.length === 0 ||
      (data.scheduledContentByAllessehId[0].body as NewsletterDto).metadata.publicationKey) !== currentProperty
  ) {
    return <ErrorPage type="500" />;
  }

  if (!isStillScheduled || !(campaignId && currentProperty)) {
    return (
      <PageRootContainer>
        <NoSearchResults
          title="Campaign"
          bodyText="You either have the wrong URL or this scheduled campaign has been sent."
        />
      </PageRootContainer>
    );
  }

  const newsletter = data.scheduledContentByAllessehId[0].body as NewsletterDto;

  return (
    <SummarianProvider>
      <PageRootContainer>
        <DataModelProvider type={DataModelType.NewsletterDTO} root={newsletter.root} metadata={newsletter.metadata}>
          <ContextMenuActionsProvider actions={contextActions}>
            <ReadOnlyNewsletter isScheduled />
          </ContextMenuActionsProvider>
        </DataModelProvider>
      </PageRootContainer>
    </SummarianProvider>
  );
};

export default ScheduledNewsletterPage;

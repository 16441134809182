import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs as TabsComponent, Typography, Wrapper } from '@screentone/core';

import { HistoryContent, PublishHistory } from 'components/publish-history/PublishHistory';
import { EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import {
  ContentUnion,
  ModuleContainer,
  PageDto,
  QueryItem,
  SectionContainer,
  useGetLiveContentByAllessehIdQuery
} from 'data/generated/graphql';
import { usePagePreview } from 'features/section-page-edit/hooks/usePagePreview';
import { usePublicationSettings } from 'hooks/publication-settings';
import { ResetDefault } from '../../../../components/artifact-settings/actions/ResetDefault';
import { Settings } from '../../../../components/artifact-settings/Settings';
import pageEditStyles from '../../SectionPageEdit.module.scss';
import { PageContentSearch } from '../page-search-section/page-content-search/PageContentSearch';
import { PageSearchSection } from '../page-search-section/PageSearchSection';

export enum Tabs {
  ContentSearch,
  Embargoed,
  PublishHistory,
  Settings
}

export const PageMultitabSection = () => {
  const { publishUtc } = useParams();
  const [tabIndex, setTabIndex] = useState(Tabs.ContentSearch);
  const publishUtcParam = publishUtc ? parseInt(publishUtc, 10) : 0;
  const { data: publicationSettings } = usePublicationSettings();
  const { getDTO } = useDataModelContext();
  const {
    hasRecentlyPublished,
    hasRecentlyScheduled,
    hasRecentlyPublishedScheduledContent,
    originalPageWhenInHistoryEditMode,
    tabToChangeTo,
    setTabToChangeTo,
    handleShowHistoryEditMode
  } = usePagePublish();
  const { handlePreviewPage, isLoading: isPreviewPageLoading } = usePagePreview();
  const disableEmbargo = publicationSettings?.publicationSetting.disableEmbargo ?? false;
  const pageDTO = getDTO() as PageDto;
  const [liveVersion, setLiveVersion] = useState<PageDto>();
  const { insertEntity, removeAllByType } = useDataModelContext<SectionContainer>();
  const { currentLayout } = usePageTypeSettingsContext();

  const { data, refetch } = useGetLiveContentByAllessehIdQuery({
    allessehId: pageDTO.metadata.allessehId,
    publicationKey: pageDTO.metadata.publicationKey
  });

  const setTabIndexWrapper = (index: Tabs) => {
    setTabToChangeTo(Tabs.ContentSearch);
    setTabIndex(index);
  };

  useEffect(() => {
    if (data?.liveContentByAllessehId[0]?.body) {
      setLiveVersion(data.liveContentByAllessehId[0].body as PageDto);
    } else {
      setLiveVersion(pageDTO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.liveContentByAllessehId]);

  useEffect(() => {
    if (hasRecentlyPublished && !hasRecentlyPublishedScheduledContent) {
      setLiveVersion(pageDTO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRecentlyPublished]);

  useEffect(() => {
    async function reset() {
      if (hasRecentlyPublishedScheduledContent) {
        await refetch();
      }
    }
    void reset();
  }, [hasRecentlyPublishedScheduledContent, refetch]);

  useEffect(() => {
    // Select Publish History tab when loading a shared page version
    if (publishUtc) {
      setTabIndexWrapper(Tabs.PublishHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabToChangeTo && tabToChangeTo !== tabIndex) {
      setTabIndex(tabToChangeTo);
    }
  }, [tabToChangeTo, tabIndex]);

  const handleResetToDefault = () => {
    removeAllByType(EntityType.Module);

    currentLayout?.requiredLayoutModules.forEach((module, i) => {
      // TODO: Handle other module types, currently applicable to Feeds
      const collection = module.moduleItems
        .map((moduleItem) => {
          if (moduleItem.itemFields.queryItem) {
            const queryItem: QueryItem = {
              type: 'Query',
              attributes: {
                isFeed: moduleItem.itemFields.queryItem.isFeed,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                query: JSON.parse(JSON.stringify(moduleItem.itemFields.queryItem.jsonQuery)),
                feedTitle: moduleItem.itemFields.queryItem.title,
                feedUrl: moduleItem.itemFields.queryItem.url
              }
            };
            return queryItem;
          }
          return null;
        })
        .filter((item) => item !== null) as ContentUnion[];

      const newModule: ModuleContainer = {
        type: 'Module',
        attributes: { pageModule: module },
        collection
      };

      insertEntity(`0-0-${i}`, newModule);
    });
  };

  return (
    <Wrapper margin={{ top: 'mlg' }}>
      <TabsComponent
        role="tablist"
        onChange={setTabIndexWrapper}
        value={tabIndex}
        margin={{ bottom: 'md' }}
        data-testid="page-multi-tab-list"
      >
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.ContentSearch ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          data-testid="page-content-search-tab"
        >
          Content Search
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === Tabs.Embargoed ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          disabled={disableEmbargo}
          data-testid="page-embargoed-tab"
        >
          Embargoed
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id2"
          aria-selected={tabIndex === Tabs.PublishHistory ? 'true' : 'false'}
          aria-controls="tabpanel-id2"
          data-testid="page-publish-history-tab"
        >
          Publish History
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id3"
          aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
          aria-controls="tabpanel-id3"
          data-testid="page-settings-tab"
        >
          Settings
        </TabsComponent.Item>
      </TabsComponent>
      {tabIndex === Tabs.ContentSearch && (
        <Wrapper
          id="tabpanel-id0"
          role="tabpanel"
          aria-labelledby="tab-id0"
          padding={{ top: 'sm' }}
          data-testid="page-multi-tab-section-content-search-tab-content"
        >
          <PageSearchSection showFilters={{ status: false }} />
        </Wrapper>
      )}
      {tabIndex === Tabs.Embargoed && (
        <Wrapper
          id="tabpanel-id1"
          role="tabpanel"
          aria-labelledby="tab-id1"
          padding={{ top: 'sm' }}
          data-testid="page-multi-tab-section-embargoed-tab-content"
        >
          <Typography
            variant="header3"
            margin={{ bottom: 'none' }}
            data-testid="page-multi-tab-section-embargoed-tab-title"
          >
            Embargoed
          </Typography>
          <PageContentSearch
            embargo
            resultListClassName={pageEditStyles.searchResultScrollableEmbargo}
            data-testid="page-multi-tab-section-embargoed-tab-list"
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.PublishHistory && (
        <Wrapper id="tabpanel-id2" role="tabpanel" aria-labelledby="tab-id2" padding={{ top: 'sm' }}>
          <PublishHistory<PageDto>
            liveVersion={liveVersion}
            publishUtc={publishUtcParam}
            hasRecentlyPublished={hasRecentlyPublished || hasRecentlyScheduled}
            originalVersionWhenInHistoryEditMode={originalPageWhenInHistoryEditMode}
            currentlyEditedVersion={originalPageWhenInHistoryEditMode ? pageDTO : undefined}
            handleShowHistoryEditMode={(historyItem: HistoryContent) =>
              handleShowHistoryEditMode(historyItem as PageDto)
            }
            scrollableSectionClassName={pageEditStyles.historyScrollable}
            handlePreviewClick={(selectedVersion) => handlePreviewPage(selectedVersion)}
            isPreviewLoading={isPreviewPageLoading}
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.Settings && (
        <Wrapper id="tabpanel-id3" role="tabpanel" aria-labelledby="tab-id3" padding={{ top: 'sm' }}>
          <Settings>
            <ResetDefault handleResetToDefault={handleResetToDefault} />
          </Settings>
        </Wrapper>
      )}
    </Wrapper>
  );
};

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Input,
  Overlay,
  Typography,
  useModalPortal,
  Wrapper
} from '@screentone/core';
import { AxiosError } from 'axios';

import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { ContentItem } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ExternalCollectionItem, ModuleContainer, QueryItem } from 'data/generated/graphql';
import { useDebounce } from 'hooks';
import { useAllessehCollectionQuery } from 'hooks/useAllessehCollectionQuery';
import { AllessehContent, AllessehContentQueryBody, useAllessehContentQuery } from 'hooks/useAllessehContentQuery';
import { isArticleOrMediaContentType } from 'utils/contentType';
import styles from './AddAllessehCollectionModal.module.scss';

interface AddAllessehCollectionModuleProps {
  hierarchyId: string;
  module: ModuleContainer;
}

export const AddAllessehCollectionModal = ({ hierarchyId, module }: AddAllessehCollectionModuleProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();
  const [allessehCollectionId, setAllessehCollectionId] = useState<string | null>(null);
  const [allessehError, setAllessehError] = useState<string>('');
  const [articleIds, setArticleIds] = useState<string[]>([]);
  const [contentQuery, setContentQuery] = useState<AllessehContentQueryBody | null>(null);
  const [queryInCollection, setQueryInCollection] = useState<AllessehContentQueryBody | null>(null);
  const { insertEntity, fromAllessehContent } = useDataModelContext();
  const { setIsModalOpen } = useContextMenuActions();
  const debouncedCollectionId = useDebounce(allessehCollectionId, 500);

  const {
    data: allessehCollection,
    error: allessehCollectionError,
    isLoading: isAllessehCollectionLoading,
    fetchStatus: collectionFetchStatus
  } = useAllessehCollectionQuery(debouncedCollectionId!, {
    enabled: !!debouncedCollectionId
  });

  const {
    data: articleData,
    error: articleError,
    isLoading: isArticleQueryLoading,
    fetchStatus: articleFetchStatus
  } = useAllessehContentQuery(contentQuery!, {
    enabled: !!contentQuery,
    ignoreEmbargoCheck: true
  });

  const isCollectionLoading = isAllessehCollectionLoading && collectionFetchStatus !== 'idle';
  const isArticleLoading = isArticleQueryLoading && articleFetchStatus !== 'idle';
  const isLoading = isCollectionLoading || isArticleLoading;

  useEffect(() => {
    if (allessehCollectionError) {
      if (allessehCollectionError instanceof AxiosError && allessehCollectionError.response?.data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setAllessehError(allessehCollectionError.response.data?.message);
      } else {
        setAllessehError('An error occurred while fetching the collection');
      }
    } else if (allessehCollection?.response) {
      setAllessehError(allessehCollection.response.message);
    } else if (allessehCollection?.data) {
      const articleIds = allessehCollection.data.attributes.collection
        .filter((content) => isArticleOrMediaContentType(content.type))
        .map((content) => content.id);
      setArticleIds(articleIds);

      const contentQuery: AllessehContentQueryBody = {
        count: articleIds.length,
        query: { and: [{ terms: { key: 'UpstreamOriginId', value: articleIds } }] }
      };
      setContentQuery(contentQuery);

      const queryInCollection = allessehCollection.data.attributes.collection.find(
        (content) => content.type === 'collection'
      );
      const queryData = queryInCollection?.parameters?.find(({ name: nameOfParameter }) => nameOfParameter === 'query');
      if (queryData) {
        setQueryInCollection(queryData.value as AllessehContentQueryBody);
      }
    }
  }, [allessehCollection, allessehCollectionError]);

  useEffect(() => {
    if (articleError) {
      if (articleError instanceof AxiosError && articleError.response?.data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setAllessehError(articleError.response.data?.message);
      } else {
        setAllessehError('An error occurred while fetching the articles of the collection');
      }
    }
  }, [articleError]);

  const handleDismissModal = () => {
    setIsModalOpen(false);
  };

  const handleAdd = () => {
    if (allessehCollection?.response) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setAllessehError(allessehCollection.response.message);
    } else {
      const articleItems = articleData!.pages
        .map((page) => page.data?.attributes)
        .flat()
        .filter((x): x is AllessehContent => !!x)
        .sort((a, b) => articleIds.indexOf(a.data.id) - articleIds.indexOf(b.data.id))
        .map((content) => fromAllessehContent(content));

      const allItems: ContentItem[] = articleItems;
      if (queryInCollection) {
        const queryItem: QueryItem = {
          type: 'Query',
          attributes: { query: queryInCollection as Record<string, unknown> }
        };
        allItems.push(queryItem);
      }

      const externalCollectionItem: ExternalCollectionItem = {
        type: 'Collection',
        attributes: { id: allessehCollectionId!, repo: 'Allesseh' },
        contentItems: allItems,
        // @ts-expect-error We don't need to provide all the fields in the metadata, as only the name will be displayed when Collection component is rendered,
        // and also the createdUtc is needed for redirecting to the correct collection page
        metadata: {
          id: allessehCollectionId!,
          name: allessehCollection?.meta.name ?? `Allesseh Collection ${allessehCollectionId!}`,
          createdUtc: Number(
            allessehCollection?.meta.created_utc && new Date(allessehCollection.meta.created_utc).getTime()
          )
        }
      };

      insertEntity(`${hierarchyId}-${module.collection.length}`, externalCollectionItem);

      handleDismissModal();
      setAllessehError('');
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAllessehCollectionId(e.target.value);
    if (allessehError) setAllessehError('');
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Overlay onDismiss={handleDismissModal} status="open" className={styles.overlay} renderNode={renderNode}>
      <Box>
        <Box.Title>ADD ALLESSEH COLLECTION</Box.Title>
        <Box.Content padding={{ all: 'none' }}>
          <Wrapper padding={{ all: 'md' }}>
            <Input
              type="text"
              placeholder="Enter Allesseh collection ID"
              value={allessehCollectionId ?? ''}
              onChange={handleInputChange}
              margin={{ right: 'sm' }}
              error={!!allessehError}
              data-testid="allesseh-collection-id-input"
            />
            <Typography variant="note" margin={{ top: 'sm' }}>
              Use this only to import collections from Allesseh powered by Cxense.
            </Typography>
            {!!allessehError && <FormHelperText error>{allessehError}</FormHelperText>}
          </Wrapper>
          <Divider />
          <Wrapper padding={{ all: 'md' }} className={styles.bottomBar}>
            <Button secondary onClick={handleDismissModal} data-testid="cancel-allesseh-id">
              Cancel
            </Button>
            <Button
              primary
              margin={{ left: 'sm' }}
              onClick={handleAdd}
              disabled={isLoading}
              data-testid="add-allesseh-id"
            >
              Add
              {isLoading && <ResponsiveLoader size="md" />}
            </Button>
          </Wrapper>
        </Box.Content>
      </Box>
    </Overlay>
  );
};

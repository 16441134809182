import { Box, Button, IconRefresh, Wrapper } from '@screentone/core';

interface ResetDefaultProps {
  handleResetToDefault: () => void;
}

export const ResetDefault = ({ handleResetToDefault }: ResetDefaultProps) => (
  <Box>
    <Wrapper padding={{ all: 'md' }}>
      <Button
        tertiary
        icon={IconRefresh as SvgComponent}
        onClick={handleResetToDefault}
        data-testid="settings-reset-default-button"
      >
        Reset to default
      </Button>
    </Wrapper>
  </Box>
);

import { ReactElement, useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  Button,
  Divider,
  Dropdown,
  Group,
  IconCode2,
  IconEdit,
  IconMinusCircle,
  IconPlusCircle,
  IconThreeDotsVer,
  Typography,
  useDropdownState,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';
import cloneDeep from 'lodash.clonedeep';

import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import {
  Collection,
  PageModule,
  PageModuleItem,
  PageModuleItemCollection,
  PageModuleItemQuery,
  PageModuleItemType,
  TreatmentTypeSetting,
  UiRankedTreatmentType
} from 'data/generated/graphql';
import { removeModuleItem } from 'features/page-edit/components/page-draft-section/PageDraftSectionUtils';
import { getUiModuleTypeLabel } from 'features/page-edit/pageEditUtils';
import { useConvertedProperty } from 'hooks';
import { AllessehContent, DEFAULT_QUERY_JSON } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';
import { AddAllessehCollection } from './components/add-allesseh-collection/AddAllessehCollection';
import { ConvertToCollection } from './components/convert-to-collection/ConvertToCollection';
import { PageModuleItemCard } from './components/page-module-item-card/PageModuleItemCard';
import { PageModuleItemCount } from './components/page-module-item-count/PageModuleItemCount';
import { PageModuleModal } from './components/page-module-modal/PageModuleModal';
import { PageModuleTreatmentDropdown } from './components/page-module-treatment-dropdown/PageModuleTreatmentDropdown';
import { UiModuleFieldsHeader } from './components/ui-module-fields-header/UiModuleFieldsHeader';
import styles from './PageModuleItemList.module.scss';
import { getTreatmentType } from './PageModuleUtils';

interface PageModuleProps {
  isITP?: boolean;
  isNewsletter?: boolean;
  isMobileAppScreen?: boolean;
  isPageHistorySection?: boolean;
  disableAltSumm?: boolean;
  numberOfModulesInPage: number;
  draggableModuleItemPrefix: string;
  moduleItemsDroppableId: string;
  onPageModuleChange: ((newPageModule: PageModule) => void) | null;
  onRemoveModule: (() => void) | null;
  pageModule: PageModule;
  padding: { bottom: string };
  treatmentTypeSettings?: TreatmentTypeSetting[];
  moduleError?: boolean;
  moduleHierarchyId: string;
  treatmentTypeInDropdown?: boolean;
}

const shouldConvertToCollectionByPublication = (treatmentType: string, publication: string | null) => {
  switch (publication) {
    case 'buyside':
      // eslint-disable-next-line
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      return treatmentType !== UiRankedTreatmentType.Vertical_5WithProductCards;
    default:
      return true;
  }
};

export const PageModuleItemList = ({
  isITP,
  isNewsletter,
  isMobileAppScreen,
  isPageHistorySection,
  numberOfModulesInPage,
  draggableModuleItemPrefix,
  disableAltSumm,
  moduleItemsDroppableId,
  onRemoveModule,
  onPageModuleChange,
  pageModule,
  padding,
  treatmentTypeSettings,
  moduleError,
  moduleHierarchyId
}: PageModuleProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isDropdownOpen,
    setOpen: setIsDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();
  const currentProperty = useConvertedProperty();
  const [isPageModuleModalOpen, setIsPageModuleModalOpen] = useState(false);
  const [numContentItemsPerModuleItem, setNumContentItemsPerModuleItem] = useState(pageModule.moduleItems.map(() => 1));
  const treatmentType = getTreatmentType(pageModule);
  const treatmentTypeSetting = treatmentTypeSettings?.filter(
    (t) => t.treatmentType === treatmentType || t.treatmentTypeKey === treatmentType
  )[0];
  const treatmentTypeInDropdown = treatmentTypeSetting && (isMobileAppScreen ?? isITP);
  const { isEditingLinkedItem, setAllContentIds, setIsEditingLinkedItem } = usePagePublish();

  useEffect(() => {
    setNumContentItemsPerModuleItem(pageModule.moduleItems.map(() => 1));
  }, [pageModule.moduleItems]);

  const handleDropdownOpenToggle = () => setIsDropdownOpen(!isDropdownOpen);

  const handleAddQuery = () => {
    const newPageModule = cloneDeep(pageModule);
    newPageModule.moduleItems.push({
      itemType: PageModuleItemType.Query,
      itemFields: {
        queryItem: {
          jsonQuery: DEFAULT_QUERY_JSON
        }
      }
    });
    onPageModuleChange?.(newPageModule);
    setIsDropdownOpen(false);
  };

  const handleCollectionQueryChange = (index: number, collectionItem: PageModuleItemCollection) => {
    const newPageModule = cloneDeep(pageModule);
    newPageModule.moduleItems[index].itemFields.collectionItem = collectionItem;
    onPageModuleChange?.(newPageModule);
  };

  const handleRemoveModuleItem = (index: number, newItem?: PageModuleItem) => {
    const newPageModule = cloneDeep(pageModule);
    if (newItem) newPageModule.moduleItems.splice(index, 1, newItem);
    else newPageModule.moduleItems.splice(index, 1);
    onPageModuleChange?.(newPageModule);
    setIsDropdownOpen(false);
    setAllContentIds((prevState) => removeModuleItem(prevState, moduleHierarchyId));
  };

  const handleDetachFromCollection = (index: number, collection: Collection) => {
    const newPageModule = cloneDeep(pageModule);
    const contentItemsToModuleItems = collection.contentItems.map(
      (contentItem) =>
        ({
          itemType: PageModuleItemType.Content,
          itemFields: { contentItem }
        } as PageModuleItem)
    );
    newPageModule.moduleItems.splice(index, 1);
    let query: null | PageModuleItem = null;
    if (collection.allessehJsonQuery) {
      query = {
        itemType: PageModuleItemType.Query,
        itemFields: { queryItem: { jsonQuery: collection.allessehJsonQuery } }
      };
    }
    const before = newPageModule.moduleItems.slice(0, index);
    const after = newPageModule.moduleItems.slice(index);
    newPageModule.moduleItems = [...before, ...contentItemsToModuleItems, ...(query ? [query] : []), ...after];
    onPageModuleChange?.(newPageModule);
  };

  const handleChangeQueryItem = (index: number, newQueryItem: PageModuleItemQuery) => {
    const newPageModule = cloneDeep(pageModule);
    newPageModule.moduleItems[index].itemFields.queryItem = newQueryItem;
    onPageModuleChange?.(newPageModule);
    setAllContentIds((prevState) => removeModuleItem(prevState, moduleHierarchyId));
  };

  const handleNumItemsLoaded = (index: number, numItems: number) => {
    const newNumItemsPerModuleItem = [...numContentItemsPerModuleItem];
    if (newNumItemsPerModuleItem[index] !== numItems) {
      newNumItemsPerModuleItem[index] = numItems;
      setNumContentItemsPerModuleItem(newNumItemsPerModuleItem);
    }
  };

  const handleEditModule = () => {
    setIsDropdownOpen(false);
    setIsPageModuleModalOpen(true);
  };

  const getNumTotalItemsBeforeIndex = (index: number, moduleItems?: Array<PageModuleItem>) =>
    numContentItemsPerModuleItem.reduce((acc: number, curr: number, numContentItemsIndex: number) => {
      if (moduleItems) {
        if (numContentItemsIndex < index) {
          const item: PageModuleItem = moduleItems[numContentItemsIndex];
          if (item.itemType === PageModuleItemType.Content) {
            const parsedContent = safelyParseContent<AllessehContent>(item.itemFields.contentItem?.content);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!parsedContent.data?.attributes?.content_status) {
              return acc + curr;
            }
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const itemStatus = parsedContent.data?.attributes?.content_status;
            return itemStatus === 'embargo' ? acc : acc + curr;
          }
        }
      }
      return numContentItemsIndex < index ? acc + curr : acc;
    }, 0);

  const numTotalItemsUsed = getNumTotalItemsBeforeIndex(pageModule.moduleItems.length, pageModule.moduleItems);

  const handleDismissEditModule = () => {
    setIsPageModuleModalOpen(false);
  };

  const shouldHaveItems =
    (treatmentTypeSetting?.maxRequiredContentItems == null || treatmentTypeSetting.maxRequiredContentItems > 0) &&
    (treatmentTypeSetting?.minRequiredContentItems == null || treatmentTypeSetting.minRequiredContentItems > 0);
  const allowAddContent = treatmentTypeSetting && numTotalItemsUsed < treatmentTypeSetting.maxRequiredContentItems;

  const contentAndQueryTypes = [PageModuleItemType.Content, PageModuleItemType.Query];

  const shouldShowConvertToCollection =
    !pageModule.moduleItems.some(({ itemType }) => !contentAndQueryTypes.includes(itemType)) &&
    pageModule.moduleItems.some(({ itemType }) => contentAndQueryTypes.includes(itemType)) &&
    shouldConvertToCollectionByPublication(treatmentType, currentProperty);
  return (
    <Wrapper data-testid="page-module-item-list-container" padding={padding}>
      {isPageModuleModalOpen && (
        <PageModuleModal
          onChange={onPageModuleChange}
          pageModule={pageModule}
          onDismiss={handleDismissEditModule}
          treatmentTypeSettings={treatmentTypeSettings}
          isITP={isITP}
        />
      )}
      <Wrapper data-testid="page-module-item-list-header" className={styles.pageModuleHeader} margin={{ bottom: 'xs' }}>
        <div data-testid="page-module-item-list-header-content" className={styles.pageModuleHeaderLeft}>
          {!isMobileAppScreen && (
            <Typography size="sm" weight="bold" margin={{ right: 'sm' }} data-testid="page-treatment-type">
              {!isITP ? getUiModuleTypeLabel(pageModule.uiModuleType) : `Page ${Number(moduleHierarchyId) + 1}`}
            </Typography>
          )}
          {!treatmentTypeInDropdown && (
            <Typography variant="note" data-testid="page-treatment-type-value">
              {treatmentTypeSetting?.label}{' '}
            </Typography>
          )}
          {treatmentTypeInDropdown && (
            <PageModuleTreatmentDropdown
              pageModule={pageModule}
              onChange={onPageModuleChange}
              treatmentTypeSettings={treatmentTypeSettings}
              showCurrentTreatmentTypeAtTheTop
            />
          )}
        </div>
        {!isNewsletter && (
          <Dropdown
            open={isDropdownOpen}
            onToggle={handleDropdownOpenToggle}
            componentRef={dropdownRef}
            padding={{ all: 'none' }}
            position="right"
            trigger={<IconThreeDotsVer color="asphalt" />}
          >
            <Group margin={{ all: 'md' }} gap="xs">
              <Button
                tertiary
                icon={IconEdit as SvgComponent}
                onClick={handleEditModule}
                data-testid="page-draft-ellipsis-option"
              >
                {onPageModuleChange ? 'Edit' : 'View'} module
              </Button>
              {shouldHaveItems && (
                <>
                  {onPageModuleChange && !isITP && (
                    <Button
                      tertiary
                      icon={IconCode2 as SvgComponent}
                      onClick={handleAddQuery}
                      disabled={!allowAddContent}
                    >
                      Add query
                    </Button>
                  )}
                  {onPageModuleChange && !isITP && (
                    <Button
                      disabled={isEditingLinkedItem !== false}
                      icon={IconPlusCircle as SvgComponent}
                      onClick={() => {
                        const DEFAULT_LINKED_ITEM_URL = '';
                        const newPageModule = cloneDeep(pageModule);
                        newPageModule.moduleItems.push({
                          itemType: PageModuleItemType.LinkedItem,
                          itemFields: {
                            linkedItem: {
                              headline: '',
                              media: '',
                              summary: '',
                              validationBypassed: false,
                              url: DEFAULT_LINKED_ITEM_URL
                            }
                          }
                        });
                        onPageModuleChange(newPageModule);
                        setIsDropdownOpen(false);
                        setIsEditingLinkedItem(DEFAULT_LINKED_ITEM_URL);
                      }}
                      tertiary
                    >
                      Add linked item
                    </Button>
                  )}
                  {onPageModuleChange && !isITP && (
                    <AddAllessehCollection
                      pageModule={pageModule}
                      onPageModuleChange={onPageModuleChange}
                      setIsDropdownOpen={setIsDropdownOpen}
                    />
                  )}
                </>
              )}
              {shouldShowConvertToCollection && onPageModuleChange && !isITP && (
                <ConvertToCollection
                  pageModule={pageModule}
                  onPageModuleChange={onPageModuleChange}
                  setIsDropdownOpen={setIsDropdownOpen}
                />
              )}
            </Group>
            {((!isITP && onRemoveModule) || (isITP && onRemoveModule && numberOfModulesInPage > 1)) && (
              <>
                <Divider />
                <Wrapper padding={{ all: 'md' }}>
                  <Button tertiary icon={IconMinusCircle as SvgComponent} color="lava" onClick={onRemoveModule}>
                    Remove module
                  </Button>
                </Wrapper>
              </>
            )}
          </Dropdown>
        )}
      </Wrapper>
      <UiModuleFieldsHeader pageModule={pageModule} />
      {shouldHaveItems && (
        <Droppable droppableId={moduleItemsDroppableId} isDropDisabled={!onPageModuleChange}>
          {(droppableProvided, droppableStateSnapshot) => (
            <div
              data-testid="page-module-item-list-content"
              key={moduleItemsDroppableId}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              data-error={moduleError}
              className={classnames(
                styles.moduleItemBox,
                onPageModuleChange ? styles.moduleItemBoxDroppable : '',
                droppableStateSnapshot.isDraggingOver && droppableStateSnapshot.draggingOverWith
                  ? styles.moduleItemBoxIsDragActive
                  : ''
              )}
            >
              {pageModule.moduleItems.map((moduleItem, index) => (
                <div key={index} className={styles.pageModuleItem}>
                  <PageModuleItemCard
                    key={index}
                    draggableModuleItemPrefix={`${draggableModuleItemPrefix}${index}-`}
                    moduleItem={moduleItem}
                    index={index}
                    disableAltSumm={disableAltSumm}
                    onRemoveModuleItem={onPageModuleChange ? handleRemoveModuleItem : null}
                    onDetachFromCollection={onPageModuleChange ? handleDetachFromCollection : null}
                    onChangeQueryItem={onPageModuleChange ? handleChangeQueryItem : null}
                    treatmentTypeSetting={treatmentTypeSetting}
                    getNumTotalItemsBeforeIndex={() => getNumTotalItemsBeforeIndex(index, pageModule.moduleItems)}
                    onNumItemsLoaded={handleNumItemsLoaded}
                    moduleHierarchyId={moduleHierarchyId}
                    handleCollectionQueryChange={handleCollectionQueryChange}
                    canHighlight={isITP}
                    highlightExtra={
                      treatmentTypeSetting?.maxRequiredContentItems
                        ? index >= treatmentTypeSetting.maxRequiredContentItems
                        : false
                    }
                    isPageHistorySection={isPageHistorySection}
                    isITP={isITP}
                  />
                </div>
              ))}
              {droppableProvided.placeholder}
              <PageModuleItemCount
                isMobileAppScreen={isMobileAppScreen}
                data-testid="page-module-item-count"
                min={treatmentTypeSetting?.minRequiredContentItems}
                max={treatmentTypeSetting?.maxRequiredContentItems}
                showMaxInDenominator
                count={numTotalItemsUsed}
                contentTypes={treatmentTypeSetting?.contentTypes}
              />
            </div>
          )}
        </Droppable>
      )}
    </Wrapper>
  );
};

import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

function useDebouncedState<T extends object>(
  value: T,
  delay: number = 300,
  omitFields: (keyof T)[] = [] // Fields to omit from the comparison
): T | null {
  const [debouncedValue, setDebouncedValue] = useState<T | null>(null);
  const previousValueRef = useRef<T | null>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const omittedValue = omit(value, omitFields);
    const omittedPreviousValue = previousValueRef.current ? omit(previousValueRef.current, omitFields) : null;

    if (!isEqual(omittedPreviousValue, omittedValue)) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        setDebouncedValue(value);
        previousValueRef.current = value;
      }, delay);
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [value, delay, omitFields]);

  return debouncedValue;
}

export default useDebouncedState;

/* eslint-disable import/no-cycle */
import { useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Box,
  Button,
  Dropdown,
  IconClipboard,
  IconDisabled,
  IconThreeDotsVer,
  Token,
  Typography,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { RemoveEntity } from 'components/context-menu-actions/remove-entity/RemoveEntity';
import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { CommonContentProps } from 'components/datamodel/content/commonContentProps';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { ArticleItem } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';
import { resizeIMImage } from 'utils/url';
import styles from './CollectionArticle.module.scss';

interface ErrorArticleProps {
  allessehId: string;
  hierarchyId: string;
  resetErrorBoundary: (...args: any[]) => void;
  isDragging?: boolean;
}
export interface CollectionArticleProps extends CommonEntityProps, CommonContentProps {
  data: ArticleItem;
  isDragging?: boolean;
  renderActions?: () => React.ReactNode;
}

const ErrorArticle = ({ allessehId, hierarchyId, resetErrorBoundary, isDragging }: ErrorArticleProps) => {
  const copyIdToClipboard = async () => {
    await navigator.clipboard.writeText(allessehId);
  };

  return (
    <Box
      data-testid="collection-content-card-item"
      className={classnames(styles.wrapperError, { [styles.wrapperDragging]: isDragging })}
      data-hierarchy-id={hierarchyId}
    >
      <Wrapper
        data-testid="collection-content-card-content"
        padding={{ top: 'md', left: 'md', bottom: 'md' }}
        className={styles.wrapperInner}
      >
        <Wrapper data-testid="collection-content-card-info" margin={{ right: 'md' }} className={styles.left}>
          <div data-testid="collection-content-card-headline" className={styles.headline}>
            <Typography data-testid="collection-content-card-title" weight="bold">
              This article has an error and cannot be displayed. (ID: {allessehId})
              <Button
                tertiary
                margin={{ right: 'md' }}
                icon={IconClipboard as SvgComponent}
                onClick={copyIdToClipboard}
              >
                Copy ID
              </Button>
            </Typography>
          </div>
        </Wrapper>

        <div className={styles.imageContainer} data-testid="collection-error-thumbnail">
          <div className={classnames(styles.image, styles.noImage)} />
        </div>
      </Wrapper>
      <Wrapper data-testid="collection-content-card-toggle" margin={{ all: 'sm' }}>
        <Dropdown
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
          data-testid="collection-error-actions"
        >
          <Wrapper onClick={resetErrorBoundary} padding={{ all: 'smd' }}>
            <RemoveEntity hierarchyId={hierarchyId} text="Remove from collection" />
          </Wrapper>
        </Dropdown>
      </Wrapper>
    </Box>
  );
};

const BareCollectionArticle = ({
  data: article,
  isDragging,
  renderActions,
  parentHierarchyId,
  index
}: CollectionArticleProps) => {
  const { generateHierarchyId } = useDataModelContext();
  const { trackContent, getAltSummFields } = useSummarianContext();
  const hierarchyId = generateHierarchyId(article, parentHierarchyId, index);

  const allessehContent = useMemo(() => safelyParseContent<AllessehContent>(article.content), [article.content]);

  const contentAttributes = allessehContent.data.attributes;
  const actionButtons = renderActions?.();

  const { headline, image } = getAltSummFields(allessehContent);

  useEffect(() => {
    trackContent(allessehContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  const summary = useMemo(() => {
    const summaries = contentAttributes.summary?.content;
    const summaryDescriptions = summaries?.[summaries.length - 1]?.content;
    return summaryDescriptions?.[summaryDescriptions.length - 1]?.text;
  }, [contentAttributes]);

  const updatedTime = useMemo(
    () =>
      contentAttributes.updated_datetime_utc
        ? format(new Date(contentAttributes.updated_datetime_utc), 'MMM d, yyyy h:mm aaa z')
        : '-',
    [contentAttributes.updated_datetime_utc]
  );

  const imageUrl = useMemo(() => resizeIMImage(image, { width: 98, size: 1 }), [image]);

  return (
    <Box
      data-testid="collection-content-card-item"
      className={classnames(styles.wrapper, { [styles.wrapperDragging]: isDragging })}
      data-hierarchy-id={hierarchyId}
    >
      <Wrapper
        data-testid="collection-content-card-content"
        padding={{ top: 'md', left: 'md', bottom: 'md' }}
        className={styles.wrapperInner}
      >
        <Wrapper data-testid="collection-content-card-info" margin={{ right: 'md' }} className={styles.left}>
          <div data-testid="collection-content-card-headline" className={styles.headline}>
            <Typography
              data-testid="collection-content-card-title"
              weight="bold"
              className={styles.header}
              title={headline}
            >
              {headline}
            </Typography>
            {contentAttributes.content_status === 'embargo' && (
              <Token
                data-testid="collection-content-card-icon-embargo"
                icon={IconDisabled as SvgComponent}
                margin={{ left: 'sm' }}
                color="lava"
              />
            )}
          </div>

          {summary && (
            <Typography className={styles.description} data-testid="collection-content-card-description">
              {summary}
            </Typography>
          )}
          <Typography variant="note" data-testid="collection-content-card-status-datetime">
            Updated {updatedTime}
          </Typography>
          {contentAttributes.section_type && (
            <Wrapper margin={{ top: 'sm' }} data-testid="collection-content-card-type">
              <Token data-testid="collection-content-card-section-type">{contentAttributes.section_type}</Token>
            </Wrapper>
          )}
        </Wrapper>
        {imageUrl && (
          <div
            style={{ backgroundImage: `url('${imageUrl}')` }}
            className={classnames(styles.image, image ? '' : styles.noImage)}
            data-testid="collection-content-card-thumbnail"
          />
        )}
      </Wrapper>
      <Wrapper data-testid="collection-content-card-toggle" margin={{ all: 'sm' }}>
        {actionButtons && (
          <Dropdown
            padding={{ all: 'none' }}
            position="right"
            trigger={<IconThreeDotsVer color="asphalt" />}
            data-testid="page-content-ellipsis-icon"
          >
            {actionButtons}
          </Dropdown>
        )}
      </Wrapper>
    </Box>
  );
};

const CollectionArticle = (collectionArticleProps: CollectionArticleProps) => {
  const { generateHierarchyId } = useDataModelContext();
  const hierarchyId = generateHierarchyId(
    collectionArticleProps.data,
    collectionArticleProps.parentHierarchyId,
    collectionArticleProps.index
  );

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <ErrorArticle
          allessehId={collectionArticleProps.data.attributes.id || ''}
          hierarchyId={hierarchyId}
          resetErrorBoundary={resetErrorBoundary}
          isDragging={collectionArticleProps.isDragging}
        />
      )}
    >
      <BareCollectionArticle {...collectionArticleProps} />
    </ErrorBoundary>
  );
};

export default CollectionArticle;

import { useAppQuery } from 'data/query-client';
import restClient from 'data/restClient';
import { useAuthToken } from 'hooks/useAuthToken';
import { AllessehContentErrorResponse } from './useAllessehContentQuery';
import { useConvertedProperty } from './useConvertedProperty';

interface ContentParameter {
  type: 'parameter';
  name: string;
  value: unknown;
}

interface AllessehCollectionItem {
  type: string;
  seo_id: string;
  hosted_url: string;
  id: string;
  link: string;
  parameters?: ContentParameter[];
}
interface AllessehCollection {
  type: string;
  collection: AllessehCollectionItem[];
}

interface AllessehCollectionQueryResponse {
  data?: {
    id: string;
    type: string;
    attributes: AllessehCollection;
  };
  meta: {
    name?: string;
    created_utc?: string;
  };
  response?: AllessehContentErrorResponse;
}

const getAllessehCollectionQuery = (authToken: string, allessehCollectionId: string, currentProperty: string | null) =>
  restClient.get<AllessehCollectionQueryResponse>('allesseh/collection', {
    headers: {
      Authorization: authToken
    },
    params: {
      id: allessehCollectionId,
      publicationKey: currentProperty
    }
  });

export const useAllessehCollectionQuery = (allessehCollectionId: string, options?: { enabled: boolean }) => {
  const authToken = useAuthToken();
  const currentProperty = useConvertedProperty();

  return useAppQuery(
    [allessehCollectionId],
    () => getAllessehCollectionQuery(authToken, allessehCollectionId, currentProperty),
    options
  );
};

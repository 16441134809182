import { useState } from 'react';
import { Button, Dialog, FormHelperText, FormLabel, IconMail, Input } from '@screentone/core';

import { useAlert } from 'contexts/alert/useAlert';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NewsletterTestDialog = ({ sendTestEmail }: { sendTestEmail: (emails: string) => void }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [emailValidationError, setEmailValidationError] = useState(false);
  const { alertError } = useAlert();

  const toggleSendTestModal = () => {
    setModalOpen(!modalOpen);
    setEmailInput('');
    setEmailValidationError(false);
  };

  const onSendTestEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValidationError(false);
    setEmailInput(e.target.value);
  };

  const handleSendTestEmail = () => {
    const emails = emailInput.replace(/\s/g, '').split(',');
    const valid = emails.every((email) => EMAIL_REGEX.test(email));
    if (!valid) {
      setEmailValidationError(true);
      return;
    }
    const emailString = emails.join(',');
    try {
      sendTestEmail(emailString);
      setEmailInput('');
      setModalOpen(!modalOpen);
      setEmailValidationError(false);
    } catch (e: unknown) {
      console.error(e);
      alertError(`Campaign failed with the following error: ${(e as Error).message}`);
    }
  };

  return (
    <>
      <Button tertiary icon={IconMail as SvgComponent} onClick={() => setModalOpen(true)}>
        Send Test
      </Button>
      <Dialog status={modalOpen ? 'open' : 'closed'} onDismiss={toggleSendTestModal}>
        <Dialog.Title id="dialogTitle">Send Test Email</Dialog.Title>
        <Dialog.Content>
          <FormLabel label="Send to" labelPosition="top" fullWidth>
            <Input onChange={onSendTestEmailChange} value={emailInput} error={emailValidationError} />
            <FormHelperText>Add more email addresses separated with commas</FormHelperText>
            {emailValidationError && <FormHelperText error>Enter valid email addresses</FormHelperText>}
          </FormLabel>
        </Dialog.Content>
        <Dialog.Actions>
          <Button secondary onClick={toggleSendTestModal}>
            Cancel
          </Button>
          <Button primary onClick={handleSendTestEmail} disabled={!emailInput.length}>
            Send
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

import { Typography, Wrapper } from '@screentone/core';

import PageDraftTrash from 'components/page-draft-trash/PageDraftTrash';
import { SearchContent, SearchProps } from 'components/search-content/SearchContent';

interface ContentSearchProps {
  searchProps: SearchProps;
}
const ContentSearch = ({ searchProps }: ContentSearchProps) => (
  <>
    <Typography variant="header3" margin={{ bottom: 'none' }}>
      Content
    </Typography>
    <Wrapper padding={{ top: 'sm' }}>
      <SearchContent searchProps={searchProps} beforeContent={<PageDraftTrash messageLabel="banner" />} />
    </Wrapper>
  </>
);

export default ContentSearch;

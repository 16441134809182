import { PageModule, TreatmentTypeSetting, UiModule } from 'data/generated/graphql';

interface PageModuleSharedProps {
  children: React.ReactNode;
  moduleFieldKey: Exclude<keyof UiModule, '__typename'>;
  pageModule: PageModule;
  allowedTreatmentTypeSettings?: TreatmentTypeSetting[];
  showCurrentTreatmentTypeAtTheTop?: boolean;
}

export const PageModuleShared = ({
  children,
  moduleFieldKey,
  pageModule,
  allowedTreatmentTypeSettings,
  showCurrentTreatmentTypeAtTheTop
}: PageModuleSharedProps) => {
  if (showCurrentTreatmentTypeAtTheTop) {
    const currentTreatmentType = pageModule.uiModuleFields[moduleFieldKey]?.treatmentType;

    const currTreatmentTypeIndex = allowedTreatmentTypeSettings?.findIndex(
      (treatmentTypeSetting) =>
        (treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType) ===
        (currentTreatmentType as string)
    );

    allowedTreatmentTypeSettings?.unshift(allowedTreatmentTypeSettings[currTreatmentTypeIndex!]);
    allowedTreatmentTypeSettings?.splice(currTreatmentTypeIndex! + 1, 1);
  }

  return <>{children}</>;
};

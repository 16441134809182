import { Box, FormLabel, Group, Switch } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { ItpIssuePageType, ItpIssueWithPages, ItpPage } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';

interface IssuePagesListProps {
  issue?: ItpIssueWithPages;
  handlePartialIssueChange: (issue: Partial<ItpIssueWithPages>) => void;
}

const IssuePagesList = ({ issue, handlePartialIssueChange }: IssuePagesListProps) => {
  const { data: publicationSettings, isLoading: isLoad, fetchStatus } = usePublicationSettings();
  const requiredPageTypes = new Set(publicationSettings?.publicationSetting.itpRequiredPageTypes);

  const isLoading = isLoad && fetchStatus !== 'idle';

  const handlePageHiddenCheckBox = (checked: boolean, pageIndex: number) => {
    if (!issue || issue.pages.length === 0) return;

    const newPages = [...issue.pages];
    newPages[pageIndex].isHidden = !checked;
    handlePartialIssueChange({ pages: newPages });
  };

  return (
    <>
      {isLoading ? (
        <ResponsiveLoader />
      ) : (
        <Box attached="bottom" data-testid="itp-section-header">
          <Box.Title>Sections</Box.Title>
          <Box.Content>
            {issue?.pages.map((page: ItpPage, pageIndex: number) => (
              <Group key={page.pageType} data-testid="itp-sections-items">
                <FormLabel
                  labelPosition="right"
                  label={page.label}
                  margin={{ bottom: 'md' }}
                  data-testid="itp-sections-item-labels"
                >
                  <Switch
                    disabled={requiredPageTypes.has(page.pageType as ItpIssuePageType)}
                    checked={!page.isHidden}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePageHiddenCheckBox(e.target.checked, pageIndex)
                    }
                  />
                </FormLabel>
              </Group>
            ))}
          </Box.Content>
        </Box>
      )}
    </>
  );
};

export default IssuePagesList;

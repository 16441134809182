import { RefObject, useEffect, useRef, useState } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

import {
  CollectionsDtoQuery,
  GetAllBannersQuery,
  GetAllOffPlatformQuery,
  GetNewslettersQuery,
  GetScheduledContentByPublicationKeyQuery,
  PagesDtoQuery,
  PagesQuery
} from 'data/generated/graphql';
import { useOnScreen } from 'hooks/dom/useOnScreen';
import { AllessehContentQueryResponse } from 'hooks/useAllessehContentQuery';
import styles from './InfiniteScrollWaypoint.module.scss';

interface WaypointProps {
  nextPageProps: {
    fetchNextPage: (
      options?: FetchNextPageOptions | undefined
    ) => Promise<
      InfiniteQueryObserverResult<
        | PagesQuery
        | PagesDtoQuery
        | CollectionsDtoQuery
        | AllessehContentQueryResponse
        | GetNewslettersQuery
        | GetScheduledContentByPublicationKeyQuery
        | GetAllOffPlatformQuery
        | GetAllBannersQuery
      >
    >;
    isFetchingNextPage: boolean;
    hasNextPage?: boolean;
  };
}

export const InfiniteScrollWaypoint = ({
  nextPageProps: { fetchNextPage, isFetchingNextPage, hasNextPage }
}: WaypointProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const waypointRef = useRef() as RefObject<HTMLDivElement>;
  const onScreen = useOnScreen(waypointRef);

  useEffect(() => {
    async function fetchMoreData() {
      await fetchNextPage();
      setIsFetching(false);
    }

    if (hasNextPage && onScreen && !isFetchingNextPage && !isFetching) {
      setIsFetching(true);
      void fetchMoreData();
    }
  }, [fetchNextPage, isFetchingNextPage, hasNextPage, onScreen, isFetching]);

  return (
    <div
      data-testid="infinite-scroll-waypoint-scroll"
      ref={waypointRef}
      className={styles.height}
      data-isfetching={!hasNextPage || isFetchingNextPage || isFetching}
    />
  );
};

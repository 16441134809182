import { SummarianContext } from 'contexts/summarian/SummarianContext';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { decodeHtmlEntities } from 'utils/text';

export function getLatestAltSummHeadline(
  content: AllessehContent,
  getAltSummFields: SummarianContext['getAltSummFields']
) {
  const altSummFields = getAltSummFields(content);

  const { headline } = altSummFields;

  return decodeHtmlEntities(headline);
}

import { useEffect } from 'react';
import { Button, Dialog, Group, IconDisabled, Token, Typography } from '@screentone/core';

import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { ArticleItem } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { formatInTimeZone } from 'utils/dates';
import { safelyParseContent } from 'utils/temp';
import { decodeHtmlEntities } from 'utils/text';
import styles from './EmbargoPagePublishDialog.module.scss';

interface EmbargoPagePublishDialogProps {
  onPublish: () => Promise<void>;
  onCancel: () => void;
  embargoedContent: ArticleItem[];
}

const EmbargoedArticle = ({ embargoedContent }: { embargoedContent: AllessehContent }) => {
  const { trackContent, getAltSummFields } = useSummarianContext();
  const { data: publicationSettingsResp } = usePublicationSettings();

  useEffect(() => {
    trackContent(embargoedContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  const {
    data: {
      attributes: { embargo_datetime_utc: embargoDatetime }
    }
  } = embargoedContent;
  const modifiedDate = embargoDatetime
    ? formatInTimeZone(
        embargoDatetime,
        publicationSettingsResp?.publicationSetting.preferredTimezone,
        "LLLL dd, yyyy 'at' hh:mm aaaa",
        { showTimezoneAbbreviation: true }
      )
    : new Date();
  return (
    <div className={styles.embargoedArticle}>
      <Typography variant="header4" weight="bold" margin={{ bottom: 'xs' }}>
        {decodeHtmlEntities(getAltSummFields(embargoedContent).headline)}
      </Typography>
      <Group gap="sm">
        <Token className={styles.token} icon={IconDisabled as SvgComponent} color="lava" />
        <Typography color="asphalt">{modifiedDate}</Typography>
      </Group>
    </div>
  );
};

export const EmbargoPagePublishDialog = ({ onPublish, onCancel, embargoedContent }: EmbargoPagePublishDialogProps) => (
  <Dialog status="open" className={styles.dialog} onDismiss={onCancel} focusTrap={false}>
    <Dialog.Title>Publish With Embargo</Dialog.Title>
    <Dialog.Content>
      <Typography color="asphalt" size="sm">
        This page contains an embargoed story. The page content will update at the scheduled publish time of the
        following articles:
      </Typography>
      <br />
      <div className={styles.embargoedArticles}>
        {embargoedContent.map((article) => {
          const allessehContentStr = article.content;
          const content = safelyParseContent<AllessehContent>(allessehContentStr);
          return <EmbargoedArticle key={content.data.id} embargoedContent={content} />;
        })}
      </div>
    </Dialog.Content>
    <Dialog.Actions>
      <Button secondary onClick={onCancel}>
        Cancel
      </Button>
      <Button
        primary
        onClick={async () => {
          onCancel();
          await onPublish();
        }}
      >
        Publish
      </Button>
    </Dialog.Actions>
  </Dialog>
);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown, IconThreeDotsVer, useDropdownState } from '@screentone/core';

import styles from './PortalDropdown.module.scss';

interface PortalDropdownProps {
  children: React.ReactNode;
  zIndex?: 'minor' | 'mid' | 'major' | 'top';
  position?: 'left' | 'right' | 'center';
}

const PortalDropdown: React.FC<PortalDropdownProps> = ({ children, zIndex = 'top', position = 'left' }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { open, setOpen, componentRef } = useDropdownState();

  const [dropdownPosition, setDropdownPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0
  });

  const triggerRef = useRef<HTMLDivElement | null>(null);

  const handleToggleDropdown = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setOpen(!open);
  }, [setOpen, open]);

  const dropdownContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (triggerRef.current && dropdownContentRef.current && open) {
      const rect = triggerRef.current.getBoundingClientRect();
      const dropdownWidth = dropdownContentRef.current.offsetWidth;
      const top = rect.bottom + 8;
      let { left } = rect;

      if (position === 'right') {
        left = rect.left;
      } else if (position === 'center') {
        left = rect.left + rect.width / 2 - dropdownWidth / 2;
      } else {
        left = rect.right - dropdownWidth;
      }

      setDropdownPosition({ top, left });
    }
  }, [open, position]);

  return (
    <Dropdown
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      open={open || null}
      onToggle={handleToggleDropdown}
      zIndex={zIndex}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      componentRef={componentRef}
      data-testid="portal-dropdown"
    >
      <Dropdown.Trigger data-testid="portal-dropdown-trigger">
        <div ref={triggerRef}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <Button tertiary color="asphalt" type="Button" onClick={handleToggleDropdown} icon={IconThreeDotsVer} />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content position={position} padding={{ all: 'none' }}>
        {open &&
          ReactDOM.createPortal(
            <div
              role="button"
              ref={dropdownContentRef}
              tabIndex={0}
              className={`st_box ${styles.portalDropdownContent}`}
              data-testid="portal-dropdown-content"
              style={{
                top: dropdownPosition.top,
                left: dropdownPosition.left
              }}
              onPointerDown={(e) => e.stopPropagation()}
            >
              {children}
            </div>,
            document.body
          )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default PortalDropdown;

import { Button, Group, IconEye, Typography, Wrapper } from '@screentone/core';
import { format } from 'date-fns-tz';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import { SectionContainer } from 'data/generated/graphql';
import { usePagePreview } from 'features/section-page-edit/hooks/usePagePreview';
import styles from './PageDraftSection.module.scss';

export const PageDraftSection = () => {
  const { root, metadata, renderEntity, countContent } = useDataModelContext<SectionContainer>();
  const { originalPageWhenInHistoryEditMode } = usePagePublish();
  const { handlePreviewPage, isLoading: isPreviewPageLoading } = usePagePreview();

  const draftSection = renderEntity(root, { hierarchyId: '' });

  return (
    <>
      <Wrapper margin={{ bottom: 'sm', top: 'mlg' }} className={styles.topHeaderWrapper}>
        <Group data-testid="page-draft-title" direction="row" align="space-between" fullWidth valign="baseline">
          <Typography variant="header3">
            {originalPageWhenInHistoryEditMode
              ? `Editing: ${format(metadata.publishUtc!, 'MMM d, yyyy h:mm aaa z')}`
              : `${countContent(root)}`}
          </Typography>
          <Button
            tertiary
            icon={IconEye as SvgComponent}
            onClick={() => handlePreviewPage()}
            disabled={isPreviewPageLoading}
            data-testid="page-preview-button"
          >
            Preview
          </Button>
        </Group>
      </Wrapper>
      <Wrapper className={styles.draftContainer}>{draftSection}</Wrapper>
    </>
  );
};

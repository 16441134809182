import { Button, IconCode2, Wrapper } from '@screentone/core';

import { EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { ModuleContainer, PageModule, QueryItem } from 'data/generated/graphql';
import { DEFAULT_QUERY_JSON } from 'hooks/useAllessehContentQuery';

interface AddQueryProps {
  hierarchyId: string;
  module: ModuleContainer;
  numTotalItemsUsed: number;
}

export const AddQuery = ({ hierarchyId, module, numTotalItemsUsed }: AddQueryProps) => {
  const { getTreatmentTypeSetting } = usePageTypeSettingsContext();
  const { insertEntity } = useDataModelContext();

  const treatmentTypeSetting = getTreatmentTypeSetting(module.attributes.pageModule as PageModule);
  const allowAddContent = treatmentTypeSetting && numTotalItemsUsed < treatmentTypeSetting.maxRequiredContentItems;

  const handleAddQuery = () => {
    const queryItem: QueryItem = {
      type: EntityType.Query,
      attributes: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query: JSON.parse(DEFAULT_QUERY_JSON)
      }
    };
    insertEntity(`${hierarchyId}-${module.collection.length}`, queryItem);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconCode2 as SvgComponent} onClick={handleAddQuery} disabled={!allowAddContent}>
        Add query
      </Button>
    </Wrapper>
  );
};

import { useEffect } from 'react';
import io from 'socket.io-client';

import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { CronNotificationScheduledContent } from 'data/generated/graphql';
import { BannerTabs } from 'features/banner-edit/components/banner-multitab-section/BannerMultitabSection';
import { Tabs } from 'features/section-page-edit/components/page-multitab-section/PageMultitabSection';
import env from 'utils/env';
import { isWithinTimeInterval } from 'utils/time';

interface UseSocketNotificationsProps {
  canSave: boolean;
  isPreviewDraftPage: boolean;
  soonToBePublishedScheduledContentPublishUtc: number | null;
  setTabToChangeTo: (tab: Tabs | BannerTabs) => void;
  setHasRecentlyPublished: (value: boolean) => void;
  setHasRecentlyPublishedScheduledContent: (value: boolean) => void;
  setSoonToBePublishedScheduledContentPublishUtc: (utc: number | null) => void;
  handleScheduledContentPublished: (publishUtc: number) => Promise<any>;
}

export const useSocketNotificationsScheduledContent = ({
  canSave,
  isPreviewDraftPage,
  soonToBePublishedScheduledContentPublishUtc,
  setTabToChangeTo,
  setHasRecentlyPublished,
  setHasRecentlyPublishedScheduledContent,
  setSoonToBePublishedScheduledContentPublishUtc,
  handleScheduledContentPublished
}: UseSocketNotificationsProps) => {
  const { alertWarning } = useAlert();
  const { metadata } = useDataModelContext();
  useEffect(() => {
    const socket = io(`${env.API_URL}/notifications`, {
      path: '/api/notifications',
      transports: ['websocket']
    });

    socket.on(
      'cronNotification',
      async (cronNotificationData: { type: string; data: CronNotificationScheduledContent[] }) => {
        switch (cronNotificationData.type) {
          case 'scheduledContentTime':
            const scheduledContentData = cronNotificationData.data;
            const scheduledContent = scheduledContentData.find(
              (item: CronNotificationScheduledContent) =>
                item.allessehId === metadata.allessehId &&
                isWithinTimeInterval(item.publishUtc, new Date().getTime(), 'hour')
            );
            if (
              scheduledContent?.allessehId === metadata.allessehId &&
              isWithinTimeInterval(scheduledContent.publishUtc, new Date().getTime(), 'hour')
            ) {
              setSoonToBePublishedScheduledContentPublishUtc(scheduledContent.publishUtc);
            } else if (soonToBePublishedScheduledContentPublishUtc) {
              setSoonToBePublishedScheduledContentPublishUtc(null);
            }
            break;
          case 'scheduledContentPublished':
            const { data } = cronNotificationData;
            if (data[0]) {
              const scheduledContentArray = data;
              await Promise.all(
                scheduledContentArray.map(async (scheduledContent) => {
                  if (scheduledContent.allessehId === metadata.allessehId) {
                    await handleScheduledContentPublished(scheduledContent.publishUtc);
                  } else if (
                    scheduledContent.allessehId === metadata.allessehId &&
                    scheduledContent.publishUtc < Date.now()
                  ) {
                    setSoonToBePublishedScheduledContentPublishUtc(null);
                  }
                })
              );
            }
            break;
          default:
            break;
        }
      }
    );

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [
    canSave,
    isPreviewDraftPage,
    metadata.allessehId,
    metadata.publishUtc,
    soonToBePublishedScheduledContentPublishUtc,
    alertWarning,
    setTabToChangeTo,
    setSoonToBePublishedScheduledContentPublishUtc,
    setHasRecentlyPublished,
    setHasRecentlyPublishedScheduledContent,
    handleScheduledContentPublished
  ]);
};

import { ReactElement } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Alert, Button, IconWarning, Wrapper } from '@screentone/core';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

import { ResponsiveLoader } from 'components';
import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { AllessehContent, AllessehContentQueryResponse } from 'hooks/useAllessehContentQuery';
import { DROPPABLE_IDS } from 'utils/collectionDragUtils';
import styles from './SearchContentList.module.scss';
import { getLatestAltSummHeadline } from './utils/pageContentSearchUtils';
import collectionEditStyles from '../../../../features/collection-edit/CollectionEdit.module.scss';

interface ContentsListProps {
  includedContentItemsInSearch: AllessehContent[];
  excludedContentItemsInSearch: AllessehContent[];
  className?: string;
  renderContentCard: (content: AllessehContent, index: number) => ReactElement;
  nextPageProps?: {
    fetchNextPage: (
      options?: FetchNextPageOptions | undefined
    ) => Promise<InfiniteQueryObserverResult<AllessehContentQueryResponse>>;
    isFetchingNextPage: boolean;
    hasNextPage?: boolean;
  };
  isLoading: boolean;
  customMessage?: string;
  embargo?: boolean;
}

const SearchContentList = ({
  includedContentItemsInSearch,
  excludedContentItemsInSearch,
  className,
  nextPageProps,
  isLoading,
  customMessage,
  renderContentCard,
  embargo
}: ContentsListProps) => {
  const { getAltSummFields } = useSummarianContext();
  return (
    <div
      data-testid="search-content-list-container"
      className={`${className ? `${className} ` : ''}${collectionEditStyles.curateScrollable}`}
    >
      <Wrapper data-testid="search-content-list-container-list" margin={{ top: 'sm' }}>
        {isLoading && <ResponsiveLoader />}
        {!isLoading &&
          (!(includedContentItemsInSearch[0] || excludedContentItemsInSearch[0]) ||
          includedContentItemsInSearch[0]?.response?.error ||
          (embargo && !includedContentItemsInSearch[0]) ? (
            <NoSearchResults
              data-testid="search-content-list-no-results"
              title="Content"
              bodyText={customMessage ?? 'No matching content found'}
            />
          ) : (
            <>
              <Droppable droppableId={DROPPABLE_IDS.RESULTS} isDropDisabled>
                {(droppableProvided) => (
                  <div
                    data-testid="search-content-list-content"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className={styles.itemsList}
                  >
                    {includedContentItemsInSearch.map((content, index: number) => (
                      <div data-testid="search-content-list-item" key={content.data.id}>
                        {renderContentCard(content, index)}
                      </div>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
              {!embargo &&
                !includedContentItemsInSearch[0] &&
                excludedContentItemsInSearch.map((content) => {
                  const headline = getLatestAltSummHeadline(content, getAltSummFields);

                  return (
                    <Alert
                      type="warning"
                      key={content.data.id}
                      className={styles.stAlert}
                      data-testid="search-content-list-item-excluded-article"
                    >
                      <div className={styles.flexAlignRow}>
                        <IconWarning />
                        <strong>Warning: Duplicate Article</strong>
                      </div>
                      {`The article titled: "${headline}" is already being used on the page.`}
                      <Button
                        className={styles.stBtn}
                        onClick={() => window.open(content.data.attributes.source_url, '_blank')}
                        tertiary
                      >
                        Open Article
                      </Button>
                    </Alert>
                  );
                })}
              {nextPageProps?.fetchNextPage && <InfiniteScrollWaypoint nextPageProps={nextPageProps} />}
            </>
          ))}
      </Wrapper>
    </div>
  );
};

export default SearchContentList;

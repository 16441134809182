import { FormLabel, Switch, Typography } from '@screentone/core';
import cloneDeep from 'lodash.clonedeep';

import {
  AvailableRequiredLayoutModule,
  ModuleContainer,
  PageContainer,
  PageModule,
  UiBasicOptionalModule,
  UiBasicOptionalTreatmentType
} from 'data/generated/graphql';
import styles from './TopOptionalToggle.module.scss';

interface TopOptionalToggleProps {
  page: PageContainer;
  availableRequiredLayoutModules: AvailableRequiredLayoutModule[] | undefined;
  insertEntity: (hierarchyId: string, entity: ModuleContainer) => void;
  removeEntity: (hierarchyId: string) => void;
}

const OPTIONAL_TOP_ORDER = {
  pen: [UiBasicOptionalTreatmentType.AboveSuperHero, UiBasicOptionalTreatmentType.SuperHero],
  barrons: []
};

function getNewIndex(pageModules: ModuleContainer[], treatmentType: string): number {
  const orderIdx = OPTIONAL_TOP_ORDER.pen.findIndex((type: string) => type === treatmentType);
  const orderArray = OPTIONAL_TOP_ORDER.pen.map((type: UiBasicOptionalTreatmentType) => {
    const index = pageModules.findIndex((module) => {
      const pageModule = module.attributes.pageModule as PageModule;
      return pageModule.uiModuleFields.basicOptionalModule?.treatmentType === type;
    });
    return index;
  });

  if (orderArray.every((value) => value === -1)) return 0;
  if (orderArray.includes(orderIdx)) return orderIdx;
  return orderArray.findIndex((num) => num === -1);
}

const TopOptionalToggle = ({
  page,
  availableRequiredLayoutModules,
  insertEntity,
  removeEntity
}: TopOptionalToggleProps) => {
  const handleAddModule = (
    event: { target: { checked: boolean } },
    treatmentType: UiBasicOptionalTreatmentType | undefined
  ) => {
    if (!treatmentType) {
      return;
    }
    const defaultModule = cloneDeep(
      availableRequiredLayoutModules?.find(
        (module) => module.defaultModule.uiModuleFields.basicOptionalModule?.treatmentType === treatmentType
      )?.defaultModule
    );
    if (event.target.checked) {
      const moduleIndex = getNewIndex(page.collection, treatmentType);
      const newModule: ModuleContainer = {
        type: 'Module',
        attributes: { pageModule: defaultModule },
        collection: []
      };
      insertEntity(`0-0-${moduleIndex}`, newModule);
    } else {
      const index = page.collection.findIndex((module) => {
        const pageModule = module.attributes.pageModule as PageModule;
        return pageModule.uiModuleFields.basicOptionalModule?.allowedTreatmentTypes?.includes(treatmentType);
      });
      removeEntity(`0-0-${index}`);
    }
  };

  return (
    <div className={styles.moduleList}>
      <div className={styles.container}>
        {availableRequiredLayoutModules?.map((module, i) => {
          const basicOptionalModule = module.defaultModule.uiModuleFields.basicOptionalModule as
            | UiBasicOptionalModule
            | undefined;
          if (basicOptionalModule) {
            const moduleName = basicOptionalModule.moduleName ?? '';
            const { treatmentType } = basicOptionalModule;

            return (
              <div className={styles.moduleContainer} key={i}>
                <Typography variant="label2" className={styles.text}>
                  {moduleName.toUpperCase()}
                </Typography>
                <FormLabel key="Enable" label="Enable" labelPosition="right" className={styles.text}>
                  <Switch
                    name={moduleName}
                    id={moduleName}
                    onChange={(e: { target: { checked: boolean } }) => handleAddModule(e, treatmentType)}
                    checked={page.collection.some((module) => {
                      const pageModule = module.attributes.pageModule as PageModule;
                      return pageModule.uiModuleFields.basicOptionalModule?.allowedTreatmentTypes?.includes(
                        treatmentType
                      );
                    })}
                  />
                </FormLabel>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default TopOptionalToggle;

import { IconInfo, Typography, Wrapper } from '@screentone/core';

import Countdown from 'components/countdown/Countdown';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import { SectionContainer } from 'data/generated/graphql';
import { OKTA_ENV } from 'features/auth/components/OktaRouter';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { formatInTimeZone } from 'utils/dates';
import env from 'utils/env';
import { safelyParseContent } from 'utils/temp';
import { PagePublishingButtons } from './components/page-publishing-buttons/PagePublishingButtons';
import styles from './PageTitleHeader.module.scss';

export const PageTitleHeader = () => {
  const { hasRecentlyPublished, soonToBePublishedScheduledContentPublishUtc } = usePagePublish();
  const { data: publicationSettings } = usePublicationSettings();
  const { root, metadata, getEmbargoContent } = useDataModelContext<SectionContainer>();

  const latestEmbargoContent = getEmbargoContent()
    .sort((a, b) => {
      const aDateTime = safelyParseContent<AllessehContent>(a.content).data.attributes.embargo_datetime_utc;
      const bDateTime = safelyParseContent<AllessehContent>(b.content).data.attributes.embargo_datetime_utc;

      const aDate = new Date(aDateTime!);
      const bDate = new Date(bDateTime!);

      return aDate < bDate ? 1 : -1;
    })
    .at(0);
  const collectionPrefix = `UCS${(env.OKTA_ENV as OKTA_ENV) === OKTA_ENV.PRD ? 'P' : 'D'}`;
  const allessehId = `${collectionPrefix}_${publicationSettings?.publicationSetting.publicationKey.toUpperCase()}${root.attributes.routeKey.replace(
    /\//,
    '_'
  )}`;

  return (
    <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.titleHeader}>
      <div className={styles.row}>
        <Typography variant="header2" margin={{ bottom: 'none' }} data-testid="page-name-title">
          {metadata.name}
        </Typography>
        {hasRecentlyPublished && (
          <Typography color="asphalt" margin={{ left: 'md' }} data-testid="page-status-label">
            Published
          </Typography>
        )}
      </div>
      <div className={styles.row}>
        {soonToBePublishedScheduledContentPublishUtc && (
          <Countdown
            timeInMilliseconds={soonToBePublishedScheduledContentPublishUtc}
            timerMessage="Next Scheduled Publish in"
            atZeroMessage="Scheduled Publish should be happening any moment now..."
          />
        )}
        <ExternalLink contentId={allessehId} type={ExternalLinkContentType.COLLECTION} isPublished />
        {latestEmbargoContent && (
          <div className={styles.embargoWarning}>
            <IconInfo />
            <Typography margin={{ right: 'xs' }} data-testid="page-embargo-date-label">
              Embargoed story for{' '}
              {formatInTimeZone(
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                safelyParseContent<AllessehContent>(latestEmbargoContent.content).data.attributes.embargo_datetime_utc!,
                publicationSettings?.publicationSetting.preferredTimezone,
                "M/dd/yyyy 'at' hh:mm aaaa",
                { showTimezoneAbbreviation: true }
              )}
            </Typography>
          </div>
        )}
        <PagePublishingButtons />
      </div>
    </Wrapper>
  );
};

import { Button, Dialog, FormLabel, Radio } from '@screentone/core';

import { useNewsletterTypes } from 'features/newsletters/hooks/useNewsletterTypes';
import { useConvertedProperty } from 'hooks';
import { ComponentRef } from 'utils/componentRef';

interface NewsletterSelectDialogProps {
  open: boolean;
  closeModal: () => void;
  renderNode: () => void;
  handleCancelClick: () => void;
  handleBeginCampaignClick: () => void;
  setSelectedNewsletterId: (id: string) => void;
  status: string;
  selectedNewsletterId: string;
  componentRef: ComponentRef;
}

export const NewsletterSelectDialog = ({
  open,
  closeModal,
  componentRef,
  renderNode,
  handleCancelClick,
  handleBeginCampaignClick,
  status,
  setSelectedNewsletterId,
  selectedNewsletterId
}: NewsletterSelectDialogProps) => {
  const currentProperty = useConvertedProperty();

  const { newsletterTypes } = useNewsletterTypes({
    publicationKey: currentProperty ?? ''
  });

  return (
    <>
      {open && (
        <Dialog
          aria-labelledby="dialogTitle"
          aria-describedby="dialogDesc"
          onDismiss={closeModal}
          renderNode={renderNode}
          status={status}
          componentRef={componentRef}
        >
          <Dialog.Title id="dialogTitle" data-testid="newsletter-choose-title">
            Choose Newsletter
          </Dialog.Title>
          <Dialog.Content id="dialogDesc">
            {newsletterTypes?.map((newsletterItem) => (
              <div key={newsletterItem.id}>
                <FormLabel label={newsletterItem.name} labelPosition="right" data-testid="newsletter-options-label">
                  <Radio
                    checked={newsletterItem.id === selectedNewsletterId}
                    id={newsletterItem.id}
                    name={newsletterItem.id}
                    onChange={(evt: Event) => setSelectedNewsletterId((evt.target as HTMLElement).id)}
                    data-testid="newsletter-options-radio"
                  />
                </FormLabel>
              </div>
            ))}
          </Dialog.Content>
          <Dialog.Actions>
            <Button secondary onClick={handleCancelClick} data-testid="newsletter-cancel">
              Cancel
            </Button>
            <Button primary onClick={handleBeginCampaignClick} data-testid="newsletter-begin-campaign">
              Begin Campaign
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </>
  );
};

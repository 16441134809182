import { format } from 'date-fns-tz';

export const TIMES = {
  ONE_SECOND: 1000,
  ONE_MINUTE: 60 * 1000,
  ONE_HOUR: 60 * 60 * 1000,
  ONE_DAY: 24 * 60 * 60 * 1000
};

export function formatDateToShortDateTime(timestamp: number, timezone = 'America/New_York'): string {
  return format(new Date(timestamp), 'MM/dd/yyyy hh:mm a', { timeZone: timezone });
}

export function isWithinTimeInterval(
  targetTime: number,
  referenceTime: number,
  intervalType: 'hour' | 'minute'
): boolean {
  const interval = intervalType === 'hour' ? TIMES.ONE_HOUR : TIMES.ONE_MINUTE;
  return targetTime <= referenceTime + interval;
}

export function formatMillisecondsToHHMMSS(ms: number) {
  const hours = String(Math.floor(ms / TIMES.ONE_HOUR)).padStart(2, '0');
  const minutes = String(Math.floor((ms % TIMES.ONE_HOUR) / TIMES.ONE_MINUTE)).padStart(2, '0');
  const seconds = String(Math.floor((ms % TIMES.ONE_MINUTE) / 1000)).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

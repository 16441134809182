import { useMemo } from 'react';
import { format } from 'date-fns';

import { SearchContentFilters } from 'components/search-content/components/search-content-filters/SearchContentFiltersContainer';
import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { useAllessehContentQuery, useDebounce } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import {
  AllessehQueryRule,
  AllessehQueryRuleFullText,
  AllessehQuerySortKeyTypes,
  AllessehQuerySortOrderType
} from 'hooks/useAllessehContentQuery';
import { BaseQueryOrigin, getBaseQuery } from 'utils/contentType';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';

const SEARCH_KEYS = ['UpstreamOriginId', 'Keyword', 'Page', 'Product', 'SectionName', 'SectionType'];
const SEARCH_DEBOUNCE_TIMEOUT = 500;
const ALLESSEH_DATE_FORMAT = 'MM-dd-yyyy';

const getFiltersForAndQuery = (filters: SearchContentFilters, disableEmbargo: boolean) => {
  const andQuery = [];

  if (filters.author) {
    andQuery.push({ term: { key: 'Author', value: filters.author } });
  }

  if (filters.embargo && !disableEmbargo) {
    andQuery.push({ term: { key: 'ContentStatus', value: 'embargo' } });
  }

  if (filters.sectionType) {
    andQuery.push({ term: { key: 'SectionType', value: filters.sectionType } });
  }

  if (filters.publishDates.afterUtc) {
    andQuery.push({
      date: {
        key: 'PublishedDateUtc',
        value: format(filters.publishDates.afterUtc, ALLESSEH_DATE_FORMAT),
        operand: 'GreaterEquals'
      }
    });
  }

  if (filters.publishDates.beforeUtc) {
    andQuery.push({
      date: {
        key: 'PublishedDateUtc',
        value: format(filters.publishDates.beforeUtc, ALLESSEH_DATE_FORMAT),
        operand: 'LessEquals'
      }
    });
  }

  return andQuery;
};

// eslint-disable-next-line unused-imports/no-unused-vars
const getFiltersForNotQuery = (filters: SearchContentFilters) => {
  const notQuery = [] as AllessehQueryRule[];

  // if (!filters.embargo && !disableEmbargo) {
  //   notQuery.push({ term: { key: 'ContentStatus', value: 'embargo' } });
  // }

  const liveCoverageCardFilterSet = filters.contentTypes.includes(
    PublicationSettingSearchableContentType.LiveCoverageCard
  );
  const liveCoverageStackFilterSet = filters.contentTypes.includes(
    PublicationSettingSearchableContentType.LiveCoverageStack
  );
  if (!liveCoverageCardFilterSet || !liveCoverageStackFilterSet) {
    if (liveCoverageCardFilterSet) {
      notQuery.push({ terms: { key: 'SectionType', value: ['stack'] } });
    }
    if (liveCoverageStackFilterSet) {
      notQuery.push({ terms: { key: 'SectionType', value: ['card', 'featured_content', 'featured_media'] } });
    }
  }

  return notQuery;
};

interface UseSearchProps {
  search: string;
  filters: SearchContentFilters;
  sort: AllessehQuerySortKeyTypes;
  sortOrder?: AllessehQuerySortOrderType;
  refreshTimestamp?: number;
}

const useSearchContent = ({ search, filters, sort, sortOrder, refreshTimestamp }: UseSearchProps) => {
  const { data: publicationSettings } = usePublicationSettings();
  const enabledContentTypes = publicationSettings?.publicationSetting.searchableContentTypes;

  const disableEmbargo = publicationSettings?.publicationSetting.disableEmbargo ?? false;

  const debouncedSearchQuery = useDebounce(search, SEARCH_DEBOUNCE_TIMEOUT);
  const searchQuery = debouncedSearchQuery
    ? [
        ...SEARCH_KEYS.map((key) => ({
          term: {
            key,
            value: debouncedSearchQuery
          }
        })),
        {
          full_text: { value: debouncedSearchQuery, parameters: [{ property: 'Body' }, { property: 'Headline' }] }
        } as AllessehQueryRuleFullText
      ]
    : [];
  const filtersForAndQuery = useMemo(() => getFiltersForAndQuery(filters, disableEmbargo), [filters, disableEmbargo]);
  const filtersForNotQuery = useMemo(() => getFiltersForNotQuery(filters), [filters]);
  const baseQuery = getBaseQuery({
    contentTypes: filters.contentTypes,
    publicationSettings: publicationSettings?.publicationSetting,
    origin: BaseQueryOrigin.SEARCH
  });
  const fullQuery = mergeAllessehQueryBodies(
    baseQuery,
    JSON.stringify({
      count: 10,
      query: {
        or: searchQuery,
        and: filtersForAndQuery,
        not: filtersForNotQuery
      },
      sort: [{ key: sort, order: sortOrder ?? AllessehQuerySortOrderType.DESC }]
    })
  );

  return useAllessehContentQuery(fullQuery, {
    enabled: !!enabledContentTypes,
    refreshTimestamp
  });
};

export default useSearchContent;

import { useCallback, useEffect, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Alert, Box, Button, Checkbox, FormHelperText, FormLabel, Group, Input, Textarea } from '@screentone/core';

import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { PageModuleItem, PageModuleItemType } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { SnippetyResponse, useSnippetyQuery } from 'hooks/useSnippetyQuery';
import { isValidURL } from 'utils/url';
import styles from './LinkItemForm.module.scss';

interface LinkItemFormProps {
  defaultHeadline: string;
  defaultMedia?: string | null;
  defaultSummary?: string | null;
  defaultValidationBypassed?: boolean;
  defaultUrl: string;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  handleCancel(): void;
  handleSubmit(newModuleItem: PageModuleItem): void | undefined;
}

const urlErrorMessages = {
  notFound: 'Article with url not found!',
  invalidUrl: 'Invalid URL!',
  notExternal: 'Link items are only for external resources! Please use Content Items instead.'
};

const IM_ID_REGEX = /[iI][mM]-\d{4,}/i;
const ONLY_IM_ID = new RegExp(`^${IM_ID_REGEX.source}$`);

const LinkItemForm = ({
  defaultHeadline,
  defaultMedia,
  defaultSummary,
  defaultUrl,
  defaultValidationBypassed,
  dragHandleProps,
  handleCancel,
  handleSubmit
}: LinkItemFormProps) => {
  // if media is an image url, extract the im-id from the url
  const defaultImId = defaultMedia?.match(IM_ID_REGEX)?.[0] ?? '';

  const [errors, setErrors] = useState<string[]>([]);
  const [headline, setHeadline] = useState(defaultHeadline);
  const [media, setMedia] = useState(defaultImId);
  const [summary, setSummary] = useState(defaultSummary ?? '');
  const [currentUrl, setCurrentUrl] = useState(defaultUrl);
  const [previousUrl, setPreviousUrl] = useState('');
  const [bypassValidation, setBypassValidation] = useState<boolean>(!!defaultValidationBypassed);
  const [mediaValidationError, setMediaValidationError] = useState(false);
  const [urlValidationError, setCurrentUrlValidationError] = useState<string | null>(null);

  const { currentProperty, currentPropertyObject, user } = useAuth();
  const { data } = usePublicationSettings();

  const {
    data: snippetyData,
    error: snippetyError,
    refetch: refetchSnippetyData,
    fetchStatus
  } = useSnippetyQuery(currentUrl, { enabled: false });

  const mediaUrl = `${data?.publicationSetting.snippetyIMDomain}/${media}`;

  const { setIsEditingLinkedItem } = usePagePublish();

  const totalWords = summary.length ? summary.trim().split(' ').length : 0;
  const isFetchingFromSnippety = fetchStatus === 'fetching';
  const isEditingExistingLinkedItem = !!defaultUrl;
  const isInternalUrl = urlValidationError === urlErrorMessages.notExternal;
  const isAdminUser = !!user?.app_admin;
  const showBypassValidationCheckbox = isInternalUrl && isAdminUser;

  const typedSnippetyData = snippetyData ? (snippetyData as SnippetyResponse) : null;

  const setData = useCallback(() => {
    if (typedSnippetyData?.title) {
      setHeadline(typedSnippetyData.title);
    }
    if (typedSnippetyData?.desc) {
      setSummary(typedSnippetyData.desc);
    }
  }, [typedSnippetyData]);

  const handleUrlOnBlur = async () => {
    setPreviousUrl(currentUrl);
    if (isValidURL(currentUrl) && currentUrl !== previousUrl) {
      await refetchSnippetyData();
    } else if (currentUrl && !isValidURL(currentUrl)) {
      setCurrentUrlValidationError(urlErrorMessages.invalidUrl);
    } else if (!currentUrl) {
      setCurrentUrlValidationError(null);
    }
  };

  const handleBypassValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBypassValidation(e.target.checked);
    if (e.target.checked) {
      setCurrentUrlValidationError(null);
      setData();
    } else {
      setCurrentUrlValidationError(urlErrorMessages.notExternal);
    }
  };

  useEffect(() => {
    if (
      typedSnippetyData &&
      currentProperty &&
      typedSnippetyData.provider?.includes(currentProperty) &&
      !bypassValidation
    ) {
      setCurrentUrlValidationError(urlErrorMessages.notExternal);
      return;
    }
    setCurrentUrlValidationError(null);
    setData();
  }, [currentProperty, setData, typedSnippetyData, snippetyError, bypassValidation]);

  const validation: string[] = [
    !headline && 'headline',
    !currentUrl && 'url',
    media && !IM_ID_REGEX.test(media) && 'media'
  ].filter((v: string | false): v is string => !!v);

  return (
    <Box {...dragHandleProps}>
      <Box.Title className={styles.title} padding={{ left: 'md', right: 'sm', vertical: 'md' }}>
        Add Linked Item
      </Box.Title>

      <Box.Content padding={{ all: 'md' }}>
        {!!errors.length && (
          <Alert onDismiss={() => setErrors([])} type="error">
            The following field{errors.length > 1 ? 's are' : ' is'} empty or invalid: {errors.join(', ')}.
          </Alert>
        )}
        <Group direction="column" gap="md">
          <FormLabel fullWidth label="URL" required>
            <Input
              error={errors.includes('url') || !!urlValidationError}
              onBlur={() => handleUrlOnBlur()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentUrl(e.target.value)}
              value={currentUrl}
              placeholder="Add URL"
              disabled={isFetchingFromSnippety}
            />
            {urlValidationError && <FormHelperText error>{urlValidationError}</FormHelperText>}
            {showBypassValidationCheckbox && (
              <FormLabel label="Bypass Validation" labelPosition="right">
                <Checkbox
                  name="Bypass Validation"
                  value={bypassValidation}
                  checked={bypassValidation}
                  onChange={handleBypassValidation}
                />
              </FormLabel>
            )}
          </FormLabel>
          <FormLabel fullWidth label="Headline" required>
            <Input
              error={errors.includes('headline')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHeadline(e.target.value)}
              placeholder="Headline"
              value={headline}
            />
          </FormLabel>
          <FormLabel fullWidth label="Image">
            <Input
              error={errors.includes('media') || mediaValidationError}
              onBlur={() => {
                if (media && !ONLY_IM_ID.test(media)) setMediaValidationError(true);
                else setMediaValidationError(false);
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMedia(e.target.value);
                if (ONLY_IM_ID.test(e.target.value) || !e.target.value) setMediaValidationError(false);
              }}
              placeholder={` ${currentPropertyObject?.name} Image Manager ID`}
              value={media}
            />
            {(errors.includes('media') || mediaValidationError) && (
              <FormHelperText error>Invalid Image ID. ID format must be &quot;im-[some numbers]&quot;</FormHelperText>
            )}
          </FormLabel>
          <FormLabel fullWidth label="Summary">
            <Textarea
              error={errors.includes('summary')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSummary(e.target.value)}
              placeholder="Summary"
              value={summary}
            />
            <Group.Item align="flex-end">
              <FormHelperText>
                {totalWords} {totalWords === 1 ? 'word' : 'words'}
              </FormHelperText>
            </Group.Item>
          </FormLabel>

          <Group align="end" fullWidth valign="end">
            <Group gap="md">
              <Button
                onClick={() => {
                  if (!isEditingExistingLinkedItem) handleCancel();
                  setIsEditingLinkedItem(false);
                }}
                secondary
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (validation.length) {
                    setErrors(validation);
                  } else {
                    handleSubmit({
                      itemType: PageModuleItemType.LinkedItem,
                      itemFields: {
                        linkedItem: {
                          headline,
                          media: media ? mediaUrl : '',
                          summary,
                          validationBypassed: bypassValidation,
                          url: currentUrl
                        }
                      }
                    });
                    setIsEditingLinkedItem(false);
                  }
                }}
                disabled={!!validation.length || isFetchingFromSnippety}
                primary
              >
                Save linked item
              </Button>
            </Group>
          </Group>
        </Group>
      </Box.Content>
    </Box>
  );
};

export default LinkItemForm;

import { isSameDay } from 'date-fns';

import { DateGroup } from './RevisionSelector';

export function filterContentByDate(dateGroup: DateGroup, selectedDate?: number | null): number[] {
  if (!selectedDate) {
    return [];
  }
  const { type, dates } = dateGroup;
  if (!dates) {
    return [];
  }
  const now = Date.now();
  const startOfDay = new Date(selectedDate).setHours(0, 0, 0, 0);
  let datesToReturn: number[] = [];

  if (type === 'published') {
    datesToReturn = dates;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (selectedDate < startOfDay) {
    // Past dates: No scheduled content
    datesToReturn = [];
  } else if (isSameDay(selectedDate, now)) {
    // Same day: Show scheduled content not yet published
    datesToReturn = dates.filter((date) => date > now);
  } else {
    // Future dates: Show all scheduled content
    datesToReturn = dates;
  }

  return datesToReturn;
}

/* eslint-disable import/no-cycle */
import React, { createContext, useCallback, useState } from 'react';
import { Divider } from '@screentone/core';
import classnames from 'classnames';

import { EntityComponent } from 'contexts/datamodel/DataModelContext';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import styles from './contextActions.module.scss';

type ComponentFnParams = {
  hierarchyId?: string;
  entity?: EntityComponent;
  index?: number;
  isHistory?: boolean;
  extraProperties?: {
    numTotalItemsUsed?: number;
    numItemsToShow?: number;
    baseQuery?: string;
    allessehContent?: AllessehContent;
    isExternalCollection?: boolean;
    jsonQueryStr?: string;
    setJsonQueryStr?: (newQueryStr: string) => void;
    isContentIdExcludedInQueryRules?: boolean;
  };
};

type ActionDefinition = {
  component: (params: ComponentFnParams) => JSX.Element;
  showIf?: (params: ComponentFnParams) => boolean;
  withDivider?: 'top' | 'bottom';
};

export type MenuActions = {
  draftArticle?: ActionDefinition[];
  draftExternalCollection?: ActionDefinition[];
  draftQuery?: ActionDefinition[];
  draftLinkedItem?: ActionDefinition[];
  draftModule?: ActionDefinition[];
  historyArticle?: ActionDefinition[];
  historyExternalCollection?: ActionDefinition[];
  historyQuery?: ActionDefinition[];
  historyLinkedItem?: ActionDefinition[];
  historyModule?: ActionDefinition[];
  articleSearch?: ActionDefinition[];
  articleQueryModal?: ActionDefinition[];
  collectionSearch?: ActionDefinition[];
  collectionDraftQueryArticle?: ActionDefinition[];
};

export const MenuActionsKeys = {
  DraftArticle: 'draftArticle',
  DraftExternalCollection: 'draftExternalCollection',
  DraftQuery: 'draftQuery',
  DraftModule: 'draftModule',
  HistoryArticle: 'historyArticle',
  HistoryExternalCollection: 'historyExternalCollection',
  HistoryQuery: 'historyQuery',
  HistoryModule: 'historyModule',
  ArticleSearch: 'articleSearch',
  ArticleQueryModal: 'articleQueryModal',
  CollectionSearch: 'collectionSearch',
  CollectionDraftQueryArticle: 'collectionDraftQueryArticle'
} as const;

interface ContextMenuActionsContext {
  renderActions: (actionType: keyof MenuActions, params: ComponentFnParams) => (JSX.Element | null)[] | null;
  hasRenderActions: (actionType: keyof MenuActions, params: ComponentFnParams) => boolean;
  setActionModalComponent: (modalComponent: JSX.Element | null) => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DEFAULT_STATE: ContextMenuActionsContext = {
  renderActions: () => [],
  hasRenderActions: () => false,
  setActionModalComponent: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {}
};

interface ContextMenuActionsProviderProps {
  actions: MenuActions;
  children: React.ReactNode;
}

export const ContextMenuActionsContext = createContext(DEFAULT_STATE);

export const ContextMenuActionsProvider = ({ actions, children }: ContextMenuActionsProviderProps) => {
  const [actionModal, setActionModal] = useState<JSX.Element | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Split actions into groups based on `withDivider` property
  // Actions that have the `withDivider` property set, are displayed in their own group
  // Consecutive actions that don't have the `withDivider` property set, are displayed in the same group, wrapped in a flex container
  const getSplitActions = (selectedActions: ActionDefinition[]) =>
    selectedActions.reduce<ActionDefinition[][]>((acc, actionDefinition) => {
      const hasTopDivider = actionDefinition.withDivider === 'top';
      const hasBottomDivider = actionDefinition.withDivider === 'bottom';

      if (hasTopDivider) {
        acc.push([actionDefinition]);
      } else if (hasBottomDivider) {
        if (Array.isArray(acc[acc.length - 1])) {
          acc[acc.length - 1].push(actionDefinition);
        } else {
          acc.push([actionDefinition]);
        }
        acc.push([]);
      } else if (Array.isArray(acc[acc.length - 1])) {
        acc[acc.length - 1].push(actionDefinition);
      } else {
        acc.push([actionDefinition]);
      }

      return acc;
    }, []);

  const hasRenderActions = (actionType: keyof MenuActions, params: ComponentFnParams) => {
    const selectedActions = actions[actionType];

    if (selectedActions?.length) {
      const splitActions = getSplitActions(selectedActions);

      return splitActions.some((group) =>
        group.some((actionDefinition) => !actionDefinition.showIf || actionDefinition.showIf(params))
      );
    }

    return false;
  };

  const renderActions = (actionType: keyof MenuActions, params: ComponentFnParams) => {
    const selectedActions = actions[actionType];

    if (!selectedActions || selectedActions.length === 0) {
      return null;
    }

    const splitActions = getSplitActions(selectedActions);

    // Render the split actions
    return splitActions.map((group, i) => {
      const willRenderAtLeastOneAction = group.some(
        (actionDefinition) => !actionDefinition.showIf || actionDefinition.showIf(params)
      );

      const actionWithDivider = group.find((actionDefinition) => !!actionDefinition.withDivider);
      if (actionWithDivider) {
        const { withDivider } = actionWithDivider;
        return (
          <div key={i}>
            {willRenderAtLeastOneAction && withDivider === 'top' && <Divider />}
            <div className={classnames({ [styles.dividerGroup]: willRenderAtLeastOneAction })}>
              {group.map((action, j) => {
                const component = action.component(params);

                if (action.showIf && !action.showIf(params)) {
                  return null;
                }

                return <div key={j}>{component}</div>;
              })}
            </div>
            {willRenderAtLeastOneAction && withDivider === 'bottom' && <Divider />}
          </div>
        );
      }

      return (
        <div key={i} className={willRenderAtLeastOneAction ? styles.normalGroup : ''}>
          {group.map((actionDefinition, j) => {
            const component = actionDefinition.component(params);

            if (actionDefinition.showIf && !actionDefinition.showIf(params)) {
              return null;
            }

            return <div key={j}>{component}</div>;
          })}
        </div>
      );
    });
  };

  const setActionModalComponent = useCallback((modalComponent: JSX.Element | null) => {
    setActionModal(modalComponent);
  }, []);

  const value = { renderActions, hasRenderActions, setActionModalComponent, isModalOpen, setIsModalOpen };
  return (
    <ContextMenuActionsContext.Provider value={value}>
      {children}
      {isModalOpen && actionModal}
    </ContextMenuActionsContext.Provider>
  );
};

import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Group, Token, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { OffPlatformDto } from 'data/generated/graphql';
import styles from './OffPlatformSearch.module.scss';

const SMALL_WIDTH_THRESHOLD = 420;

interface CardProps {
  offPlatform: OffPlatformDto;
  containerWidth: number;
}

const Card = ({ offPlatform, containerWidth }: CardProps) => {
  const { currentProperty } = useAuth();
  const navigate = useNavigate();

  const isSmall = containerWidth <= SMALL_WIDTH_THRESHOLD;
  const itemsLength = offPlatform.root.collection.length;

  const handleClick = (idCreatedUtc: string) => () => {
    navigate(`/${currentProperty ?? ''}/off-platform/${idCreatedUtc}`);
  };

  return (
    <Box
      padding={{ all: 'sm' }}
      onClick={handleClick(offPlatform.idCreatedUtc)}
      className={classnames(styles.item)}
      data-testid="offplatform-collection-content"
    >
      {!isSmall ? (
        <>
          <Group align="space-between" gap="none">
            <Typography data-testid="offplatform-content-headline">{offPlatform.metadata.name}</Typography>
            <Wrapper>
              {offPlatform.metadata.updatedUtc && (
                <Typography variant="note" inline>
                  Last published {`${format(offPlatform.metadata.updatedUtc, 'MMM dd, yyyy')}`}
                </Typography>
              )}
              {offPlatform.metadata.isArchived && (
                <Token color="gray" margin={{ left: 'sm' }}>
                  Archived
                </Token>
              )}
            </Wrapper>
          </Group>
          <Group align="space-between" gap="none" margin={{ top: 'sm' }}>
            <Typography variant="note">{offPlatform.metadata.allessehId || <div>&nbsp;</div>}</Typography>
            <Wrapper>
              {itemsLength > 0 && (
                <Typography
                  variant="note"
                  margin={{ right: 'sm' }}
                  inline
                  data-testid="offplatform-collection-item-count"
                >
                  {itemsLength} {itemsLength > 1 ? 'items' : 'item'}
                </Typography>
              )}
            </Wrapper>
          </Group>
        </>
      ) : (
        <>
          <Typography>{offPlatform.metadata.name}</Typography>
          <Typography variant="note">{offPlatform.metadata.allessehId || ''}</Typography>
          <Group align="space-between">
            <Wrapper>
              {offPlatform.metadata.updatedUtc && (
                <Typography variant="note" inline margin={{ right: 'sm' }}>
                  {`${format(offPlatform.metadata.updatedUtc, 'MMM dd, yyyy')}`}
                </Typography>
              )}
            </Wrapper>
          </Group>
          <Group align="space-between">
            <Wrapper>
              {itemsLength > 0 && (
                <Typography variant="note" margin={{ right: 'sm' }} inline>
                  {itemsLength} {itemsLength > 1 ? 'items' : 'item'}
                </Typography>
              )}
              {offPlatform.metadata.isArchived && (
                <Token color="gray" margin={{ left: 'sm' }}>
                  Archived
                </Token>
              )}
            </Wrapper>
          </Group>
        </>
      )}
    </Box>
  );
};

export default Card;

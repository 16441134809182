import { useState } from 'react';
import { Tabs as TabsComponent, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { NewsletterDto, PublicationSetting } from 'data/generated/graphql';
import styles from '../../NewsletterEdit.module.scss';
import ContentSearch from '../content-search/ContentSearch';
import { NewsletterSettings } from '../newsletter-settings/NewsletterSettings';

enum Tabs {
  Articles,
  Settings
}

interface NewsletterMultitabSectionProps {
  campaign: NewsletterDto;
  readOnly?: boolean;
  newsletterName?: string;
  isScheduled?: boolean;
  cancelCampaign?: () => void;
  publicationSetting?: PublicationSetting;
}

export const NewsletterMultitabSection = ({
  campaign,
  readOnly,
  isScheduled = false,
  newsletterName,
  cancelCampaign,
  publicationSetting
}: NewsletterMultitabSectionProps) => {
  const [tabIndex, setTabIndex] = useState(readOnly ? Tabs.Settings : Tabs.Articles);

  return (
    <Wrapper>
      <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.Articles ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          disabled={readOnly}
          className={classnames({ [styles.disabledTab]: readOnly })}
        >
          Articles
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id2"
          aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
          aria-controls="tabpanel-id2"
          disabled={!(readOnly && campaign)}
          className={classnames({ [styles.disabledTab]: !readOnly })}
          data-testid="newsletters-settings-tab"
        >
          Settings
        </TabsComponent.Item>
      </TabsComponent>
      {tabIndex === Tabs.Articles && !readOnly && (
        <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
          <ContentSearch newsletterName={newsletterName} />
        </Wrapper>
      )}
      {tabIndex === Tabs.Settings && (
        <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
          <NewsletterSettings
            readOnly={readOnly}
            isScheduled={isScheduled}
            campaign={campaign}
            cancelCampaign={cancelCampaign}
            publicationSetting={publicationSetting}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};

import { useAppQuery } from 'data/query-client';
import restClient from 'data/restClient';

export interface ContentConstantsResponse {
  contentMap: Record<string, string[]>;
  publicationMap: Record<string, string>;
  allowedExternalAllessehCollections: string[];
  liveCoveragePublicationSourceUrls: { url: string; publication: string }[];
  excludedArticles: string[];
}

export function modifyPublicationMapResult(publicationMap: Record<string, string>, product: string): string {
  if (publicationMap[product] === 'penta') {
    return 'barrons';
  }
  return publicationMap[product];
}

const fetchContentConstants = async (): Promise<ContentConstantsResponse> => {
  const response = await restClient.get<ContentConstantsResponse>('/contentConstants');
  return response;
};

export const useContentConstants = (options?: { enabled: boolean }) =>
  useAppQuery<ContentConstantsResponse, Error>(['contentConstants'], fetchContentConstants, {
    ...options,
    shouldShowErrorAlert: true
  });

import type { ChangeEventHandler } from 'react';
import { useEffect, useState } from 'react';
import '@screentone/addon-autocomplete/css/index.css';
import { useAuth, useHeaderData } from '@screentone/addon-auth-wrapper';
import { AutoComplete } from '@screentone/addon-autocomplete';
import {
  Button,
  FormHelperText,
  FormLabel,
  Group,
  Input,
  Select,
  Switch,
  Textarea,
  Typography,
  Wrapper
} from '@screentone/core';

import { ResponsiveLoader } from 'components';
import {
  PreferredTimezoneInput,
  PublicationSettingSearchableContentType,
  PublicationSettingUpdateInput,
  usePublicationSettingUpdateMutation
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import styles from './PublicationSettings.module.scss';

const CONTENT_TYPES = Object.keys(PublicationSettingSearchableContentType) as PublicationSettingSearchableContentType[];

const PublicationSettings = () => {
  const [currentPublicationSetting, setCurrentPublicationSetting] = useState<PublicationSettingUpdateInput | null>(
    null
  );
  const [hasChanged, setHasChanged] = useState(false);
  // Auxiliary state variable to allow editing slug mappings as a string
  const [slugMappingsString, setSlugMappingsString] = useState('');
  const [slugMappingsError, setSlugMappingsError] = useState('');

  const { currentPropertyObject } = useAuth();
  const currentProperty = useConvertedProperty();
  const {
    state: { header }
  } = useHeaderData();

  const properties = header?.properties;

  const publicationSettingsQueryVariables = { publicationKey: currentProperty ?? '' };
  const { data, isLoading: isLoad, fetchStatus } = usePublicationSettings(publicationSettingsQueryVariables);
  const isLoading = isLoad && fetchStatus !== 'idle';

  const { mutateAsync, isLoading: isUpdating } = usePublicationSettingUpdateMutation();

  useEffect(() => {
    setCurrentPublicationSetting(data?.publicationSetting ?? null);
    setSlugMappingsString(JSON.stringify(data?.publicationSetting.slugMappings));
  }, [data]);

  const handleSetTags = (newTags: string[], setting: 'searchableContentTypes' | 'editableExternalPublications') => {
    if (currentPublicationSetting == null) {
      return;
    }
    if (setting === 'searchableContentTypes') {
      setCurrentPublicationSetting({
        ...currentPublicationSetting,
        [setting]: newTags as PublicationSettingSearchableContentType[]
      });
    } else {
      setCurrentPublicationSetting({
        ...currentPublicationSetting,
        [setting]: newTags
      });
    }
    setHasChanged(true);
  };

  const handleInputStringChange =
    (fieldName: keyof PublicationSettingUpdateInput): ChangeEventHandler<HTMLInputElement> =>
    (e: React.FormEvent<HTMLInputElement>) => {
      if (currentPublicationSetting == null) {
        return;
      }
      const { value } = e.target as HTMLInputElement;
      if (fieldName === 'slugMappings') {
        setSlugMappingsString(value);
      } else {
        setCurrentPublicationSetting({ ...currentPublicationSetting, [fieldName]: value });
      }
      setHasChanged(true);
    };

  const handleSelectChange =
    (fieldName: keyof PublicationSettingUpdateInput) => (e: React.FormEvent<HTMLInputElement>) => {
      if (currentPublicationSetting === null) {
        return;
      }
      const { checked } = e.target as HTMLInputElement;
      setCurrentPublicationSetting({ ...currentPublicationSetting, [fieldName]: checked });
      setHasChanged(true);
    };

  const handlePreferredTimezoneChange =
    (fieldName: keyof PreferredTimezoneInput): ChangeEventHandler<HTMLInputElement> =>
    (e: React.FormEvent<HTMLInputElement>) => {
      if (currentPublicationSetting === null) return;

      const { value } = e.target as HTMLInputElement;
      setCurrentPublicationSetting({
        ...currentPublicationSetting,
        preferredTimezone: { ...currentPublicationSetting.preferredTimezone, [fieldName]: value }
      });
      setHasChanged(true);
    };

  const handleSave = async () => {
    if (currentPublicationSetting == null) {
      return;
    }

    let slugMappings;
    try {
      slugMappings = JSON.parse(slugMappingsString) as Record<string, string>;
    } catch (error: unknown) {
      setSlugMappingsError((error as Error).message);
      return;
    }

    setCurrentPublicationSetting({ ...currentPublicationSetting, slugMappings });

    if (currentProperty) {
      await mutateAsync({
        publicationSettingUpdateInput: {
          ...currentPublicationSetting,
          slugMappings
        }
      });
      setHasChanged(false);
      setSlugMappingsError('');
    }
  };

  const selectedSearchableContentTypes = currentPublicationSetting?.searchableContentTypes;
  const selectedEditableExternalPublications = currentPublicationSetting?.editableExternalPublications ?? [];

  return (
    <Wrapper margin={{ top: 'lg' }}>
      {!currentPublicationSetting ? (
        <ResponsiveLoader />
      ) : (
        <>
          <div className={styles.headerWrapper}>
            <Typography variant="header2" margin={{ all: 'none' }}>
              {currentPropertyObject?.name} Settings
            </Typography>
            <Button primary onClick={handleSave} disabled={isLoading || isUpdating || !hasChanged}>
              Save
            </Button>
          </div>
          <Wrapper margin={{ bottom: 'lg' }}>
            <FormLabel label="Allowed Searchable Content Types" margin={{ top: 'md' }} />
            <AutoComplete
              disabled={isLoading}
              dropDownItems={CONTENT_TYPES.filter((c) => !selectedSearchableContentTypes?.includes(c))}
              placeholder="Select searchable content types"
              onDismiss={(tags: string[]) => handleSetTags(tags, 'searchableContentTypes')}
              onSelect={(tags: string[]) => handleSetTags(tags, 'searchableContentTypes')}
              tags={selectedSearchableContentTypes}
            />
          </Wrapper>
          <Wrapper margin={{ bottom: 'lg' }}>
            <FormLabel label="Editable External Publications" margin={{ top: 'md' }} />
            <AutoComplete
              disabled={isLoading}
              dropDownItems={
                properties
                  ?.map((property) => property.value)
                  ?.filter((c) => !selectedEditableExternalPublications.includes(c)) ?? []
              }
              placeholder="Select editable external publications"
              onDismiss={(tags: string[]) => handleSetTags(tags, 'editableExternalPublications')}
              onSelect={(tags: string[]) => handleSetTags(tags, 'editableExternalPublications')}
              tags={selectedEditableExternalPublications}
            />
          </Wrapper>
          <Wrapper margin={{ bottom: 'lg' }}>
            <FormLabel label="Base Route" labelPosition="top" className={styles.inputForm}>
              <Input
                placeholder="Enter the url prefix for all pages in this publication"
                value={currentPublicationSetting.baseRoute}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                onChange={handleInputStringChange('baseRoute')}
              />
            </FormLabel>
          </Wrapper>
          <Wrapper margin={{ bottom: 'lg' }}>
            <FormLabel label="Preview Base Route" labelPosition="top" className={styles.inputForm}>
              <Input
                placeholder="Enter the preview url prefix for all pages in this publication"
                value={currentPublicationSetting.previewBaseRoute}
                onChange={handleInputStringChange('previewBaseRoute')}
              />
            </FormLabel>
          </Wrapper>
          <Wrapper margin={{ bottom: 'lg' }}>
            <FormLabel className={styles.inputForm} label="Base Allesseh Query" labelPosition="top">
              <Input
                placeholder="Enter the JSON Allesseh query that should be used for article* queries in this publication"
                value={currentPublicationSetting.baseAllessehQuery}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                onChange={handleInputStringChange('baseAllessehQuery')}
              />
            </FormLabel>
          </Wrapper>
          {currentPublicationSetting.baseProductCardAllessehQuery && (
            <Wrapper margin={{ bottom: 'lg' }}>
              <FormLabel className={styles.inputForm} label="Base Product Card Allesseh Query" labelPosition="top">
                <Input
                  placeholder="Enter the JSON Allesseh query that should be used for product card queries in this publication"
                  value={currentPublicationSetting.baseProductCardAllessehQuery}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  onChange={handleInputStringChange('baseProductCardAllessehQuery')}
                />
              </FormLabel>
            </Wrapper>
          )}
          <Wrapper margin={{ bottom: 'lg' }}>
            <FormLabel label="Slug mappings" labelPosition="top" className={styles.inputForm}>
              <Textarea
                placeholder="Enter an object of slugs to revision group key mappings"
                value={slugMappingsString}
                onChange={handleInputStringChange('slugMappings')}
                error={!!slugMappingsError}
              />
              {!!slugMappingsError && <FormHelperText error>{slugMappingsError}</FormHelperText>}
            </FormLabel>
          </Wrapper>
          <Wrapper margin={{ bottom: 'lg' }}>
            <FormLabel label="Image Manager Snippety Domain" labelPosition="top" className={styles.inputForm}>
              <Input
                placeholder="Enter the IM domain that Snippety will use as base URL"
                value={currentPublicationSetting.snippetyIMDomain}
                onChange={handleInputStringChange('snippetyIMDomain')}
              />
            </FormLabel>
          </Wrapper>
          {currentProperty === 'wsj' && (
            <>
              <Wrapper margin={{ bottom: 'lg' }}>
                <FormLabel label="Video API Snippety Domain" labelPosition="top" className={styles.inputForm}>
                  <Input
                    placeholder="Enter the Video API domain that Snippety will use as base URL"
                    value={currentPublicationSetting.snippetyVideoDomain}
                    onChange={handleInputStringChange('snippetyVideoDomain')}
                  />
                </FormLabel>
              </Wrapper>
              <Wrapper margin={{ bottom: 'lg' }}>
                <FormLabel label="Chartlos API Snippety Domain" labelPosition="top" className={styles.inputForm}>
                  <Input
                    placeholder="Enter the Chartlos API domain that Snippety will use as base URL"
                    value={currentPublicationSetting.snippetyChartDomain}
                    onChange={handleInputStringChange('snippetyChartDomain')}
                  />
                </FormLabel>
              </Wrapper>
            </>
          )}
          <Wrapper margin={{ bottom: 'lg' }}>
            <Group wrap={false}>
              <FormLabel label="Preferred timezone" labelPosition="top" className={styles.inputForm}>
                <Select
                  defaultValue={currentPublicationSetting.preferredTimezone.timezone}
                  onChange={handlePreferredTimezoneChange('timezone')}
                >
                  {Intl.supportedValuesOf('timeZone').map((tz: string) => (
                    <option value={tz} key={tz}>
                      {tz}
                    </option>
                  ))}
                </Select>
              </FormLabel>
              <FormLabel label="Timezone abbreviation" labelPosition="top" className={styles.inputForm}>
                <Input
                  placeholder="Enter the timezone abbreviation"
                  value={currentPublicationSetting.preferredTimezone.timezoneAbbreviation}
                  onChange={handlePreferredTimezoneChange('timezoneAbbreviation')}
                />
              </FormLabel>
            </Group>
          </Wrapper>
          {currentPublicationSetting.disableAltSummEdit !== null && (
            <Wrapper margin={{ bottom: 'lg' }}>
              <FormLabel className={styles.inputForm} label="Disable alt summ edit" labelPosition="top">
                <Switch
                  checked={currentPublicationSetting.disableAltSummEdit}
                  onChange={handleSelectChange('disableAltSummEdit')}
                />
              </FormLabel>
            </Wrapper>
          )}

          {currentPublicationSetting.disableEmbargo !== null && (
            <Wrapper margin={{ bottom: 'lg' }}>
              <FormLabel className={styles.inputForm} label="Disable Embargo" labelPosition="top">
                <Switch
                  checked={currentPublicationSetting.disableEmbargo}
                  onChange={handleSelectChange('disableEmbargo')}
                />
              </FormLabel>
            </Wrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default PublicationSettings;

import {
  IconArticle,
  IconBarrons,
  IconInteractive,
  IconMansionglobal,
  IconMarketwatch,
  IconMic,
  IconPackage,
  IconPenta,
  IconShoppingCart,
  IconVideo,
  IconWsj,
  IconWsjopinion,
  IconZap,
  SvgIcon,
  Token
} from '@screentone/core';

import { PublicationSettingSearchableContentType } from 'data/generated/graphql';

const IconAfp = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 26 18">
    <path
      d="M18.1162 17C17.5855 17 17.1552 16.5697 17.1552 16.039V10C17.1552 9.05719 17.1552 8.58579 17.4481 8.29289C17.741 8 18.2124 8 19.1552 8H20.7418C21.4314 8 22.0188 8.13037 22.5041 8.39111C22.9894 8.64893 23.3593 9.00781 23.6138 9.46777C23.8713 9.9248 24 10.4521 24 11.0498C24 11.6475 23.8698 12.1748 23.6094 12.6318C23.349 13.0889 22.9717 13.4448 22.4775 13.6997C21.9862 13.9546 21.3914 14.082 20.693 14.082H18.407V12.5571H20.3823C20.7522 12.5571 21.057 12.4941 21.2967 12.3682C21.5394 12.2393 21.7199 12.062 21.8383 11.8364C21.9596 11.6079 22.0203 11.3457 22.0203 11.0498C22.0203 10.751 21.9596 10.4902 21.8383 10.2676C21.7199 10.042 21.5394 9.86768 21.2967 9.74463C21.0541 9.61865 20.7463 9.55566 20.3734 9.55566H19.0773V16.039C19.0773 16.5697 18.647 17 18.1162 17Z"
      fill="#636E72"
    />
    <path
      d="M2.27802 16.3354C2.14772 16.732 1.77752 17 1.36013 17C0.695059 17 0.228853 16.3437 0.447833 15.7157L2.73063 9.16912C2.97475 8.46904 3.63503 8 4.37646 8C5.11817 8 5.77865 8.46939 6.02255 9.16985L8.30196 15.716C8.52063 16.344 8.05439 17 7.38943 17C6.97179 17 6.60142 16.7317 6.47127 16.3348L4.41226 10.0566H4.34124L2.27802 16.3354ZM1.93092 13.4624H6.79595V14.9478H1.93092V13.4624Z"
      fill="#636E72"
    />
    <path
      d="M10.8043 17C10.2735 17 9.84325 16.5697 9.84325 16.039V10C9.84325 9.05719 9.84325 8.58579 10.1361 8.29289C10.429 8 10.9004 8 11.8433 8H15.078C15.5112 8 15.8624 8.3512 15.8624 8.78442C15.8624 9.21765 15.5112 9.56885 15.078 9.56885H11.7653V11.7134H14.6785C15.1117 11.7134 15.4629 12.0646 15.4629 12.4978C15.4629 12.931 15.1117 13.2822 14.6785 13.2822H11.7653V16.039C11.7653 16.5697 11.335 17 10.8043 17Z"
      fill="#636E72"
    />
  </SvgIcon>
);

const ContentTypeToken = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (props.type as PublicationSettingSearchableContentType | string) {
    case PublicationSettingSearchableContentType.CommerceProduct.toLowerCase():
    case PublicationSettingSearchableContentType.CommerceProduct:
      return <Token icon={IconShoppingCart as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Article.toLowerCase():
    case PublicationSettingSearchableContentType.Article:
      return <Token icon={IconArticle as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Audio.toLowerCase():
    case PublicationSettingSearchableContentType.Audio:
      return <Token icon={IconMic as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Video.toLowerCase():
    case PublicationSettingSearchableContentType.Video:
      return <Token icon={IconVideo as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.LiveCoverage.toLowerCase():
    case PublicationSettingSearchableContentType.LiveCoverage:
      return <Token icon={IconZap as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Tappable.toLowerCase():
    case PublicationSettingSearchableContentType.Tappable:
    case PublicationSettingSearchableContentType.Webstory.toLowerCase():
    case PublicationSettingSearchableContentType.Webstory:
      return <Token icon={IconInteractive as SvgComponent} {...props} />;
    // linked items are not a searchable content type
    case 'linkeditem':
      return <Token icon={IconPackage as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Marketwatch.toLowerCase():
    case PublicationSettingSearchableContentType.Marketwatch:
      return <Token icon={IconMarketwatch as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Barrons.toLowerCase():
    case PublicationSettingSearchableContentType.Barrons:
      return <Token icon={IconBarrons as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Penta.toLowerCase():
    case PublicationSettingSearchableContentType.Penta:
      return <Token icon={IconPenta as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.MansionGlobal.toLowerCase():
    case PublicationSettingSearchableContentType.MansionGlobal:
      return <Token icon={IconMansionglobal as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Afp.toLowerCase():
    case PublicationSettingSearchableContentType.Afp:
      return <Token icon={IconAfp as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Wsj.toLowerCase():
    case PublicationSettingSearchableContentType.Wsj:
      return <Token icon={IconWsj as SvgComponent} {...props} />;
    case PublicationSettingSearchableContentType.Opinion.toLowerCase():
    case PublicationSettingSearchableContentType.Opinion:
      return <Token icon={IconWsjopinion as SvgComponent} {...props} />;
    default:
      return <Token icon={IconArticle as SvgComponent} {...props} />;
  }
};

export default ContentTypeToken;

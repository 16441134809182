import { Typography, Wrapper } from '@screentone/core';

interface SettingsProps {
  children: React.ReactNode;
}

export const Settings = ({ children }: SettingsProps) => (
  <div>
    <Typography margin={{ bottom: 'md' }} variant="header3" data-testid="page-settings-title">
      Settings
    </Typography>

    <Wrapper>{children}</Wrapper>
  </div>
);

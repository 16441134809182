import { Link } from 'react-router-dom';
import { HeaderBar, useAuth } from '@screentone/addon-auth-wrapper';
import { Header, useHeader } from '@screentone/core';

interface UseHeader {
  closeDrawer: () => void;
}

const mobileSidebarLabel = 'Curation Navigation';

const createMobileSidebarComponentProps = (currentProperty: string | null) => {
  const componentProps = [
    { path: '', name: 'Pages' },
    { path: '/collections', name: 'Collections' },
    { path: '/off-platform', name: 'Off-Platform' }
  ];
  if (currentProperty === 'wsj') {
    componentProps.push({ path: '/issues', name: 'ITP Issues' });
  }
  if (currentProperty === 'barrons') {
    componentProps.push({ path: '/banners', name: 'Banners' });
  }
  return componentProps;
};

const HeaderBarWrapper = ({ children }: { children: React.ReactNode }) => {
  const { currentProperty } = useAuth();
  const useHeaderHook = useHeader as () => UseHeader;
  const { closeDrawer } = useHeaderHook();
  const mobileSidebarComponentProps = createMobileSidebarComponentProps(currentProperty);
  const createMobileSidebarComponent = () => (
    <Header.LinkGroup>
      {mobileSidebarComponentProps.map(({ path, name }) => (
        <Header.Link
          key={name}
          componentEl={Link}
          to={`/${(currentProperty && currentProperty) ?? ''}${path}`}
          onClick={() => closeDrawer()}
        >
          {name}
        </Header.Link>
      ))}
    </Header.LinkGroup>
  );
  const mobileSidebar = currentProperty && {
    label: mobileSidebarLabel,
    component: createMobileSidebarComponent()
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HeaderBar mobileSidebar={mobileSidebar}>{children}</HeaderBar>
  );
};

export default HeaderBarWrapper;

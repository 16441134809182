import { useEffect, useState } from 'react';
import { Button, IconCopy, Tooltip, Wrapper } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { TIMES } from 'utils/time';

interface ViewAllessehContentProps {
  content: AllessehContent;
}

interface ViewExternalLinkProps {
  content?: never;
}

type CopyPublishedURLProps = ViewAllessehContentProps | ViewExternalLinkProps;

export const CopyPublishedURL = ({ content }: CopyPublishedURLProps) => {
  const [justCopiedToClipboard, setJustCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (justCopiedToClipboard) {
      setTimeout(() => setJustCopiedToClipboard(false), TIMES.ONE_MINUTE);
    }
  }, [justCopiedToClipboard]);

  if (content) {
    const displayPublishedUrl =
      content.data.attributes.content_status === 'live' &&
      (content.data.type === 'article' || content.data.type === 'SummaryModel');

    const handleCopyPublishedUrl = async () => {
      await navigator.clipboard.writeText(content.data.attributes.source_url);
      setJustCopiedToClipboard(true);
    };

    return displayPublishedUrl ? (
      <Wrapper>
        <Tooltip>
          {justCopiedToClipboard && <Tooltip.Content>Copied to clipboard</Tooltip.Content>}

          <Tooltip.Trigger>
            <Button
              tertiary
              icon={IconCopy as SvgComponent}
              iconPosition="left"
              data-testid="page-content-card-copy-published-url-button"
              onClick={handleCopyPublishedUrl}
            >
              Copy Published URL
            </Button>
          </Tooltip.Trigger>
        </Tooltip>
        <Tooltip />
      </Wrapper>
    ) : null;
  }

  console.warn('`content` props must be provided to <CopyPublishedURL> component');
  return null;
};

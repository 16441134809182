import { Button, Dialog, FormHelperText, FormLabel, Input, Loader } from '@screentone/core';

import styles from '../ITPPageNameChange.module.scss';

interface ChangeNameDialogProps {
  itpPageLabel: string | undefined;
  handleCancel: () => void;
  handleSave: () => void;
  setItpPageLabel: (newItpPageLabel: string) => void;
  open: boolean;
  closeModal: () => void;
  renderNode: () => void;
  isSaveLoading: boolean;
  validateLabel: (potentialLabel: string | undefined) => boolean;
}

export const ChangeNameDialog = ({
  itpPageLabel,
  setItpPageLabel,
  handleSave,
  handleCancel,
  closeModal,
  renderNode,
  isSaveLoading,
  open,
  validateLabel
}: ChangeNameDialogProps) => {
  const labelIsValid = validateLabel(itpPageLabel);
  return (
    <Dialog
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDesc"
      onDismiss={closeModal}
      renderNode={renderNode}
      status={open ? 'open' : 'closed'}
    >
      <Dialog.Title id="dialogTitle">Change ITP Page Display Name</Dialog.Title>
      <Dialog.Content id="dialogDesc">
        <FormLabel label="Display Name" className={styles.formLabel} data-testid="itp-display-name-label">
          <Input
            type="text"
            value={itpPageLabel}
            error={!labelIsValid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setItpPageLabel(e.target.value)}
            data-testid="itp-display-name-input"
          />
        </FormLabel>
        {!labelIsValid && <FormHelperText error>The Display Name must be at least one character long.</FormHelperText>}
      </Dialog.Content>
      <Dialog.Actions>
        <Button secondary onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          primary
          onClick={handleSave}
          disabled={isSaveLoading || !labelIsValid}
          data-testid="itp-change-name-save"
        >
          {isSaveLoading ? <Loader size="md" /> : 'Save'}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

import React from 'react';
import { Button, IconEdit, Tooltip } from '@screentone/core';

import styles from './EditAltSummOption.scss';

interface EditAltSummOptionProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shouldDisplay: boolean | undefined;
  disableAltSummEdit: boolean;
  isLiveCoverage?: boolean;
}

const summarianNotReadyMessage = `There was an issue loading Summarian or you do not have Summarian access.
Please contact the #curation team on Slack.`;
const liveCoverageDisabledMessage = 'Edit Alt-Summ is not available for Live Coverage';

export const EditAltSummOption = ({
  isModalOpen,
  setIsModalOpen,
  shouldDisplay,
  disableAltSummEdit,
  isLiveCoverage
}: EditAltSummOptionProps) => {
  const EditAltSummButton = (
    <Button
      data-testid="edit-alt-summ-option-button"
      disabled={!window.isSummarianReady || isLiveCoverage}
      tertiary
      icon={IconEdit as SvgComponent}
      onClick={() => setIsModalOpen(!isModalOpen)}
    >
      Edit Alt-Summ
    </Button>
  );

  return shouldDisplay && !disableAltSummEdit ? (
    <>
      {window.isSummarianReady && !isLiveCoverage ? (
        EditAltSummButton
      ) : (
        <Tooltip data-testid="edit-alt-summ-option-tooltip">
          <Tooltip.Content
            data-testid="edit-alt-summ-option-tooltip-content"
            id="altSumModalTooltip"
            position="bottom"
            className={styles.altSumTooltip}
            size="lg"
          >
            {isLiveCoverage ? liveCoverageDisabledMessage : summarianNotReadyMessage}
          </Tooltip.Content>
          <Tooltip.Trigger data-testid="edit-alt-summ-option-tooltip-trigger" aria-describedby="altSumModalTooltip">
            {EditAltSummButton}
          </Tooltip.Trigger>
        </Tooltip>
      )}
    </>
  ) : null;
};

import { Box, Button, IconTrash, Typography } from '@screentone/core';

import { ArticleItem, NewsletterDto, PublicationSetting } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { formatInTimeZone } from 'utils/dates';
import { safelyParseContent } from 'utils/temp';

export const NewsletterSettings = ({
  campaign,
  readOnly,
  isScheduled,
  cancelCampaign,
  publicationSetting
}: {
  readOnly: boolean | undefined;
  campaign?: NewsletterDto;
  isScheduled: boolean;
  cancelCampaign?: () => void;
  publicationSetting?: PublicationSetting;
}) => {
  const contentJson = (campaign?.root.collection[0]?.collection[0] as ArticleItem).content;
  const content: AllessehContent = safelyParseContent(contentJson);

  return (
    <>
      {readOnly && campaign && (
        <>
          <Box margin={{ bottom: 'md' }}>
            <Box.Title data-testid="newsletters-setting-title">Distribution Schedule</Box.Title>
            <Box.Content>
              {campaign.metadata.updatedUtc && (
                <Typography variant="header4" margin={{ bottom: 'sm' }}>
                  {formatInTimeZone(
                    campaign.metadata.updatedUtc,
                    publicationSetting?.preferredTimezone,
                    'MMMM dd, yyyy',
                    {
                      showTimezoneAbbreviation: false
                    }
                  )}{' '}
                  at{' '}
                  {formatInTimeZone(campaign.metadata.updatedUtc, publicationSetting?.preferredTimezone, 'h:mm aaaa', {
                    showTimezoneAbbreviation: true
                  })}
                </Typography>
              )}
              {campaign.metadata.publishUtc && (
                <Typography
                  data-testid="newsletters-setting-scheduled-timezone"
                  variant="header4"
                  margin={{ bottom: 'sm' }}
                >
                  {formatInTimeZone(
                    campaign.metadata.publishUtc,
                    publicationSetting?.preferredTimezone,
                    'MMMM dd, yyyy',
                    {
                      showTimezoneAbbreviation: false
                    }
                  )}{' '}
                  at{' '}
                  {formatInTimeZone(campaign.metadata.publishUtc, publicationSetting?.preferredTimezone, 'h:mm aaaa', {
                    showTimezoneAbbreviation: true
                  })}
                </Typography>
              )}
              <Typography data-testid="newsletter-setting-headline">
                {content.data.attributes.headline?.text}
              </Typography>
              {isScheduled && cancelCampaign && (
                <Button
                  tertiary
                  margin={{ top: 'sm' }}
                  icon={IconTrash as SvgComponent}
                  color="lava"
                  onClick={cancelCampaign}
                >
                  Cancel Campaign
                </Button>
              )}
            </Box.Content>
          </Box>
        </>
      )}
    </>
  );
};

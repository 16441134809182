import { ChangeEventHandler } from 'react';
import { Box, Button, Dropdown, Group, IconThreeDotsVer, IconTrash, Select, Typography } from '@screentone/core';

import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { useConvertedProperty } from 'hooks';
import { AllessehContent, AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import { getSentence } from 'utils/text';
import { QueryRuleFormKeyConfig } from './query-rule-configs/types';
import styles from './QueryRuleForm.module.scss';
import {
  getFormComponentByQueryRuleFormType,
  getKeysByQueryRuleArrayNameType,
  getOperatorsByType,
  getQueryRuleConfig
} from './queryRuleFormUtils';
import { QueryRule, QueryRuleArrayNameType, QueryRuleOperatorType } from '../../queryRulesFormUtils';

const CONTENT_TYPE = 'ContentType';

interface QueryRuleProps {
  queryArrayType: QueryRuleArrayNameType;
  rule: QueryRule;
  excludedData?: AllessehContent[] | undefined;
  onChange: ((rule: QueryRule) => void) | null;
  onRemove: (() => void) | null;
  fullAllessehQuery: AllessehContentQueryBody;
}

export const QueryRuleForm = ({
  rule,
  onChange,
  onRemove,
  queryArrayType,
  fullAllessehQuery,
  excludedData
}: QueryRuleProps) => {
  const currentProperty = useConvertedProperty();
  const { trackContent, getAltSummFields } = useSummarianContext();

  // do not display contentType in queryRules
  if (rule.key === CONTENT_TYPE) return null;

  const handleRuleKeyChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const newKey = e.target.value;
    onChange?.({ key: newKey, value: '', operator: QueryRuleOperatorType.EQUAL });
  };

  const handleChangeOperator = (newOperator: QueryRuleOperatorType) => {
    onChange?.({ ...rule, operator: newOperator });
  };

  const handleChangeValue = (newValue: string) => {
    onChange?.({ ...rule, value: newValue });
  };

  const handleRemoveRule = () => {
    onRemove?.();
  };

  const queryRuleConfig = getQueryRuleConfig(currentProperty);
  const ruleMapping = queryRuleConfig[rule.key] as QueryRuleFormKeyConfig | undefined;
  const InputComponent = getFormComponentByQueryRuleFormType(ruleMapping?.queryRuleFormType);

  // If rule mapping is commented out, return null
  // So we don't show the rule form
  if (!InputComponent) {
    return null;
  }

  const id = rule.value;
  const article = excludedData?.find((item: AllessehContent) => item.data.id === id);

  if (article) {
    trackContent(article);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const altSummContent = article ? getAltSummFields(article) : null;
  const showHeadline =
    queryArrayType === QueryRuleArrayNameType.NOT &&
    rule.key === 'UpstreamOriginId' &&
    rule.operator === QueryRuleOperatorType.NOT_EQUAL;

  return (
    <div className={styles.wrapper} data-testid="query-rule-form-container">
      <Select
        data-testid="query-rule-form-type"
        value={rule.key}
        onChange={handleRuleKeyChange}
        className={styles.select}
        disabled={!onChange}
      >
        {getKeysByQueryRuleArrayNameType(queryArrayType, queryRuleConfig).map((key) => (
          <option data-testid="query-rule-form-type-option" key={key} value={key} disabled={key === CONTENT_TYPE}>
            {queryRuleConfig[key].label ?? getSentence(key)}
          </option>
        ))}
      </Select>
      <Box data-testid="query-rule-form-add-rule" padding={{ all: 'sm' }} className={styles.buttonGroup}>
        {getOperatorsByType(queryArrayType, ruleMapping?.queryRuleFormType).map(
          (operator: QueryRuleOperatorType, index) => (
            <Button
              data-testid="query-rule-form-add-rule-button"
              active={operator === rule.operator}
              key={index}
              justified="center"
              fullWidth
              tertiary
              onClick={() => handleChangeOperator(operator)}
              disabled={!onChange}
            >
              {operator}
            </Button>
          )
        )}
      </Box>
      <div data-testid="query-rule-form-type-value" className={styles.inputComponent}>
        <InputComponent
          data-testid="query-rule-form-type-value-input"
          value={rule.value}
          onChange={handleChangeValue}
          ruleConfig={queryRuleConfig[rule.key]}
          allessehKey={rule.key}
          fullAllessehQuery={fullAllessehQuery}
          disabled={!onChange}
        />
        {showHeadline && altSummContent?.headline && (
          <Typography data-testid="query-rule-form-type-title" margin={{ vertical: 'sm' }} variant="note">
            {altSummContent.headline}
          </Typography>
        )}
      </div>
      {onRemove && (
        <Dropdown
          data-testid="query-rule-form-toggle-menu"
          padding={{ all: 'none' }}
          position="none"
          trigger={<IconThreeDotsVer color="asphalt" />}
        >
          <Group data-testid="query-rule-form-toggle-remove" margin={{ all: 'md' }} gap="xs">
            <Button
              data-testid="query-rule-form-toggle-remove-button"
              tertiary
              icon={IconTrash as SvgComponent}
              color="lava"
              onClick={handleRemoveRule}
            >
              Remove
            </Button>
          </Group>
        </Dropdown>
      )}
    </div>
  );
};

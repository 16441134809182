import { format, parse } from 'date-fns';
import { formatInTimeZone as formatInTimeZoneUtil } from 'date-fns-tz';

import { PreferredTimezone } from 'data/generated/graphql';

type FormatEmbargoTimeOptions = {
  showTimezoneAbbreviation?: boolean;
};

const DEFAULT_USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DEFAULT_USER_TIMEZONE_ABBREVIATION = new Intl.DateTimeFormat(undefined, {
  timeZoneName: 'short'
})
  .formatToParts(new Date())
  .find((part) => part.type === 'timeZoneName')!.value;

export const formatInTimeZone = (
  date: string | number | Date,
  preferredTimezone: PreferredTimezone = {
    timezone: DEFAULT_USER_TIMEZONE,
    timezoneAbbreviation: DEFAULT_USER_TIMEZONE_ABBREVIATION
  },
  formatStr: string,
  options: FormatEmbargoTimeOptions = {}
): string => {
  const formattedTime = formatInTimeZoneUtil(date, preferredTimezone.timezone, formatStr);

  if (options.showTimezoneAbbreviation) {
    return `${formattedTime} ${preferredTimezone.timezoneAbbreviation}`;
  }

  return formattedTime;
};

export const determineDateDisplay = (
  embargoDate: string,
  formattedDate: string,
  embargoTime: string,
  formattedTime: string
) => {
  let showTime;
  let showDate;
  let newEmbargoDate;
  let newEmbargoTime;
  if (embargoDate !== formattedDate) {
    showDate = true;
    newEmbargoDate = formattedDate;
    showTime = true;
    newEmbargoTime = formattedTime;
  } else {
    showDate = false;
    if (embargoTime !== formattedTime) {
      showTime = true;
      newEmbargoTime = formattedTime;
    } else {
      showTime = false;
    }
  }
  return {
    showTime,
    showDate,
    embargoDate: newEmbargoDate ?? embargoDate,
    embargoTime: newEmbargoTime ?? embargoTime
  };
};

/**
 * Transform an ITP issue date in `YYYYMMDD` format to a JavaScript Date object.
 * @param issueDate The date of the ITP issue in YYYYMMDD format.
 */
export const itpIssueDateToJSDate = (issueDate: string): Date => parse(issueDate, 'yyyyMMdd', new Date());

/**
 * Transform a JavaScript date into an ITP issue date string in `YYYYMMDD` format.
 * @param date A JavaScript Date object.
 */
export const jsDateToITPIssueDate = (date: Date): string => format(date, 'yyyyMMdd');

export const formatLocalDate = (utcDate: string): string => {
  const date = new Date(utcDate);

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12; // The hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');

  return `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;
};

/* eslint-disable import/no-cycle */
import { ReactElement } from 'react';
import { Button, Divider, Dropdown, IconPlusCircle, List, Tooltip, useDropdownState } from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { AvailableLayoutModule, ContentUnion, ModuleContainer, PageModule, QueryItem } from 'data/generated/graphql';
import styles from './AddModuleButton.module.scss';

interface AddModuleButtonProps {
  addableUiModules?: AvailableLayoutModule[];
  nextModuleIndex: number;
  availableTreatmentTypes?: string[];
}

export const AddModuleButton = ({
  addableUiModules,
  nextModuleIndex,
  availableTreatmentTypes
}: AddModuleButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isAddModuleDropdownOpen,
    setOpen: setIsAddModuleDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();
  const { insertEntity } = useDataModelContext();

  const { pageTypeSetting } = usePageTypeSettingsContext();
  const treatmentTypeSettings = pageTypeSetting?.treatmentTypeSettings;

  const availableModulesAndLabels = availableTreatmentTypes?.map((treatmentType) =>
    treatmentTypeSettings?.find((t) => t.treatmentType === treatmentType || t.treatmentTypeKey === treatmentType)
  );

  const addModuleOptions = availableModulesAndLabels?.length
    ? availableModulesAndLabels.map((t) => t?.label)
    : addableUiModules?.map((module) => module.uiModuleType) ?? [];

  const handleAddModule = (index: number, option?: string) => {
    if (!addableUiModules || (addableUiModules.length === 0 && !availableTreatmentTypes?.length)) {
      return;
    }

    const defaultModule = option
      ? (availableModulesAndLabels?.find((t) => t?.label === option)?.module as PageModule)
      : clonedeep(addableUiModules[index].defaultModule);

    const collection = defaultModule.moduleItems
      .map((moduleItem) => {
        if (moduleItem.itemFields.queryItem) {
          const queryItem: QueryItem = {
            type: 'Query',
            attributes: {
              isFeed: moduleItem.itemFields.queryItem.isFeed,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              query: JSON.parse(moduleItem.itemFields.queryItem.jsonQuery),
              feedTitle: moduleItem.itemFields.queryItem.title,
              feedUrl: moduleItem.itemFields.queryItem.url
            }
          };
          return queryItem;
        }
        return null;
      })
      .filter((item) => item !== null) as ContentUnion[];

    const newModule: ModuleContainer = {
      type: 'Module',
      attributes: { pageModule: defaultModule },
      collection
    };

    insertEntity(`0-0-${nextModuleIndex}`, newModule);

    setIsAddModuleDropdownOpen(false);
  };

  const handleAddModuleDropdownOpenToggle = () => {
    setIsAddModuleDropdownOpen(!isAddModuleDropdownOpen);
  };

  const blockDividerAction = () => {
    setIsAddModuleDropdownOpen(!isAddModuleDropdownOpen);
  };

  if (!addableUiModules || (addableUiModules.length === 0 && !availableTreatmentTypes?.length)) {
    return null;
  }

  return (
    <div className={styles.blockDivider}>
      <div className={styles.blockDividerContent}>
        <Divider className={styles.blockDividerDivider} />
        <Dropdown
          arrow="top"
          position="center"
          margin={{ horizontal: 'xs' }}
          componentRef={dropdownRef}
          open={isAddModuleDropdownOpen}
          onToggle={handleAddModuleDropdownOpenToggle}
        >
          <Dropdown.Trigger>
            <Tooltip>
              <Tooltip.Content position={nextModuleIndex === 0 ? 'bottom' : 'top'}>Add a Module Here</Tooltip.Content>
              <Tooltip.Trigger>
                <Button
                  tertiary
                  icon={IconPlusCircle as SvgComponent}
                  onClick={blockDividerAction}
                  data-testid="add-module-button"
                />
              </Tooltip.Trigger>
            </Tooltip>
          </Dropdown.Trigger>
          <Dropdown.Content
            className={styles.blockDividerDropdownContent}
            arrow="top"
            margin={{ top: 'sm' }}
            padding={{ all: 'md' }}
          >
            <List itemMargin={{ vertical: 'xs' }}>
              {addModuleOptions.map((option, index) => (
                <List.Item key={option}>
                  <Button
                    fullWidth
                    tertiary
                    className={styles.addableUiModuleOption}
                    onClick={() => handleAddModule(index, option)}
                    data-testid="page-add-module-title-button"
                  >
                    {option}
                  </Button>
                </List.Item>
              ))}
            </List>
          </Dropdown.Content>
        </Dropdown>
        <Divider className={styles.blockDividerDivider} />
      </div>
    </div>
  );
};

import { Button, IconMinusCircle, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';

interface RemoveEntityToCollectionProps {
  hierarchyId: string;
  text: string;
}

const RemoveEntityFromCollection = ({ hierarchyId, text }: RemoveEntityToCollectionProps) => {
  const { removeEntityFromCollection } = useDataModelContext();

  return (
    <Wrapper>
      <Button
        tertiary
        margin={{ top: 'sm' }}
        icon={IconMinusCircle as SvgComponent}
        color="lava"
        data-testid="page-content-card-remove-button"
        onClick={() => removeEntityFromCollection(hierarchyId)}
      >
        {text}
      </Button>
    </Wrapper>
  );
};

export default RemoveEntityFromCollection;

import { ChangeEventHandler } from 'react';
import clonedeep from 'lodash.clonedeep';

import {
  ContentUnion,
  PageModule,
  QueryItem,
  TreatmentTypeSetting,
  UiBasicOptionalTreatmentType,
  UiBasicTreatmentType,
  UiFeedsTreatmentType,
  UiModule,
  UiModuleType,
  UiRankedTreatmentType,
  UiTextTreatmentType,
  UiTitleTreatmentType,
  UiTwoColumnTreatmentType
} from 'data/generated/graphql';

function useModuleTreatmentTypes({
  moduleFieldKey,
  onChange,
  pageModule,
  subsetTreatmentTypeSettings
}: {
  moduleFieldKey: Exclude<keyof UiModule, '__typename'>;
  onChange?: ((newPageModule: PageModule, collection?: ContentUnion[]) => void) | null;
  pageModule: PageModule;
  subsetTreatmentTypeSettings?: TreatmentTypeSetting[];
}) {
  const handleChangeTreatmentByValue = (value: string) => {
    const newPageModule = clonedeep(pageModule);
    if (newPageModule.uiModuleFields[moduleFieldKey]) {
      const currentTreatmentType = newPageModule.uiModuleFields[moduleFieldKey]!.treatmentType;
      newPageModule.uiModuleFields[moduleFieldKey]!.treatmentType = value as typeof currentTreatmentType;

      // prefill with feed queries
      if (moduleFieldKey === 'feedsModule') {
        const newTreatmentTypeSettings = subsetTreatmentTypeSettings?.find(
          (setting) => setting.treatmentTypeKey === value || setting.treatmentType === value
        );

        if (
          newTreatmentTypeSettings &&
          pageModule.moduleItems.length < newTreatmentTypeSettings.minRequiredContentItems
        ) {
          const moduleItem = clonedeep(pageModule.moduleItems[0]);
          for (
            let i = pageModule.moduleItems.length, j = newTreatmentTypeSettings.minRequiredContentItems;
            i < j;
            i++
          ) {
            newPageModule.moduleItems.push(moduleItem);
          }
        } else {
          newPageModule.moduleItems = newPageModule.moduleItems.slice(
            0,
            newTreatmentTypeSettings?.minRequiredContentItems
          );
        }
      }
    }

    const collection: ContentUnion[] = newPageModule.moduleItems
      .map((moduleItem) => {
        if (moduleItem.itemFields.queryItem) {
          const queryItem: QueryItem = {
            type: 'Query',
            attributes: {
              isFeed: moduleItem.itemFields.queryItem.isFeed,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              query: JSON.parse(moduleItem.itemFields.queryItem.jsonQuery),
              feedTitle: moduleItem.itemFields.queryItem.title,
              feedUrl: moduleItem.itemFields.queryItem.url
            }
          };
          return queryItem;
        }
        return null;
      })
      .filter((item) => item !== null) as ContentUnion[];

    onChange?.(newPageModule, collection.length ? collection : undefined);
  };

  const handleChangeTreatment: ChangeEventHandler<HTMLInputElement> = (e) => {
    handleChangeTreatmentByValue(e.target.value);
  };
  const allowedTreatmentTypes =
    pageModule.uiModuleFields[moduleFieldKey]?.allowedTreatmentTypes ??
    ([pageModule.uiModuleFields[moduleFieldKey]?.treatmentType] as string[]);

  const allowedTreatmentTypeSettings = subsetTreatmentTypeSettings?.filter(
    (treatmentTypeSetting) =>
      allowedTreatmentTypes.includes(treatmentTypeSetting.treatmentType) ||
      allowedTreatmentTypes.includes(treatmentTypeSetting.treatmentTypeKey ?? '')
  );

  return {
    handleChangeTreatment,
    handleChangeTreatmentByValue,
    allowedTreatmentTypes,
    allowedTreatmentTypeSettings
  };
}

function getModuleFieldKey(uiModuleType: UiModuleType) {
  switch (uiModuleType) {
    case UiModuleType.UiBasicModuleType:
      return 'basicModule';
    case UiModuleType.UiRankedModuleType:
      return 'rankedModule';
    case UiModuleType.UiTitleModuleType:
      return 'titleModule';
    case UiModuleType.UiTextModuleType:
      return 'textModule';
    case UiModuleType.UiBasicOptionalModuleType:
      return 'basicOptionalModule';
    case UiModuleType.UiTwoColumnModuleType:
      return 'twoColumnModule';
    case UiModuleType.UiFeedsModuleType:
      return 'feedsModule';
    default:
      return 'twoColumnModule';
  }
}

function getSubsetTreatmentTypeSettings(
  uiModuleType: UiModuleType,
  treatmentTypeSettings: TreatmentTypeSetting[] = []
) {
  switch (uiModuleType) {
    case UiModuleType.UiBasicModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiBasicTreatmentType).includes(s.treatmentTypeKey as UiBasicTreatmentType) ||
          Object.values(UiBasicTreatmentType).includes(s.treatmentType as UiBasicTreatmentType)
      );
    case UiModuleType.UiRankedModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiRankedTreatmentType).includes(s.treatmentTypeKey as UiRankedTreatmentType) ||
          Object.values(UiRankedTreatmentType).includes(s.treatmentType as UiRankedTreatmentType)
      );
    case UiModuleType.UiTitleModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiTitleTreatmentType).includes(s.treatmentTypeKey as UiTitleTreatmentType) ||
          Object.values(UiTitleTreatmentType).includes(s.treatmentType as UiTitleTreatmentType)
      );
    case UiModuleType.UiTextModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiTextTreatmentType).includes(s.treatmentTypeKey as UiTextTreatmentType) ||
          Object.values(UiTextTreatmentType).includes(s.treatmentType as UiTextTreatmentType)
      );
    case UiModuleType.UiBasicOptionalModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiBasicOptionalTreatmentType).includes(s.treatmentTypeKey as UiBasicOptionalTreatmentType) ||
          Object.values(UiBasicOptionalTreatmentType).includes(s.treatmentType as UiBasicOptionalTreatmentType)
      );
    case UiModuleType.UiTwoColumnModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiTwoColumnTreatmentType).includes(s.treatmentTypeKey as UiTwoColumnTreatmentType) ||
          Object.values(UiTwoColumnTreatmentType).includes(s.treatmentType as UiTwoColumnTreatmentType)
      );
    case UiModuleType.UiFeedsModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiFeedsTreatmentType).includes(s.treatmentTypeKey as UiFeedsTreatmentType) ||
          Object.values(UiFeedsTreatmentType).includes(s.treatmentType as UiFeedsTreatmentType)
      );
    default:
      return treatmentTypeSettings;
  }
}

const getTreatmentType = (pageModule: PageModule): string => {
  let treatmentType = null;
  const keys = Object.keys(pageModule.uiModuleFields);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const uiModuleFieldKey = key as keyof UiModule;
    const uiModule = pageModule.uiModuleFields[uiModuleFieldKey] as { treatmentType: string } | null;
    if (uiModule) {
      treatmentType = uiModule.treatmentType;
    }
  }
  return treatmentType as string;
};

export { useModuleTreatmentTypes, getSubsetTreatmentTypeSettings, getModuleFieldKey, getTreatmentType };

/*
Newsletter          Default send time (ET)
Weekend Reads       Fridays, noon
Mutual Funds        Thursdays, 9 am
ETF Wrap            Thursdays, 5 pm
Need to Know        Daily, 7:30 am
Distributed Ledger  Daily, 4 pm ET

From here: https://dowjones.atlassian.net/browse/ET-7315
*/
import { PublicationSetting } from 'data/generated/graphql';
import { TIMES } from './time';

export const getTimeZoneOffsetByName = (timeZone: string | undefined) => {
  if (!timeZone) return null;
  const str = new Date().toLocaleString('en', { timeZone, timeZoneName: 'longOffset' });
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [_, h, m] = str.match(/([+-]\d+):(\d+)$/) ?? ['', '+00', '00'];
  return (Number(h) * 60 + (Number(h) > 0 ? +m : -m)) * -1;
};

export const adjustToUtcTime = (date: number, publicationSetting?: PublicationSetting) => {
  let adjustedPublishUtc = date;
  const publishDateObj = new Date(date);
  const desiredTZOffset = getTimeZoneOffsetByName(publicationSetting?.preferredTimezone.timezone);

  if (desiredTZOffset) {
    const currentTZOffset = publishDateObj.getTimezoneOffset();
    const offsetDifference =
      currentTZOffset < 0 ? (currentTZOffset - desiredTZOffset) * -1 : desiredTZOffset - currentTZOffset;
    adjustedPublishUtc = date + offsetDifference * TIMES.ONE_MINUTE;
  }

  return adjustedPublishUtc;
};

export const getNextQuarterHour = (publicationSetting?: PublicationSetting) => {
  const now = new Date();

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: publicationSetting?.preferredTimezone.timezone,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  });

  const parts = formatter.formatToParts(now);
  const minutes = Number(parts.find((part) => part.type === 'minute')?.value) + 1;

  const nextQuarter = Math.ceil(minutes / 15) * 15;

  const zonedDate = new Date(now.toLocaleString('en-US', { timeZone: publicationSetting?.preferredTimezone.timezone }));
  zonedDate.setSeconds(0);

  if (nextQuarter === 60) {
    zonedDate.setHours(zonedDate.getHours() + 1);
    zonedDate.setMinutes(0);
  } else {
    zonedDate.setMinutes(nextQuarter);
  }

  return zonedDate;
};

interface NewsletterFilters {
  [currentProperty: string]: {
    [newsletterName: string]: {
      sectionType: string;
    };
  };
}

export const NEWSLETTER_FILTERS: NewsletterFilters = {
  marketwatch: {
    'Distributed Ledger': {
      sectionType: 'Distributed Ledger'
    },
    'Mutual Funds Weekly': {
      sectionType: 'Mutual Funds Weekly'
    },
    'ETF Wrap': {
      sectionType: 'ETF Wrap'
    },
    'Need to Know': {
      sectionType: 'Need to Know'
    }
  }
};

/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Group,
  IconArrowLeft,
  IconEdit,
  IconEye,
  // IconLink,
  Loader,
  Token,
  Tooltip,
  Typography,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useAuraQuery } from 'hooks/useAuraQuery';
import { TIMES } from 'utils/time';
import styles from './HistoryItem.module.scss';
import { HistoryContent } from './PublishHistory';

interface HistoryItemProps<T extends HistoryContent> {
  selectedVersion: T;
  isLive: boolean;
  isPast: boolean;
  isEditing: boolean;
  handleShowHistoryEditMode: (historyItem: HistoryContent) => void;
  scrollableSectionClassName?: string;
  handlePreviewClick?: (selectedVersion: T) => void;
  isPreviewLoading?: boolean;
  // showURL: boolean;
}

export const HistoryItem = <T extends HistoryContent>({
  selectedVersion,
  isLive,
  isPast,
  isEditing,
  handleShowHistoryEditMode,
  scrollableSectionClassName,
  handlePreviewClick,
  isPreviewLoading
}: // showURL
HistoryItemProps<T>) => {
  const { setNewRoot } = useDataModelContext();
  const { renderEntity } = useDataModelContext();
  const { data: revisorUserData, isLoading: isFetchingUserData } = useAuraQuery(
    selectedVersion.metadata.revisorUser.djUserId
  );
  const revisorUserText = revisorUserData
    ? `${revisorUserData.first_name ?? ''} ${revisorUserData.last_name ?? ''}`
    : selectedVersion.metadata.revisorUser.djUserId;
  const { publishUtc } = selectedVersion.metadata;

  const now = new Date().getTime();
  const headerText = isLive ? 'Published' : isPast ? 'Previous' : 'Scheduled';
  const publishDateText = publishUtc ? format(publishUtc, 'MMM d, yyyy h:mm aaa z') : '';
  const [justCopiedToClipboard, setJustCopiedToClipboard] = useState(false);
  // const [versionURL, setVersionURL] = useState('');

  useEffect(() => {
    if (justCopiedToClipboard) {
      setTimeout(() => setJustCopiedToClipboard(false), TIMES.ONE_SECOND);
    }
  }, [justCopiedToClipboard]);

  const handleCopyToNew = () => {
    setNewRoot(selectedVersion.root);
  };

  // const copyVersionURLToClipboard = async () => {
  //   const {
  //     location: { origin, pathname }
  //   } = window;
  //   // Take the path of the current URL up to the revision group key or slug.
  //   const path = pathname.slice(1).split('/').slice(0, 3).join('/');

  //   const url = `${origin}/${path}/version/${publishUtc!}`;
  //   await navigator.clipboard.writeText(url);
  //   setVersionURL(url);
  //   setJustCopiedToClipboard(true);
  // };

  const handleShowEditMode = () => handleShowHistoryEditMode(selectedVersion);

  const isEditingVersion = isEditing && !isLive;

  const historySection = renderEntity(selectedVersion.root, { hierarchyId: '', isHistory: true });

  return (
    <Box>
      <Wrapper padding={{ all: 'md' }}>
        <Wrapper className={styles.publishState}>
          <Typography variant="header3" margin={{ right: 'sm' }} data-testid="page-published-title">
            {headerText}
          </Typography>
          {isLive && (
            <Token color="emerald" margin={{ right: 'md' }} data-testid="page-published-live-label">
              Live
            </Token>
          )}
          <Typography variant="note" data-testid="page-published-date-label">
            {publishDateText}
          </Typography>
        </Wrapper>
        <Wrapper className={styles.actions}>
          <Tooltip>
            {justCopiedToClipboard && (
              <Tooltip.Content data-testid="page-copy-clipboard-alert">Copied to clipboard</Tooltip.Content>
            )}
            {/* {showURL && (
              <Tooltip.Trigger>
                <Button
                  tertiary
                  disabled={isEditingVersion}
                  margin={{ right: 'md' }}
                  icon={IconLink as SvgComponent}
                  onClick={copyVersionURLToClipboard}
                  data-testid="page-published-url-button"
                  data-version-url={versionURL}
                >
                  URL
                </Button>
              </Tooltip.Trigger>
            )} */}
          </Tooltip>

          <Tooltip>
            <Tooltip.Trigger>
              <Button
                tertiary
                disabled={isEditingVersion}
                onClick={handleCopyToNew}
                margin={{ right: 'sm' }}
                icon={IconArrowLeft as SvgComponent}
                data-testid="page-published-copy-new-button"
              >
                Replace draft
              </Button>
              {handlePreviewClick && (
                <Button
                  tertiary
                  margin={{ right: 'md' }}
                  icon={IconEye as SvgComponent}
                  onClick={() => handlePreviewClick(selectedVersion)}
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  disabled={isPreviewLoading || isEditingVersion}
                  data-testid="page-published-preview-button"
                >
                  Preview
                </Button>
              )}
            </Tooltip.Trigger>
          </Tooltip>

          {/* only show if we're not in edit mode and if the page will publish >= 1 minute from now */}
          {!isEditingVersion &&
            selectedVersion.metadata.publishUtc &&
            selectedVersion.metadata.publishUtc > now + TIMES.ONE_MINUTE && (
              <Button
                tertiary
                onClick={handleShowEditMode}
                icon={IconEdit as SvgComponent}
                data-testid="page-scheduled-edit-button"
              >
                Edit
              </Button>
            )}
        </Wrapper>
      </Wrapper>
      <Divider />
      <Wrapper
        className={classnames(scrollableSectionClassName, { [styles.historyScrollable]: isEditingVersion })}
        data-testid="page-published-content"
      >
        {historySection}
        {isEditingVersion && (
          <Group
            align="center"
            valign="center"
            gap="sm"
            fullWidth
            className={styles.editNotice}
            data-testid="page-editing-status-label"
          >
            <IconArrowLeft />
            <Typography>In edit</Typography>
          </Group>
        )}
      </Wrapper>
      <Divider />
      <Wrapper padding={{ all: 'md' }}>
        <Typography variant="note" className={styles.lastEditedByText} data-testid="page-last-editor-label">
          Last edited by: {isFetchingUserData ? <Loader size="md" /> : revisorUserText}
        </Typography>
      </Wrapper>
    </Box>
  );
};

/* eslint-disable import/no-cycle */
import { Droppable } from 'react-beautiful-dnd';
import { Typography, Wrapper } from '@screentone/core';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { ArticleItem, NewsletterPageContainer, UiModuleType } from 'data/generated/graphql';
import { getUiModuleTypeLabel } from 'features/page-edit/pageEditUtils';
import styles from './Newsletter.module.scss';

interface NewsletterProps extends CommonEntityProps {
  data: NewsletterPageContainer;
  withoutDragNDrop?: boolean;
}

const Newsletter = ({ data, parentHierarchyId, index, withoutDragNDrop }: NewsletterProps) => {
  const { generateHierarchyId, renderEntity } = useDataModelContext();
  const { renderActions } = useContextMenuActions();

  const hierarchyId = generateHierarchyId(data, parentHierarchyId, index);

  const renderItems = () =>
    data.collection.length > 0 &&
    data.collection[0].collection.map((collection, mapIndex) => {
      const articleItem = collection as ArticleItem;
      const articleHierarchyId = generateHierarchyId(articleItem, hierarchyId, mapIndex);

      return (
        <div key={mapIndex}>
          {renderEntity(articleItem, {
            hierarchyId,
            index: mapIndex,
            withoutDragNDrop,
            extraProps: {
              article: {
                renderActions: () =>
                  renderActions(MenuActionsKeys.DraftArticle, {
                    hierarchyId: articleHierarchyId,
                    index: mapIndex,
                    entity: articleItem
                  })
              }
            }
          })}
        </div>
      );
    });

  return (
    <div data-model-hierarchy-id={hierarchyId}>
      <Wrapper className={styles.moduleList} margin={{ bottom: 'sm' }}>
        <div className={styles.draftScrollable}>
          {!withoutDragNDrop ? (
            <Droppable droppableId={DROPPABLE_ID_PREFIXES.NEWSLETTER + hierarchyId}>
              {(droppableProvided) => (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  className={styles.droppableContainer}
                >
                  <div className={styles.moduleItemInfo}>
                    <Wrapper className={styles.moduleHeader} margin={{ bottom: 'xs' }}>
                      <div className={styles.moduleHeaderLeft}>
                        <Typography size="sm" weight="bold" margin={{ right: 'sm' }}>
                          {getUiModuleTypeLabel(data.collection[0].attributes.pageModule?.uiModuleType as UiModuleType)}
                        </Typography>
                      </div>
                    </Wrapper>
                    <div className={styles.moduleItemBox}>{renderItems()}</div>
                    {droppableProvided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ) : (
            <div>{renderItems()}</div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

export default Newsletter;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FabButton,
  IconPlus,
  Tabs as TabsComponent,
  Typography,
  useAnimatedModalState,
  useModalPortal,
  Wrapper
} from '@screentone/core';
import { cloneDeep } from 'lodash';

import { useAlert } from 'contexts/alert/useAlert';
import {
  AllessehContentType,
  GetScheduledContentByPublicationKeyQuery,
  NewsletterDto,
  NewslettersInput,
  PublicationSetting,
  ScheduledContentStatus,
  useInfiniteGetNewslettersQuery,
  useInfiniteGetScheduledContentByPublicationKeyQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { ComponentRef } from 'utils/componentRef';
import { NewsletterSearchBar } from './components/newsletter-search-bar/NewsletterSearchBar';
import { NewsletterSelectDialog } from './components/newsletter-select-dialog/NewsletterSelectDialog';
import { NewslettersList } from './components/newsletters-list/NewslettersList';

const PER_PAGE = 10;

enum Tabs {
  Sent,
  Scheduled
}

const Newsletters = ({ publicationSetting }: { publicationSetting: PublicationSetting | undefined }) => {
  const [tabIndex, setTabIndex] = useState(Tabs.Sent);
  const [newslettersInput, setNewslettersInput] = useState<NewslettersInput>({ publicationKey: '' });
  const [selectedNewsletterId, setSelectedNewsletterId] = useState<string>('');

  const navigate = useNavigate();
  const currentProperty = useConvertedProperty();
  const { alertWarning } = useAlert();

  const {
    data: newslettersSent,
    fetchNextPage: fetchNextPageSent,
    hasNextPage: hasNextPageSent,
    isFetchingNextPage: isFetchingNextPageSent
  } = useInfiniteGetNewslettersQuery(
    {
      newslettersSearchInput: newslettersInput,
      paginationParams: { first: PER_PAGE, after: null }
    },
    {
      enabled: !!newslettersInput.publicationKey && newslettersInput.publicationKey.length > 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.getNewsletters.pageInfo.hasNextPage) {
          return false;
        }

        return {
          paginationParams: {
            first: PER_PAGE,
            after: lastPage.getNewsletters.pageInfo.endCursor
          }
        };
      }
    }
  );

  const {
    data: newslettersScheduled,
    fetchNextPage: fetchNextPageScheduled,
    hasNextPage: hasNextPageScheduled,
    isFetchingNextPage: isFetchingNextPageScheduled,
    isLoading: isLoadingScheduled
  } = useInfiniteGetScheduledContentByPublicationKeyQuery(
    {
      publicationKey: currentProperty ?? '',
      contentType: AllessehContentType.Newsletter,
      paginationParams: { first: PER_PAGE, after: null },
      status: ScheduledContentStatus.Scheduled
    },
    {
      enabled: !!newslettersInput.publicationKey && newslettersInput.publicationKey.length > 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.scheduledContentByPublicationKey.pageInfo.hasNextPage) {
          return false;
        }

        return {
          paginationParams: {
            first: PER_PAGE,
            after: lastPage.scheduledContentByPublicationKey.pageInfo.endCursor
          }
        };
      }
    }
  );

  const [filteredScheduledNewsletters, setFilteredScheduledNewsletters] = useState({ ...newslettersScheduled });

  useEffect(() => {
    if (newslettersInput.searchText && newslettersScheduled) {
      const filteredData = cloneDeep(newslettersScheduled);

      filteredData.pages = newslettersScheduled.pages.map<GetScheduledContentByPublicationKeyQuery>((pageData) => {
        const edges = pageData.scheduledContentByPublicationKey.edges.filter((edgeData) =>
          (edgeData.node.body as NewsletterDto).metadata.name
            .toLowerCase()
            .includes(newslettersInput.searchText?.toLowerCase() as string)
        );

        return {
          ...pageData,
          ...{
            scheduledContentByPublicationKey: {
              edges
            }
          }
        } as GetScheduledContentByPublicationKeyQuery;
      });

      setFilteredScheduledNewsletters(filteredData);
    } else {
      setFilteredScheduledNewsletters({ ...newslettersScheduled });
    }
  }, [newslettersInput.searchText, newslettersScheduled, isLoadingScheduled]);

  useEffect(() => {
    if (!currentProperty) {
      return;
    }

    setNewslettersInput((newslettersInput) => ({ ...newslettersInput, publicationKey: currentProperty }));
  }, [currentProperty]);

  const handlePartialNewslettersInputChange = (newSearchParams: Partial<NewslettersInput>) => {
    setNewslettersInput((newslettersInput) => ({ ...newslettersInput, ...newSearchParams }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: openUnsafe,
    status: statusUnsafe,
    componentRef: componentRefUnsafe,
    openModal: openModalUnsafe,
    closeModal: closeModalUnsafe
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  } = useAnimatedModalState();
  const open = openUnsafe as boolean;
  const status = statusUnsafe as string;
  const componentRef = componentRefUnsafe as ComponentRef;
  const openModal = openModalUnsafe as () => void;
  const closeModal = closeModalUnsafe as () => void;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode: renderNodeUnsafe } = useModalPortal();
  const renderNode = renderNodeUnsafe as () => void;

  const handleCancelClick = () => {
    setSelectedNewsletterId('');
    closeModal();
  };

  const handleBeginCampaignClick = () => {
    if (!selectedNewsletterId) {
      alertWarning('Please select a newsletter to begin campaign', { autoDismiss: true });
      return;
    }
    navigate(`/${currentProperty}/newsletters/new?newsletter=${selectedNewsletterId}`);
  };

  const nextPageProps = {
    fetchNextPage: fetchNextPageSent,
    hasNextPage: hasNextPageSent,
    isFetchingNextPage: isFetchingNextPageSent
  };

  const nextPagePropsScheduled = {
    fetchNextPage: fetchNextPageScheduled,
    hasNextPage: hasNextPageScheduled,
    isFetchingNextPage: isFetchingNextPageScheduled
  };

  return (
    <Wrapper margin={{ top: 'lg' }}>
      <Typography variant="header2">Campaigns</Typography>

      <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.Sent ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
        >
          Sent
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === Tabs.Scheduled ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          data-testid="newsletters-scheduled-tab"
        >
          Scheduled
        </TabsComponent.Item>
      </TabsComponent>

      <NewsletterSearchBar
        newslettersInput={newslettersInput}
        onPartialNewslettersInputChange={handlePartialNewslettersInputChange}
      />

      {tabIndex === Tabs.Sent && newslettersSent?.pages && (
        <NewslettersList
          newsletters={newslettersSent.pages}
          publicationSetting={publicationSetting}
          nextPageProps={nextPageProps}
        />
      )}

      {tabIndex === Tabs.Scheduled && filteredScheduledNewsletters.pages && (
        <NewslettersList
          scheduledCampaigns={filteredScheduledNewsletters.pages}
          publicationSetting={publicationSetting}
          nextPageProps={nextPagePropsScheduled}
        />
      )}

      <FabButton icon={IconPlus as SVGAElement} onClick={openModal} data-testid="newsletter-add-new" />
      <NewsletterSelectDialog
        open={open}
        handleBeginCampaignClick={handleBeginCampaignClick}
        handleCancelClick={handleCancelClick}
        selectedNewsletterId={selectedNewsletterId}
        componentRef={componentRef}
        setSelectedNewsletterId={setSelectedNewsletterId}
        renderNode={renderNode}
        status={status}
        closeModal={closeModal}
      />
    </Wrapper>
  );
};

export default Newsletters;

import { Typography, Wrapper } from '@screentone/core';

import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import styles from './ModuleItemCount.module.scss';

const getContentTypeForDisplay = (contentTypes?: PublicationSettingSearchableContentType[]) => {
  if (contentTypes?.length === 1) {
    switch (contentTypes[0]) {
      case PublicationSettingSearchableContentType.CommerceProduct:
        return 'products';
      default:
        return `${contentTypes[0].toLowerCase()}s`;
    }
  }
  return 'items';
};

interface ModuleItemCountProps {
  isNewsletter?: boolean;
  count: number;
  min?: number;
  max?: number;
  showMaxInDenominator?: boolean;
  contentTypes?: PublicationSettingSearchableContentType[];
  considerZeroMin?: boolean;
}
export const ModuleItemCount = ({
  isNewsletter,
  count,
  min,
  max,
  showMaxInDenominator,
  contentTypes,
  considerZeroMin
}: ModuleItemCountProps) => {
  let denominator;
  if (showMaxInDenominator) {
    denominator = max;
  } else if (min && !max) {
    denominator = count < min ? min : null;
  } else if (max && !min) {
    denominator = count > max ? max : null;
  } else if (max && min) {
    denominator = count < min ? min : count > max ? max : null;
  }

  if (!denominator) {
    return null;
  }

  const contentType = getContentTypeForDisplay(contentTypes);

  if (isNewsletter) {
    return (
      <>
        {count > 0 ? (
          <></>
        ) : (
          <div className={styles.numItemsMessage}>
            <Wrapper padding={{ all: 'sm' }}>
              <Typography variant="note">Drop article here</Typography>
            </Wrapper>
          </div>
        )}
      </>
    );
  }

  let total;
  if (min === denominator) {
    total = denominator;
  } else if (!min) {
    if (considerZeroMin) {
      total = `${min}-${denominator}`;
    } else {
      total = denominator;
    }
  } else {
    total = `${min}-${denominator}`;
  }

  if (total === count || count >= (max ?? count)) {
    return null;
  }

  return (
    <div className={styles.numItemsMessage} data-testid="page-item-count">
      <Wrapper padding={{ all: 'sm' }} data-testid="page-item-count-msg">
        <Typography variant="note">
          {count}/{total} {contentType}
        </Typography>
      </Wrapper>
    </div>
  );
};

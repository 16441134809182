import { createContext, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns-tz';

import { PageError, PageValidationError } from 'contexts/alert/AlertContext';
import { formatAlertMessage, useAlert } from 'contexts/alert/useAlert';
import { DEFAULT_ALT_SUM_ID } from 'contexts/summarian/SummarianContext';
import {
  Page,
  PageDeleteInput,
  PageType,
  PageTypeSetting,
  PageUpdateInput,
  usePageByRevisionGroupKeyQuery,
  usePageDeleteMutation,
  usePageTypeSettingByPublicationKeyPageTypeQuery,
  usePageUpdateMutation
} from 'data/generated/graphql';
import { AllContentIds, findDuplicates } from 'features/page-edit/components/page-draft-section/PageDraftSectionUtils';
import { prepPageModulesForPublish } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { clearValidationError, mergeModulesWithValidationError } from 'features/page-edit/pageEditUtils';
import { useConvertedProperty } from 'hooks';
import noop, { asyncNoop } from 'utils/noop';

interface PagePublishContext {
  page: Page | null;
  fetchError: number | null;
  hasPageChanged: boolean;
  hasRecentlyPublished: boolean | null;
  isEditingLinkedItem: boolean | string;
  isPublishing: boolean;
  isDeleting: boolean;
  originalPageWhenInHistoryEditMode: Page | null;
  allContentIds: {};
  duplicateIds: string[];
  handlePartialPageChange: (newPartialPage: Partial<Page>) => void;
  handlePublish: (publishUtc?: number) => Promise<void>;
  handleShowHistoryEditMode: (historyPage: Page) => void;
  handleMobilePreviewPage: () => void;
  handleHideHistoryEditMode: () => void;
  setAllContentIds: React.Dispatch<(state: AllContentIds) => AllContentIds>;
  setIsEditingLinkedItem: (flag: boolean | string) => void;
  deleteScheduledPublish: (pageDeleteInput: PageDeleteInput) => Promise<void>;
  clearValidationErrorsInModules: () => void;
  hasPageBeenPublishedOnce: boolean;
  pageTypeSetting: PageTypeSetting | undefined;
}

const DEFAULT_PAGE_PUBLISH_STATE: PagePublishContext = {
  page: null,
  fetchError: null,
  hasPageChanged: false,
  hasRecentlyPublished: null,
  isEditingLinkedItem: false,
  isPublishing: false,
  isDeleting: false,
  originalPageWhenInHistoryEditMode: null,
  allContentIds: {},
  duplicateIds: [],
  handlePartialPageChange: noop,
  handleMobilePreviewPage: noop,
  handlePublish: asyncNoop,
  handleShowHistoryEditMode: noop,
  handleHideHistoryEditMode: noop,
  setAllContentIds: noop,
  setIsEditingLinkedItem: noop,
  deleteScheduledPublish: asyncNoop,
  clearValidationErrorsInModules: noop,
  hasPageBeenPublishedOnce: false,
  pageTypeSetting: {} as PageTypeSetting
};

export const DEFAULT_PAGE_CONFIG = {
  summaryPriority: DEFAULT_ALT_SUM_ID
};

export const PagePublishContext = createContext(DEFAULT_PAGE_PUBLISH_STATE);

interface PagePublishProviderProps {
  pageRevisionGroupKey?: string;
  children: React.ReactNode;
}

export const PagePublishProvider = ({ pageRevisionGroupKey, children }: PagePublishProviderProps) => {
  const { alertError, alertSuccess, removeAlert, alertModules } = useAlert();
  const [page, setPage] = useState<Page | null>(null); // current form state of page
  const [fetchError, setFetchError] = useState<number | null>(null);
  const [hasPageChanged, setHasPageChanged] = useState(false); // used to disable/enable publishing buttons
  const [allContentIds, setAllContentIds] = useState<AllContentIds>({});
  const [duplicateIds, setDuplicateIds] = useState<string[]>([]);
  const [hasPageBeenPublishedOnce, setHasPageBeenPublishedOnce] = useState(false);
  const [originalPageWhenInHistoryEditMode, setOriginalPageWhenInHistoryEditMode] = useState<Page | null>(null);
  const [hasRecentlyPublished, setHasRecentlyPublished] = useState<boolean>(false); // used to animate in success notification and reset page history
  const [isEditingLinkedItem, setIsEditingLinkedItem] = useState<boolean | string>(false);

  const currentProperty = useConvertedProperty();

  const {
    data,
    isLoading: isLoad,
    fetchStatus,
    error: loadingError
  } = usePageByRevisionGroupKeyQuery(
    {
      revisionGroupKey: pageRevisionGroupKey ?? '',
      publicationKey: currentProperty ?? ''
    },
    { enabled: !!pageRevisionGroupKey }
  );
  const isLoading = isLoad && fetchStatus !== 'idle';
  const { mutateAsync: pageUpdateMutateAsync, isLoading: isPublishing } = usePageUpdateMutation();

  const { mutateAsync: pageDeleteMutateAsync, isLoading: isDeleting } = usePageDeleteMutation();

  const { data: pageTypeSettingsResp } = usePageTypeSettingByPublicationKeyPageTypeQuery(
    { publicationKey: currentProperty ?? '', pageType: page?.pageType ?? PageType.Home },
    { enabled: !!currentProperty && !!page?.pageType }
  );

  const pageTypeSetting = pageTypeSettingsResp?.pageTypeSettingByPublicationKeyPageType as PageTypeSetting | undefined;

  const handlePartialPageChange = (newPage: Partial<Page>) => {
    if (!page) {
      return;
    }

    setHasPageChanged(true);
    const updatedPage = { ...page, ...newPage };
    setPage(updatedPage);
  };

  const handleShowHistoryEditMode = (historyPage: Page) => {
    setAllContentIds(() => ({}));
    setOriginalPageWhenInHistoryEditMode(page);
    handlePartialPageChange(historyPage);
  };

  const handleHideHistoryEditMode = () => {
    setAllContentIds(() => ({}));
    if (originalPageWhenInHistoryEditMode) {
      handlePartialPageChange(originalPageWhenInHistoryEditMode);
    }
    setOriginalPageWhenInHistoryEditMode(null);
  };

  const updatePage = useCallback((pageToModify: Page) => {
    setPage(pageToModify);
  }, []);

  const clearValidationErrorsInModules = () => {
    handlePartialPageChange({
      pageModules: clearValidationError(page?.pageModules)
    });
  };

  useEffect(() => {
    if (!isLoading && !page) {
      if (!data) {
        console.error('Fetch error', loadingError);
        let error = 404;
        if (loadingError instanceof Error) {
          if (loadingError.message === 'Failed to fetch') {
            error = 503;
          }
        }
        setFetchError(error);
      }
      updatePage(data?.pageByRevisionGroupKey as Page);
    }
  }, [isLoading, data, loadingError, updatePage, page]);

  useEffect(() => {
    if (Object.keys(allContentIds).length) {
      setDuplicateIds(findDuplicates(allContentIds));
    }
  }, [allContentIds]);

  const handlePublish = async (publishUtc?: number) => {
    if (!page) {
      return;
    }
    removeAlert();
    clearValidationErrorsInModules();
    setHasPageBeenPublishedOnce(true);
    const updateInput: PageUpdateInput = {
      layoutId: page.layoutId,
      name: page.name,
      notes: page.notes,
      revisionGroupKey: page.revisionGroupKey,
      createdUtc: page.createdUtc,
      pageModules: prepPageModulesForPublish(page.pageModules),
      publishUtc,
      publicationKey: currentProperty ?? ''
    };
    try {
      const data = await pageUpdateMutateAsync({
        pageUpdateInput: updateInput
      });
      setAllContentIds(() => ({}));
      setHasPageChanged(false);
      setPage(data.pageUpdate as Page);
      setHasRecentlyPublished(true);
      alertSuccess(
        publishUtc ? `Scheduled page for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.` : 'Published page.'
      );
      handleHideHistoryEditMode();
    } catch (e: unknown) {
      const ERROR_MESSAGE = `Could not ${publishUtc ? 'schedule' : 'publish'} the page.`;
      if (e instanceof Error) {
        try {
          const errorJson = JSON.parse(e.message) as PageError;
          const modulesWithValidationError = mergeModulesWithValidationError(
            page.pageModules,
            errorJson.validationError!.modulesWithValidationError
          );
          alertError(`${ERROR_MESSAGE} ${formatAlertMessage(errorJson)}`);
          alertModules(errorJson.validationError as PageValidationError);
          handlePartialPageChange({ pageModules: modulesWithValidationError });
        } catch (error: unknown) {
          // likely an error coming from class-validator
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const deleteScheduledPublish = async (pageDeleteInput: PageDeleteInput) => {
    try {
      await pageDeleteMutateAsync({ pageDeleteInput });
      setAllContentIds(() => ({}));
      setHasPageChanged(false);
      setHasRecentlyPublished(true);
      alertSuccess('Scheduled Page has been deleted');
      handleHideHistoryEditMode();
    } catch (e: unknown) {
      const ERROR_MESSAGE = 'Could not delete scheduled page.';
      if (e instanceof Error) {
        try {
          const errorJson = JSON.parse(e.message) as PageError;
          alertError(`${ERROR_MESSAGE} ${formatAlertMessage(errorJson)}`);
          alertModules(errorJson.validationError as PageValidationError);
        } catch (error: unknown) {
          // likely an error coming from class-validator
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const handleMobilePreviewPage = () => {
    if (!page) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log('handlePreviewPage function running for the page:', page);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (hasRecentlyPublished) {
      timeout = setTimeout(() => {
        setHasRecentlyPublished(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasRecentlyPublished]);

  const value = {
    page,
    fetchError,
    hasPageChanged,
    hasRecentlyPublished,
    isEditingLinkedItem,
    isPublishing,
    isDeleting,
    originalPageWhenInHistoryEditMode,
    allContentIds,
    duplicateIds,
    handlePartialPageChange,
    handlePublish,
    handleShowHistoryEditMode,
    handleHideHistoryEditMode,
    setAllContentIds,
    setIsEditingLinkedItem,
    deleteScheduledPublish,
    clearValidationErrorsInModules,
    hasPageBeenPublishedOnce,
    pageTypeSetting,
    handleMobilePreviewPage
  };

  return <PagePublishContext.Provider value={value}>{children}</PagePublishContext.Provider>;
};

import { ReactNode, useContext } from 'react';

import { AlertContext, AlertOptions, PageValidationError } from 'contexts/alert/AlertContext';

export const formatAlertMessage = (pageError: { message: ReactNode }) =>
  typeof pageError.message === 'string' ? pageError.message.replace(/; /g, '\n') : '';

export const useAlert = () => {
  const alert = useContext(AlertContext);

  const alertError = (message: ReactNode, options?: AlertOptions) => alert.alertError(message, options);
  const alertWarning = (message: ReactNode, options?: AlertOptions) => alert.alertWarning(message, options);
  const alertSuccess = (message: ReactNode, options?: AlertOptions) =>
    alert.alertSuccess(message, { autoDismiss: true, ...options });
  const removeAlert = () => alert.removeAlert();
  const alertModules = (pageValidationError: PageValidationError) => alert.alertModules(pageValidationError);

  return {
    alertState: alert.alertState,
    alertError,
    alertWarning,
    alertSuccess,
    removeAlert,
    alertModules
  };
};

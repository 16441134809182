/**
 * Creates a copy of an array and reverses its elements.
 * Compared to the `reverse` method of arrays, this function does not modify the original array.
 * @param arr The array to be reversed.
 * @returns A new array containing the elements in reversed order.
 */
export function reverse<T>(arr: T[]): T[] {
  const reversedArr = new Array<T>(arr.length);

  for (let i = 0, j = arr.length - 1; i < arr.length; i++, j--) {
    reversedArr[i] = arr[j];
  }

  return reversedArr;
}

export const arrayEquals = (a: any[], b: string | any[] | undefined) =>
  Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);

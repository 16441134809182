import { Button, IconPlus, Wrapper } from '@screentone/core';

import { EntityComponent } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';

interface AddEntityProps {
  hierarchyId: string;
  entity: EntityComponent;
  textDisplay?: string;
}

export const AddEntity = ({ hierarchyId, entity, textDisplay = 'Add to collection' }: AddEntityProps) => {
  const { insertEntity } = useDataModelContext();

  return (
    <Wrapper>
      <Button
        tertiary
        icon={IconPlus as SvgComponent}
        onClick={() => insertEntity(hierarchyId, entity)}
        data-testid="page-add-collection"
      >
        {textDisplay}
      </Button>
    </Wrapper>
  );
};

import { Typography, Wrapper } from '@screentone/core';

import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import styles from '../../PageModuleItemList.module.scss';

const getContentTypeForDisplay = (contentTypes?: PublicationSettingSearchableContentType[]) => {
  if (contentTypes?.length === 1) {
    switch (contentTypes[0]) {
      case PublicationSettingSearchableContentType.CommerceProduct:
        return 'products';
      default:
        return `${contentTypes[0].toLowerCase()}s`;
    }
  }
  return 'items';
};

interface PageModuleItemCountProps {
  isNewsletter?: boolean; // this is never used?
  isMobileAppScreen?: boolean;
  count: number;
  min?: number;
  max?: number;
  showMaxInDenominator?: boolean;
  contentTypes?: PublicationSettingSearchableContentType[];
}
export const PageModuleItemCount = ({
  isNewsletter,
  isMobileAppScreen,
  count,
  min,
  max,
  showMaxInDenominator,
  contentTypes
}: PageModuleItemCountProps) => {
  let denominator;
  if (isMobileAppScreen) {
    return null;
  }

  if (showMaxInDenominator) {
    denominator = max;
  } else if (min && !max) {
    denominator = count < min ? min : null;
  } else if (max && !min) {
    denominator = count > max ? max : null;
  } else if (max && min) {
    denominator = count < min ? min : count > max ? max : null;
  }

  if (!denominator) {
    return null;
  }

  const contentType = getContentTypeForDisplay(contentTypes);

  if (isNewsletter) {
    return (
      <>
        {count > 0 ? (
          <></>
        ) : (
          <div className={styles.numItemsMessage}>
            <Wrapper padding={{ all: 'sm' }}>
              <Typography variant="note">Drop article here</Typography>
            </Wrapper>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={styles.numItemsMessage} data-testid="itp-article-num-msg">
      <Wrapper padding={{ all: 'sm' }}>
        <Typography variant="note">
          {count}/{denominator} {contentType}
        </Typography>
      </Wrapper>
    </div>
  );
};

import { PublicationSetting } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { ContentConstantsResponse, modifyPublicationMapResult } from 'hooks/useContentConstants';

export function isAltSummDisabled(
  content: AllessehContent,
  currentProperty: string,
  publicationSetting?: PublicationSetting,
  contentConstants?: ContentConstantsResponse
) {
  const publicationMap = contentConstants?.publicationMap;
  const editableExternalPublications = publicationSetting?.editableExternalPublications ?? [];
  const disableAltSummEdit = publicationSetting?.disableAltSummEdit ?? false;

  if (!publicationMap) {
    return false;
  }

  const currentProduct = modifyPublicationMapResult(publicationMap, content.data.attributes.product);
  return (
    disableAltSummEdit ||
    (currentProperty !== currentProduct &&
      !editableExternalPublications.find((property) => currentProduct === property))
  );
}

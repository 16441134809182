import { Button, IconTrash, Wrapper } from '@screentone/core';
import { isEqual } from 'lodash';

import { AllessehContent, AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
import { QUERY_CONTENT_ID_KEY } from 'utils/queryUtils';

interface ExcludeFromQueryProps {
  jsonQueryStr: string;
  allessehContent: AllessehContent;
  setJsonQueryStr: (newQueryStr: string) => void;
}

export const ExcludeFromQuery = ({ jsonQueryStr, allessehContent, setJsonQueryStr }: ExcludeFromQueryProps) => {
  const handleExclude = () => {
    const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
    parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
    parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
    const newTerm = {
      term: {
        key: QUERY_CONTENT_ID_KEY,
        value: allessehContent.data.id
      }
    };
    if (parsedJsonQuery.query.not.findIndex((rule: AllessehQueryRule) => isEqual(rule, newTerm)) < 0) {
      parsedJsonQuery.query.not.push(newTerm);
    }

    setJsonQueryStr(JSON.stringify(parsedJsonQuery));
  };

  return (
    <Wrapper>
      <Button
        tertiary
        icon={IconTrash as SvgComponent}
        color="lava"
        onClick={handleExclude}
        data-testid="page-content-card-exclude-query-button"
      >
        Exclude from Query
      </Button>
    </Wrapper>
  );
};

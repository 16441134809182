import { Button, IconArrowDown, Wrapper } from '@screentone/core';
import { isEqual } from 'lodash';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { AllessehContent, AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import { QUERY_CONTENT_ID_KEY } from 'utils/queryUtils';

interface MoveManualArticleToQueryArticleProps {
  hierarchyId: string;
  jsonQueryStr: string;
  allessehContent: AllessehContent;
  setJsonQueryStr: (newQueryStr: string) => void;
}

export const MoveManualArticleToQueryArticle = ({
  hierarchyId,
  jsonQueryStr,
  allessehContent,
  setJsonQueryStr
}: MoveManualArticleToQueryArticleProps) => {
  const { removeEntity } = useDataModelContext();

  const handleReinclude = () => {
    const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
    parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
    parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
    const newTerm = {
      term: {
        key: QUERY_CONTENT_ID_KEY,
        value: allessehContent.data.id
      }
    };
    // remove exclusion from query
    parsedJsonQuery.query.not = [...parsedJsonQuery.query.not].filter((rule) => !isEqual(rule, newTerm));

    setJsonQueryStr(JSON.stringify(parsedJsonQuery));
  };

  const handleMove = () => {
    handleReinclude();
    removeEntity(hierarchyId);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconArrowDown as SvgComponent} onClick={handleMove}>
        Remove from Top
      </Button>
    </Wrapper>
  );
};

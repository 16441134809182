import { useState } from 'react';
import { Button, Typography } from '@screentone/core';

import { ScheduleDialog } from 'components/schedule-dialog/ScheduleDialog';
import { PublicationSetting } from 'data/generated/graphql';
import { getNextQuarterHour } from 'utils/newsletters';

export const NewsletterScheduleDialog = ({
  handleSchedule,
  reschedule = false,
  publicationSetting
}: {
  handleSchedule: (publishUtc: number) => void;
  reschedule?: boolean;
  publicationSetting?: PublicationSetting;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const afterCalendarContent = publicationSetting ? (
    <Typography variant="note">
      All times are in {publicationSetting.preferredTimezone.timezoneAbbreviation}.
    </Typography>
  ) : null;

  const handleScheduleClick = (publishUtc: number) => {
    handleSchedule(publishUtc);
    setModalOpen(false);
  };

  const initialDate = getNextQuarterHour(publicationSetting);
  const initialTime = `${initialDate.getHours() < 10 ? `0${initialDate.getHours()}` : initialDate.getHours()}:${
    initialDate.getMinutes() < 10 ? `0${initialDate.getMinutes()}` : initialDate.getMinutes()
  }`;
  const minDate = new Date(initialDate.getTime() - 60000); // one minute before

  return (
    <>
      <Button primary onClick={() => setModalOpen(true)} data-testid="newsletters-schedule-button">
        {reschedule ? 'Reschedule' : 'Schedule'}
      </Button>
      {modalOpen && (
        <ScheduleDialog
          initialTime={initialTime}
          initialDate={initialDate}
          dialogTitle="Schedule Newsletter"
          onSchedule={handleScheduleClick}
          onCancel={() => setModalOpen(false)}
          afterCalendarContent={afterCalendarContent}
          minDate={minDate}
        />
      )}
    </>
  );
};

import { Box, FormLabel, Input, Typography } from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { PageModule } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import styles from './PageModuleTitle.module.scss';

interface PageModuleTitleProps {
  pageModule: PageModule;
  onChange: ((newPageModule: PageModule) => void) | null;
}

export const PageModuleTitle = ({ pageModule, onChange }: PageModuleTitleProps) => {
  const { data: publicationSettingsResp } = usePublicationSettings(); // publication settings should have access to 'artifact' details for new data model

  const handleChangeStringField =
    (fieldKey: 'title' | 'subtitle' | 'subtitleUrl') => (e: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = e.target;
      if (fieldKey === 'subtitleUrl') {
        const firstChar = value[0];
        if (firstChar !== '/') {
          value = `/${value}`;
        }
      }
      const newPageModule = clonedeep(pageModule);
      if (newPageModule.uiModuleFields.titleModule) {
        newPageModule.uiModuleFields.titleModule[fieldKey] = value;
      }
      onChange?.(newPageModule);
    };

  return (
    <>
      <FormLabel key="title" label="Title" fullWidth>
        <Input
          placeholder="Enter title"
          value={pageModule.uiModuleFields.titleModule ? pageModule.uiModuleFields.titleModule.title : ''}
          margin={{ bottom: 'sm' }}
          onChange={handleChangeStringField('title')}
          disabled={!onChange}
          data-testid="page-enter-title"
        />
      </FormLabel>
      <FormLabel key="subtitle" label="Subtitle" fullWidth margin={{ top: 'sm' }}>
        <Input
          placeholder="Enter subtitle"
          value={pageModule.uiModuleFields.titleModule ? pageModule.uiModuleFields.titleModule.subtitle : ''}
          margin={{ bottom: 'sm' }}
          onChange={handleChangeStringField('subtitle')}
          disabled={!onChange}
          data-testid="page-enter-subtitle"
        />
      </FormLabel>
      <FormLabel key="subtitleUrl" label="Subtitle URL Path" fullWidth margin={{ top: 'sm' }}>
        <div className={styles.subtitleUrlForm}>
          <Box className={styles.baseRouteBox} padding={{ left: 'xs', right: 'xs' }}>
            <Typography>{publicationSettingsResp?.publicationSetting.baseRoute}/</Typography>
          </Box>
          <Input
            placeholder="Enter subtitle url path"
            value={pageModule.uiModuleFields.titleModule?.subtitleUrl?.slice(1) ?? ''}
            margin={{ bottom: 'sm' }}
            onChange={handleChangeStringField('subtitleUrl')}
            disabled={!onChange}
            data-testid="page-enter-subtitle-url"
          />
        </div>
      </FormLabel>
    </>
  );
};

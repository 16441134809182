import { Typography, Wrapper } from '@screentone/core';

import BannerSearch from 'components/banner-search/BannerSearch';

export const Banners = () => (
  <Wrapper margin={{ top: 'lg' }}>
    <Typography variant="header2" margin={{ bottom: 'md' }}>
      Banners
    </Typography>
    <BannerSearch />
  </Wrapper>
);

import { useAppQuery } from 'data/query-client';
import restClient from 'data/restClient';
import { useAuthToken } from 'hooks/useAuthToken';
import { useConvertedProperty } from './useConvertedProperty';

export interface TopicsResponse {
  url?: string;
  seoDisplayName: string;
  djmlKeyword: string;
  subject: boolean;
  brand: boolean;
}

const getTopicsData = (authToken: string, currentProperty: string) =>
  restClient.get<TopicsResponse[]>(`/topics?publicationKey=${currentProperty}`, {
    headers: { Authorization: authToken }
  });

export const useTopicsQuery = () => {
  const authToken = useAuthToken();
  const currentProperty = useConvertedProperty();

  return useAppQuery([], () => getTopicsData(authToken, currentProperty ?? ''));
};

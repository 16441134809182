import { useState } from 'react';
import { Button, IconSave, IconTrash } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import { getPublishButtonVariant, PublishButton } from './components/publish-button/PublishButton';
import styles from './PagePublishingButtons.module.scss';
import { EmbargoPagePublishDialog } from '../embargo-page-publish-dialog/EmbargoPagePublishDialog';
import { SchedulePageDialog } from '../schedule-page-dialog/SchedulePageDialog';

export const PagePublishingButtons = () => {
  const { getEmbargoContent } = useDataModelContext();
  const {
    canPublish,
    canSave,
    originalPageWhenInHistoryEditMode,
    isPublishing,
    handleSaveDraft,
    isSavingDraft,
    isScheduling,
    isRescheduling,
    isUpdatingSchedule,
    isDeleting,
    hasPageChanged,
    handlePublish,
    handleSchedule,
    handleReschedule,
    handleUpdateSchedule,
    handleDeleteSchedule,
    handleHideHistoryEditMode
  } = usePagePublish();

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showEmbargoModal, setShowEmbargoModal] = useState(false);
  const isHistoryEdit = !!originalPageWhenInHistoryEditMode;

  const handleSave = async () => {
    await handleUpdateSchedule();
  };

  const onSchedule = async (publishUtc: number) => {
    if (isHistoryEdit) {
      await handleReschedule(publishUtc);
    } else {
      await handleSchedule(publishUtc);
    }

    setShowScheduleModal(false);
  };

  const handlePublishImmediately = async () => {
    await handlePublish();
  };

  const toggleScheduleDialog = () => {
    setShowScheduleModal((prevState) => !prevState);
  };

  const toggleEmbargoDialog = () => {
    setShowEmbargoModal((prevState) => !prevState);
  };

  const handleDeleteScheduledPublish = async () => {
    await handleDeleteSchedule();
  };

  const embargoedContent = getEmbargoContent();
  const hasEmbargoedContent = embargoedContent.length > 0;
  const variant = getPublishButtonVariant({ hasEmbargoedContent, isHistoryEdit });

  const disabled =
    isPublishing ||
    isSavingDraft ||
    isScheduling ||
    isRescheduling ||
    isUpdatingSchedule ||
    isDeleting ||
    !hasPageChanged;

  const saveDisabled =
    isPublishing || isSavingDraft || isScheduling || isRescheduling || isUpdatingSchedule || isDeleting || !canSave;

  const publishAndScheduleDisabled =
    isPublishing || isSavingDraft || isScheduling || isRescheduling || isUpdatingSchedule || isDeleting || !canPublish;

  return (
    <>
      {showScheduleModal && <SchedulePageDialog onSchedule={onSchedule} onCancel={toggleScheduleDialog} />}
      {showEmbargoModal && (
        <EmbargoPagePublishDialog
          embargoedContent={embargoedContent}
          onPublish={isHistoryEdit ? handleSave : handlePublishImmediately}
          onCancel={toggleEmbargoDialog}
        />
      )}
      <div className={styles.publishingButtons}>
        {isHistoryEdit && (
          <Button
            tertiary
            onClick={handleDeleteScheduledPublish}
            disabled={disabled}
            margin={{ right: 'sm' }}
            color="lava"
            icon={IconTrash as SvgComponent}
            data-testid="page-delete-schedule-button"
          >
            Delete Scheduled publish
          </Button>
        )}
        {isHistoryEdit && (
          <Button
            secondary
            onClick={handleHideHistoryEditMode}
            disabled={disabled}
            margin={{ right: 'sm' }}
            data-testid="page-cancel-edit-button"
          >
            Cancel Edit
          </Button>
        )}
        {!isHistoryEdit && (
          <Button
            tertiary
            onClick={handleSaveDraft}
            disabled={saveDisabled}
            data-testid="page-schedule-button"
            margin={{ right: 'md' }}
            icon={IconSave as SVGElement}
          >
            Save Draft
          </Button>
        )}
        <Button
          margin={{ left: 'sm' }}
          secondary
          onClick={toggleScheduleDialog}
          disabled={publishAndScheduleDisabled}
          data-testid="page-schedule-button"
        >
          {isHistoryEdit ? 'Reschedule' : 'Schedule'}
        </Button>
        <PublishButton
          variant={variant}
          disabled={publishAndScheduleDisabled}
          publishFunctions={{ handlePublishImmediately, handleSave, toggleEmbargoDialog }}
        />
      </div>
    </>
  );
};

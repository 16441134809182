import { Typography } from '@screentone/core';

import ContentTypeToken from 'components/content-type-token/ContentTypeToken';
import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import styles from './ContentCardHeadline.module.scss';

interface ContentCardHeadlineProps {
  isIconPresent: boolean;
  content: AllessehContent;
  headline: string | undefined;
}

//
const getType = (content: AllessehContent) => {
  if (content.data.attributes.product.includes('Live Coverage'))
    return PublicationSettingSearchableContentType.LiveCoverage.toLowerCase();
  if (content.data.attributes.section_name === 'Opinion')
    return PublicationSettingSearchableContentType.Opinion.toLowerCase();
  switch (content.data.attributes.product) {
    case 'Interactive Media':
      return PublicationSettingSearchableContentType.Tappable.toLowerCase();
    default:
      return content.data.type;
  }
};

export const ContentCardHeadline = ({ isIconPresent = true, content, headline }: ContentCardHeadlineProps) => (
  <Typography
    componentEl="div"
    data-testid="content-card-headline-label"
    variant="bodytext"
    margin={{ left: 'sm' }}
    className={styles.headline}
  >
    {isIconPresent && (
      <ContentTypeToken
        data-testid="content-card-headline-icon"
        type={getType(content)}
        size="mlg"
        margin={{ right: 'sm' }}
        className={styles.icon}
      />
    )}
    {headline}
  </Typography>
);

import { Button, IconExternalLink, Wrapper } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';

interface ViewAllessehContentProps {
  content: AllessehContent;
  externalLink?: never;
}

interface ViewExternalLinkProps {
  content?: never;
  externalLink: string;
}

type ViewPublishedURLProps = ViewAllessehContentProps | ViewExternalLinkProps;

export const ViewPublishedURL = ({ content, externalLink }: ViewPublishedURLProps) => {
  if (content) {
    const displayPublishedUrl =
      content.data.attributes.content_status === 'live' &&
      (content.data.type === 'article' || content.data.type === 'SummaryModel');

    const handleViewPublishedUrl = () => {
      window.open(content.data.attributes.source_url, '_blank');
    };

    return displayPublishedUrl ? (
      <Wrapper>
        <Button
          tertiary
          icon={IconExternalLink as SvgComponent}
          iconPosition="right"
          data-testid="page-content-card-view-published-url-button"
          onClick={handleViewPublishedUrl}
        >
          View Published URL
        </Button>
      </Wrapper>
    ) : null;
  }

  if (externalLink) {
    const handleOpenExternalLink = () => {
      window.open(externalLink, '_blank');
    };

    return (
      <Wrapper>
        <Button
          tertiary
          icon={IconExternalLink as SvgComponent}
          iconPosition="right"
          data-testid="page-content-card-view-published-url-button"
          onClick={handleOpenExternalLink}
        >
          Open external link
        </Button>
      </Wrapper>
    );
  }

  throw new Error('Either `content` or `externalLink` props must be provided to <ViewPublishedURL> component');
};

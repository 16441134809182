import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import Newsletters from 'features/newsletters/Newsletters';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';

const NewsletterPage = () => {
  const { data: publicationSettingsResp } = usePublicationSettings();
  const currentProperty = useConvertedProperty();
  if (!currentProperty || !(currentProperty === 'marketwatch' || currentProperty === 'wsj'))
    return <ErrorPage type="404" />;

  return (
    <PageRootContainer>
      <NarrowPageWrapper>
        <Newsletters publicationSetting={publicationSettingsResp?.publicationSetting} />
      </NarrowPageWrapper>
    </PageRootContainer>
  );
};

export default NewsletterPage;

import { ReactElement } from 'react';

import { AllessehArticleQueryResponse, useAllessehArticleQuery } from 'hooks/useAllessehArticleQuery';
import { AllessehContent, AllessehQuerySortKeyTypes } from 'hooks/useAllessehContentQuery';
import { SearchContentFilters } from '../search-content-filters/SearchContentFiltersContainer';
import SearchContentList from '../search-content-list/SearchContentList';

const MESSAGES = {
  NOT_FOUND: 'Article like this has not been found, please check the URL and try again.',
  ALREADY_USED: 'Article is already used on the page.',
  INVALID_URL: 'Article URL is invalid, please check the URL and try again.',
  WRONG_SECTION_TYPE: 'The article does not match the other filters in this search.'
};

function getMessage({
  data,
  isLoading,
  alreadyUsed,
  wrongSectionType,
  error
}: {
  data: AllessehArticleQueryResponse | undefined;
  isLoading: boolean;
  alreadyUsed: boolean;
  wrongSectionType: boolean;
  error: boolean;
}) {
  if (data?.response?.error) return data.response.message;
  if (error) return MESSAGES.INVALID_URL;
  if (!data && !isLoading) return MESSAGES.NOT_FOUND;
  if (alreadyUsed) return MESSAGES.ALREADY_USED;
  if (wrongSectionType) return MESSAGES.WRONG_SECTION_TYPE;
  return '';
}

function isURLValid(url: string) {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return false;
  } catch (e: unknown) {
    return true;
  }
}
interface SearchParams {
  search: string;
  filters: SearchContentFilters;
  sort: AllessehQuerySortKeyTypes;
  refreshTimestamp?: number;
}

export interface SearchContentByProps {
  searchParams: SearchParams;
  resultListClassName?: string;
  embargo?: boolean;
  renderContentCard: (content: AllessehContent, index: number) => ReactElement;
}

const SearchByUrl = ({ searchParams, resultListClassName, embargo, renderContentCard }: SearchContentByProps) => {
  const error = isURLValid(searchParams.search);

  const {
    data,
    isLoading: isLoad,
    fetchStatus
  } = useAllessehArticleQuery(searchParams.search, {
    enabled: !!searchParams.search && !error
  });
  const isLoading = isLoad && fetchStatus !== 'idle';

  const alreadyUsed = !!(data?.data && searchParams.filters.excludedContentIds?.includes(data.data.id));
  const wrongSectionType = !!(
    data?.data &&
    searchParams.filters.sectionType &&
    searchParams.filters.sectionType !== data.data.attributes.section_type
  );

  const message = getMessage({ data, isLoading, alreadyUsed, wrongSectionType, error });
  const includedContentItemsInSearch = wrongSectionType || alreadyUsed ? [] : ([data] as AllessehContent[]);
  const excludedContentItemsInSearch = alreadyUsed ? ([data] as AllessehContent[]) : [];

  return (
    <SearchContentList
      data-testid="search-content-list-by-url"
      excludedContentItemsInSearch={excludedContentItemsInSearch}
      includedContentItemsInSearch={includedContentItemsInSearch}
      className={resultListClassName}
      isLoading={isLoading}
      customMessage={message}
      renderContentCard={renderContentCard}
      embargo={embargo}
    />
  );
};

export default SearchByUrl;

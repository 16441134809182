import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { PublicationSetting } from 'data/generated/graphql';
import {
  SnippetyChartData,
  SnippetyResponseWithChart,
  SnippetyResponseWithImage,
  SnippetyResponseWithVideo,
  useSnippetyQuery,
  VideoDataForSummarian
} from 'hooks/useSnippetyQuery';
import { isValidURL } from 'utils/url';

import { ControlledAltSumm, DEFAULT_MEDIA_ALT_SUMM } from 'components/altsumm-modal/AltSummModal.utils';
import {
  CHART_ID_PATTERN,
  errorMessages,
  getChartData,
  getImageData,
  getVideoData,
  GUID_PATTERN,
  IMAGE_MANAGER_ID_PATTERN,
  MediaTypes
} from 'components/altsumm-modal/components/media-input/MediaInput.utils';

export function getDomain(publicationSettings: PublicationSetting | undefined, type: MediaTypes | '', value: string) {
  if (type === 'url' && !isValidURL(value)) {
    return '';
  }
  if (type === 'image' && !IMAGE_MANAGER_ID_PATTERN.test(value)) {
    return '';
  }
  if (type === 'video' && !GUID_PATTERN.test(value)) {
    return '';
  }
  if (type === 'chart' && !CHART_ID_PATTERN.test(value)) {
    return '';
  }
  switch (type) {
    case 'image':
      return `${publicationSettings?.snippetyIMDomain ?? ''}/${value}`;
    case 'chart':
      const modifiedValue = value.startsWith('cdc_') ? value.replace('cdc_', '') : value;
      return `${publicationSettings?.snippetyChartDomain ?? ''}/${modifiedValue}`;
    case 'video':
      return `${publicationSettings?.snippetyVideoDomain ?? ''}${value}`;
    case 'url':
      return value;
    default:
      return '';
  }
}

export const useMediaForm = (
  type: MediaTypes | '',
  onAdd: (data: any) => void,
  setControlledAltSumm: React.Dispatch<React.SetStateAction<ControlledAltSumm>>,
  publicationSettings?: PublicationSetting
) => {
  const [isIdFormOpen, setIsIdFormOpen] = useState(false);
  const [id, setId] = useState('');
  const [error, setError] = useState('');
  const [idData, setIdData] = useState<SnippetyResponseWithImage | VideoDataForSummarian | SnippetyChartData | null>(
    null
  );

  const domain = getDomain(publicationSettings, type, id);

  const { data, error: idError, isLoading: isDataLoading } = useSnippetyQuery(domain);

  const typedImageData = type === 'image' && data ? (data as SnippetyResponseWithImage) : null;
  const typedVideoData = type === 'video' && data ? (data as SnippetyResponseWithVideo) : null;
  const typedChartData = type === 'chart' && data ? (data as SnippetyResponseWithChart) : null;

  function isValid(currentId: string) {
    if (currentId.length === 0 || type === '') {
      return true;
    }

    if (type === 'image' && !IMAGE_MANAGER_ID_PATTERN.test(currentId)) {
      return false;
    }

    if (type === 'video' && !GUID_PATTERN.test(currentId)) {
      return false;
    }

    if (type === 'chart' && !CHART_ID_PATTERN.test(currentId)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (data?.error) {
      setError((data as { message: string; code: string; error: boolean }).message);
      return;
    }
    if (type === 'image' && typedImageData) {
      setIdData(getImageData(data as SnippetyResponseWithImage));
    }
    if (type === 'video' && typedVideoData) {
      setIdData(getVideoData(data as SnippetyResponseWithVideo));
    }
    if (type === 'chart' && typedChartData) {
      setIdData(getChartData(data as SnippetyResponseWithChart));
    }
  }, [data, setIdData, type, typedChartData, typedImageData, typedVideoData]);

  useEffect(() => {
    if (id.length === 0) {
      setError('');
    } else if (!domain && id.length > 0 && type !== '' && error !== errorMessages[type].invalidId && isIdFormOpen) {
      setError(errorMessages[type].invalidId);
    } else if (idError && type !== '' && idError !== error) {
      setError((idError as AxiosError<{ message: string }>).response?.data.message ?? errorMessages[type].serverError);
    }
  }, [idError, type, error, domain, id, isIdFormOpen]);

  const onDelete = () => {
    setId('');
    onAdd(null);
    setControlledAltSumm((prev) => ({
      ...prev,
      ...DEFAULT_MEDIA_ALT_SUMM
    }));
  };

  const onIdAdd = () => {
    if (id.length === 0) {
      if (type in errorMessages) {
        setError(errorMessages[type as keyof typeof errorMessages].invalIdInput);
      }
      return;
    }

    if (type === 'image' && !IMAGE_MANAGER_ID_PATTERN.test(id)) {
      setError(errorMessages.image.invalidId);
      return;
    }

    if (type === 'video' && !GUID_PATTERN.test(id)) {
      setError(errorMessages.video.invalidId);
      return;
    }

    if (type === 'chart' && !CHART_ID_PATTERN.test(id)) {
      setError(errorMessages.chart.invalidId);
      return;
    }

    onAdd(idData);
    setId('');
    setIsIdFormOpen(false);
  };

  const onIdChange = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (e === null) {
      setId('');
    } else {
      if (isValid(e.target.value)) {
        setError('');
      }
      setId(e.target.value);
    }
  };

  const shouldAdd = !!(!isDataLoading && !idError && id.length > 0);
  return {
    id,
    error,
    onDelete,
    onIdAdd,
    onIdChange,
    isIdFormOpen,
    shouldAdd,
    setIsIdFormOpen
  };
};

import { ReactElement } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  Group,
  IconPlusCircle,
  Tooltip,
  Typography,
  useDropdownState,
  Wrapper
} from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { useIssue } from 'contexts/issue/useIssue';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { AvailableLayoutModule, ItpIssuePageType, PageModule } from 'data/generated/graphql';
import { DISPLAY_KEY as ITP_SECTION_DISPLAY_KEY } from 'features/issues/util';
import { getUiModuleTypeLabel } from 'features/page-edit/pageEditUtils';
import styles from './AddModuleButton.module.scss';

enum ITPAddableModules {
  FRONT_PAGE,
  OTHER_SECTIONS
}

interface AddModuleButtonProps {
  addableUiModules?: AvailableLayoutModule[];
  pageModules: PageModule[];
  /**
   * If this is a child module, the index of the parent module
   * ie. where the new module will be added
   */
  newModuleIndex?: number;
  itpPageType?: ItpIssuePageType;
  isBlockDivider?: boolean;
  isITP?: boolean;
}

export const AddModuleButton = ({
  addableUiModules,
  pageModules,
  newModuleIndex,
  itpPageType,
  isBlockDivider = false,
  isITP = false
}: AddModuleButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isAddModuleDropdownOpen,
    setOpen: setIsAddModuleDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();
  const { handlePartialPageChange } = usePagePublish();
  const { issue, handlePartialIssueChange } = useIssue();

  // NOTE: Don't show Add module button for ITP Front Section,
  // as there's only ever one page for that section.
  if (itpPageType === ItpIssuePageType.FrontSection) {
    return null;
  }

  const handleAddModule = (index: number) => {
    if (!addableUiModules || addableUiModules.length === 0) {
      return;
    }

    const defaultModule = clonedeep(addableUiModules[index].defaultModule);
    let newPageModules = [];
    // Only with block divider's can modules be inserted in a specific place
    if (isBlockDivider) {
      const before = pageModules.slice(0, newModuleIndex);
      const after = pageModules.slice(newModuleIndex);
      newPageModules = [...before, defaultModule, ...after];
    } else {
      newPageModules = [...pageModules, defaultModule];
    }

    if (isITP && itpPageType) {
      const issuePages = issue!.pages;
      const itpPageIndex = ITP_SECTION_DISPLAY_KEY[itpPageType].order - 1;
      issuePages[itpPageIndex].pageModules = newPageModules;
      handlePartialIssueChange({ pages: issuePages });
    } else {
      handlePartialPageChange({ pageModules: newPageModules });
    }
    setIsAddModuleDropdownOpen(false);
  };

  const handleAddModuleDropdownOpenToggle = () => {
    // Don't open module type dropdown if on ITP
    if (!isITP) {
      setIsAddModuleDropdownOpen(!isAddModuleDropdownOpen);
    }
  };

  const blockDividerAction = () => {
    if (isITP && itpPageType) {
      // When on ITP, add BasicModule from list of `addableUiModules`
      handleAddModule(ITPAddableModules.OTHER_SECTIONS);
    } else {
      setIsAddModuleDropdownOpen(!isAddModuleDropdownOpen);
    }
  };

  const addableUiModulesJSX = addableUiModules?.map((addableUiModules, index) => (
    <Wrapper
      padding={{ all: 'md' }}
      key={addableUiModules.uiModuleType}
      className={styles.addableUiModuleOption}
      onClick={() => handleAddModule(index)}
    >
      <Typography>{getUiModuleTypeLabel(addableUiModules.uiModuleType)}</Typography>
    </Wrapper>
  ));

  const blockDivider = (
    <div className={styles.blockDivider}>
      <div className={styles.blockDividerContent}>
        <Divider className={styles.blockDividerDivider} />
        <Dropdown
          arrow="top"
          position="center"
          margin={{ horizontal: 'xs' }}
          componentRef={dropdownRef}
          open={isAddModuleDropdownOpen}
          onToggle={handleAddModuleDropdownOpenToggle}
        >
          <Dropdown.Trigger>
            <Tooltip>
              <Tooltip.Content position={newModuleIndex === 0 ? 'bottom' : 'top'}>Add a Module Here</Tooltip.Content>
              <Tooltip.Trigger>
                <Button
                  tertiary
                  icon={IconPlusCircle as SvgComponent}
                  onClick={blockDividerAction}
                  data-testid="add-module-button"
                />
              </Tooltip.Trigger>
            </Tooltip>
          </Dropdown.Trigger>
          <Dropdown.Content
            className={styles.blockDividerDropdownContent}
            arrow="top"
            margin={{ top: 'sm' }}
            padding={{ all: 'md' }}
          >
            {addableUiModulesJSX}
          </Dropdown.Content>
        </Dropdown>
        <Divider className={styles.blockDividerDivider} />
      </div>
    </div>
  );

  const normalButton = (
    <Dropdown
      componentRef={dropdownRef}
      trigger={
        <Group gap="xs" data-testid="add-module-button">
          <IconPlusCircle />
          Add a module
        </Group>
      }
      open={isAddModuleDropdownOpen || false}
      onToggle={handleAddModuleDropdownOpenToggle}
      padding={{ all: 'none' }}
    >
      {addableUiModules?.map((addableUiModules, index) => (
        <Wrapper
          padding={{ all: 'md' }}
          // TODO: fix this eslint error
          // eslint-disable-next-line react/no-array-index-key
          key={addableUiModules.uiModuleType}
          className={styles.addableUiModuleOption}
          onClick={() => handleAddModule(index)}
          data-testid="page-add-module-title-button"
        >
          <Typography>{getUiModuleTypeLabel(addableUiModules.uiModuleType)}</Typography>
        </Wrapper>
      ))}
    </Dropdown>
  );

  return addableUiModules && addableUiModules.length > 0 ? (isBlockDivider ? blockDivider : normalButton) : null;
};

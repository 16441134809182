import { useState } from 'react';
import { Box, Button, IconArchive, IconCopy, IconPackage, IconTrash, Loader } from '@screentone/core';

import { ConfirmationDialog } from 'components';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { PagesDtoForCollectionIdQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { useDeleteCollection } from 'hooks/collections/useDeleteCollection';
import { useIsAdmin } from 'hooks/roles';
import styles from './CollectionPublishingButtons.module.scss';
import {
  CollectionOffPlatformOverlay,
  TOffPlatformInput
} from '../collection-off-platform-overlay/CollectionOffPlatformOverlay';

interface CollectionPublishingButtonsProps {
  hasCollectionChanged: boolean;
  pagesForCollection?: PagesDtoForCollectionIdQuery;
  isUpdating: boolean;
  handleCopy: () => Promise<void>;
  isCopying: boolean;
  handleArchive: () => Promise<void>;
  isArchiving: boolean;
  handleOffPlatformConvert: (input: TOffPlatformInput) => Promise<void>;
  isConvertingToOffPlatform: boolean;
  waitingForAllesseh: boolean;
}

export const CollectionPublishingButtons = ({
  hasCollectionChanged,
  pagesForCollection,
  isUpdating,
  handleCopy,
  isCopying,
  handleArchive,
  isArchiving,
  handleOffPlatformConvert,
  isConvertingToOffPlatform,
  waitingForAllesseh
}: CollectionPublishingButtonsProps) => {
  const currentProperty = useConvertedProperty();
  const { metadata } = useDataModelContext();
  const { showDeleteModal, setShowDeleteModal, canDelete, deleteHint, isDeleteLoading, handleDeleteCollection } =
    useDeleteCollection({
      collectionId: metadata.allessehId,
      variant: 'collections',
      pagesForCollection
    });

  const isAdmin = useIsAdmin();
  const [offPlatformInputOpen, setOffPlatformInputOpen] = useState(false);

  const isArchived = 'isArchived' in metadata && metadata.isArchived;

  return (
    <Box data-testid="collection-info-box-section-options" margin={{ top: 'md' }}>
      <Box.Title data-testid="collection-info-box-section-options-title">Options</Box.Title>
      <Box.Content data-testid="collection-info-box-section-options-content" className={styles.optionsButtons}>
        <Button
          tertiary
          icon={IconArchive as SvgComponent}
          disabled={isArchiving}
          margin={{ right: 'md' }}
          onClick={handleArchive}
          data-testid="collection-info-box-section-options-archive-button"
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </Button>
        {isAdmin && (
          <Button
            tertiary
            icon={IconTrash as SvgComponent}
            color="lava"
            data-testid="collection-info-box-section-options-delete-button"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        )}
        <Button
          tertiary
          icon={IconCopy as SvgComponent}
          disabled={isCopying}
          onClick={handleCopy}
          data-testid="collection-info-box-section-options-make-copy-button"
        >
          Make a copy
        </Button>
        {isAdmin && (
          <Button
            tertiary
            icon={IconPackage as SvgComponent}
            disabled={
              isUpdating || isArchived || hasCollectionChanged || isConvertingToOffPlatform || waitingForAllesseh
            }
            onClick={() => setOffPlatformInputOpen(true)}
            data-testid="collection-info-box-section-options-convert-off-platform-button"
          >
            {(isConvertingToOffPlatform || waitingForAllesseh) && <Loader size="md" margin={{ right: 'md' }} />}
            Convert to Off-Platform
          </Button>
        )}
        <ExternalLink
          contentId={metadata.allessehId}
          type={ExternalLinkContentType.COLLECTION}
          isPublished={!!metadata.updatedUtc}
          data-testid="collection-info-box-section-options-buttons-container"
          margin={{ right: 'md' }}
        />
      </Box.Content>
      {showDeleteModal && (
        <ConfirmationDialog
          title="Delete Collection"
          description="Are you sure you want to delete this collection?"
          showDialog={setShowDeleteModal}
          onClickFunc={handleDeleteCollection}
          isLoading={isDeleteLoading}
          hint={deleteHint}
          disabled={!canDelete}
        />
      )}
      {offPlatformInputOpen && (
        <CollectionOffPlatformOverlay
          currentProperty={currentProperty}
          onDismiss={() => setOffPlatformInputOpen(false)}
          onConvert={handleOffPlatformConvert}
        />
      )}
    </Box>
  );
};

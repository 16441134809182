import { useState } from 'react';
import { Tabs, Wrapper } from '@screentone/core';

import PageDraftTrash from 'components/page-draft-trash/PageDraftTrash';
import { QueryRulesForm } from 'components/query-rules-form/QueryRulesForm';
import { EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { QueryItem } from 'data/generated/graphql';
import { SearchContent, SearchProps } from '../../../../components/search-content/SearchContent';

interface CollectionCurateSectionProps {
  searchProps: SearchProps;
  queryProps: {
    allessehJsonQuery?: string | null;
    queryItemIndex: number;
    totalItemsLength: number;
  };
}

export const CollectionCurateSection = ({ searchProps, queryProps }: CollectionCurateSectionProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { insertEntity, modifyEntity } = useDataModelContext();

  const handleAllessehJsonQueryChange = (newAllessehJsonQuery: string) => {
    if (queryProps.queryItemIndex >= 0) {
      const hierarchyId = `0-${queryProps.queryItemIndex}`;

      modifyEntity(hierarchyId, (entity) => {
        const query = entity as QueryItem;
        query.attributes.query = JSON.parse(newAllessehJsonQuery) as Record<string, unknown>;
      });
    } else {
      const hierarchyId = `0-${queryProps.totalItemsLength}`;
      const newQueryItem: QueryItem = {
        type: EntityType.Query,
        attributes: {
          query: JSON.parse(newAllessehJsonQuery) as Record<string, unknown>
        }
      };

      insertEntity(hierarchyId, newQueryItem);
    }
  };

  return (
    <>
      <Tabs data-testid="collection-curate-section-tabs" role="tablist" onChange={setTabIndex} value={tabIndex}>
        <Tabs.Item
          data-testid="collection-curate-section-tab-content-search"
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === 0 ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
        >
          Content Search
        </Tabs.Item>
        <Tabs.Item
          data-testid="collection-curate-section-tab-query"
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === 1 ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
        >
          Query
        </Tabs.Item>
      </Tabs>
      {tabIndex === 0 && (
        <Wrapper
          data-testid="collection-curate-section-collections-container"
          id="tabpanel-id0"
          role="tabpanel"
          aria-labelledby="tab-id0"
          padding={{ top: 'sm' }}
        >
          <SearchContent searchProps={searchProps} beforeContent={<PageDraftTrash messageLabel="collection" />} />
        </Wrapper>
      )}
      {tabIndex === 1 && (
        <Wrapper
          data-testid="collection-curate-section-query-container"
          id="tabpanel-id1"
          role="tabpanel"
          aria-labelledby="tab-id1"
          padding={{ top: 'sm' }}
        >
          <QueryRulesForm
            allessehJsonQuery={queryProps.allessehJsonQuery}
            onAllessehJsonQueryChange={handleAllessehJsonQueryChange}
          />
        </Wrapper>
      )}
    </>
  );
};

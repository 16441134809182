import { Button, IconEdit, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { LinkedItem } from 'data/generated/graphql';

interface EditLinkedItemProps {
  linkedItem: LinkedItem;
}

export const EditLinkedItem = ({ linkedItem }: EditLinkedItemProps) => {
  const { setIsEditingLinkedItem } = useDataModelContext();

  return (
    <Wrapper>
      <Button
        tertiary
        icon={IconEdit as SvgComponent}
        iconPosition="right"
        onClick={() => setIsEditingLinkedItem(linkedItem.attributes.hosted_url)}
      >
        Edit linked item
      </Button>
    </Wrapper>
  );
};

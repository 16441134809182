/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Button, Group, IconArticle, IconRefresh, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { ArticleItem, OffPlatformListContainer } from 'data/generated/graphql';
import { useElementDimensions } from 'hooks/dom/useElementDimensions';
import styles from './OffPlatform.module.scss';

interface OffPlatformProps extends CommonEntityProps {
  data: OffPlatformListContainer;
  isHistory: boolean;
}

const OffPlatform = ({ data, parentHierarchyId, index, isHistory }: OffPlatformProps) => {
  const [isOverflown, setIsOverflown] = useState(false);
  const { generateHierarchyId, renderEntity, removeAllByType } = useDataModelContext();
  const { renderActions } = useContextMenuActions();

  const { elementRef, dimensions } = useElementDimensions<HTMLDivElement>();

  const hierarchyId = generateHierarchyId(data, parentHierarchyId, index);

  useEffect(() => {
    if (!elementRef.current) return;
    const maxHeightStr = getComputedStyle(elementRef.current).maxHeight.replace('px', '');
    const maxHeight = Number(maxHeightStr);
    setIsOverflown(dimensions.scrollHeight > maxHeight);
  }, [dimensions.scrollHeight, elementRef]);

  return (
    <>
      {!isHistory && (
        <Typography variant="header3" margin={{ bottom: 'md' }} data-testid="off-platform-new-header">
          New
        </Typography>
      )}
      <Wrapper margin={{ bottom: 'md' }}>
        <div
          className={classnames(styles.itemsScrollable, {
            [styles.draftItems]: !isHistory,
            [styles.wrapperOverflow]: isOverflown
          })}
          ref={elementRef}
        >
          <Typography weight="bold" size="sm" margin={{ bottom: 'sm' }} data-testid="off-platform-display-name">
            {data.attributes.moduleDisplayName}
          </Typography>
          <Droppable
            droppableId={(isHistory ? DROPPABLE_ID_PREFIXES.HISTORY : DROPPABLE_ID_PREFIXES.OFF_PLATFORM) + hierarchyId}
            isDropDisabled={isHistory}
          >
            {(droppableProvided) => (
              <div
                className={classnames(styles.itemsList, {
                  [styles.draftItemsList]: !isHistory
                })}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {data.collection.map((collection, mapIndex) => {
                  const articleItem = collection as ArticleItem;
                  const articleHierarchyId = generateHierarchyId(articleItem, hierarchyId, mapIndex);

                  return (
                    <div key={mapIndex}>
                      {renderEntity(articleItem, {
                        hierarchyId,
                        index: mapIndex,
                        isHistory,
                        extraProps: {
                          article: {
                            renderActions: () =>
                              renderActions(MenuActionsKeys.DraftArticle, {
                                hierarchyId: articleHierarchyId,
                                index: mapIndex,
                                entity: articleItem
                              })
                          }
                        }
                      })}
                    </div>
                  );
                })}
                {droppableProvided.placeholder}
                {data.collection.length !== data.attributes.maxItems && (
                  <Wrapper
                    className={classnames(styles.itemsNumber, {
                      [styles.itemsNumberWithContent]: data.collection.length > 0
                    })}
                    padding={{ all: 'md' }}
                  >
                    <IconArticle color="asphalt" margin={{ right: 'xs' }} />
                    <Typography variant="note">
                      {data.collection.length}/{data.attributes.maxItems} articles
                    </Typography>
                  </Wrapper>
                )}
              </div>
            )}
          </Droppable>
        </div>
      </Wrapper>
      {!isHistory && (
        <Group align="end">
          <Button
            tertiary
            icon={IconRefresh as SvgComponent}
            color="lava"
            onClick={() => {
              removeAllByType(EntityType.Article);
            }}
            data-testid="off-platform-reset-to-default"
          >
            Reset to default
          </Button>
        </Group>
      )}
    </>
  );
};

export default OffPlatform;

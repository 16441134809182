import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Group, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { BannerDto } from 'data/generated/graphql';
import styles from './BannerSearch.module.scss';

const SMALL_WIDTH_THRESHOLD = 420;

interface CardProps {
  banner: BannerDto;
  containerWidth: number;
}

const Card = ({ banner, containerWidth }: CardProps) => {
  const { currentProperty } = useAuth();
  const navigate = useNavigate();

  const isSmall = containerWidth <= SMALL_WIDTH_THRESHOLD;

  const handleClick = (allessehId: string) => {
    navigate(`/${currentProperty ?? ''}/banners/${allessehId}`);
  };

  return (
    <Box
      padding={{ all: 'sm' }}
      onClick={() => handleClick(banner.metadata.allessehId)}
      className={classnames(styles.item)}
    >
      {!isSmall ? (
        <>
          <Group align="space-between" gap="none">
            <Typography>{banner.metadata.name}</Typography>
            <Wrapper>
              {banner.metadata.updatedUtc && (
                <Typography variant="note" inline>
                  Last published {`${format(banner.metadata.updatedUtc, 'MMM dd, yyyy')}`}
                </Typography>
              )}
            </Wrapper>
          </Group>
          <Group align="space-between" gap="none" margin={{ top: 'sm' }}>
            <Typography variant="note">{banner.metadata.allessehId || <div>&nbsp;</div>}</Typography>
          </Group>
        </>
      ) : (
        <>
          <Typography>{banner.metadata.name}</Typography>
          <Typography variant="note">{banner.metadata.allessehId || ''}</Typography>
          <Group align="space-between">
            <Wrapper>
              {banner.metadata.updatedUtc && (
                <Typography variant="note" inline margin={{ right: 'sm' }}>
                  {`${format(banner.metadata.updatedUtc, 'MMM dd, yyyy')}`}
                </Typography>
              )}
            </Wrapper>
          </Group>
        </>
      )}
    </Box>
  );
};

export default Card;

import { ModuleContainer } from 'data/generated/graphql';

export const defaultNewsletterModuleContainer: ModuleContainer = {
  collection: [],
  subtype: null,
  type: 'Module',
  attributes: {
    pageModule: {
      uiModuleFields: {
        basicModule: {
          treatmentType: 'AUDIENCE',
          contentTypes: ['article'],
          allowedTreatmentTypes: null
        }
      },
      uiModuleType: 'UiBasicModuleType'
    }
  }
};

export const DEBOUNCE_TIMEOUT = 500;

import { useState } from 'react';
import { Button, Group, IconTrash } from '@screentone/core';

import { ConfirmationDialog } from 'components';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { ScheduleDialog } from 'components/schedule-dialog/ScheduleDialog';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { OffPlatformListContainer } from 'data/generated/graphql';
import { useDeleteCollection } from 'hooks/collections/useDeleteCollection';
import { useIsAdmin } from 'hooks/roles';

interface PublishingButtonsProps {
  hasCollectionChanged: boolean;
  isPerformingAction: boolean;
  isInEditMode: boolean;
  onPublish: (publishUtc: number) => Promise<void>;
  onSchedule: (publishUtc: number) => Promise<void>;
  onCancelEdit: () => void;
  onDelete: () => Promise<void>;
}

const PublishingButtons = ({
  hasCollectionChanged,
  isPerformingAction,
  isInEditMode,
  onPublish,
  onSchedule,
  onCancelEdit,
  onDelete
}: PublishingButtonsProps) => {
  const { metadata } = useDataModelContext<OffPlatformListContainer>();

  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const isAdmin = useIsAdmin();

  const { showDeleteModal, setShowDeleteModal, canDelete, deleteHint, isDeleteLoading, handleDeleteCollection } =
    useDeleteCollection({
      collectionId: metadata.allessehId,
      variant: 'off-platform'
    });

  const toggleScheduleDialog = () => setShowScheduleModal((prev) => !prev);

  const handleSchedule = async (publishUtc: number) => {
    await onSchedule(publishUtc);
    setShowScheduleModal(false);
  };

  const handleDeleteScheduled = async () => {
    await onDelete();
  };

  const handlePublish = async (publishUtc: number) => {
    await onPublish(publishUtc);
  };

  const areButtonsDisabled = isPerformingAction || !hasCollectionChanged;

  return (
    <>
      {showScheduleModal && (
        <ScheduleDialog dialogTitle="Schedule module" onSchedule={handleSchedule} onCancel={toggleScheduleDialog} />
      )}
      <Group align="center" gap="md">
        {isAdmin && (
          <Button
            tertiary
            icon={IconTrash as SvgComponent}
            color="lava"
            data-testid="collection-info-box-section-options-delete-button"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        )}
        <ExternalLink
          contentId={metadata.allessehId}
          type={ExternalLinkContentType.COLLECTION}
          isPublished={!!metadata.updatedUtc}
        />
        {isInEditMode && (
          <>
            <Button
              tertiary
              disabled={isPerformingAction}
              onClick={handleDeleteScheduled}
              margin={{ right: 'sm' }}
              color="lava"
              icon={IconTrash as SvgComponent}
            >
              Delete Scheduled publish
            </Button>
            <Button secondary onClick={onCancelEdit} disabled={isPerformingAction} margin={{ right: 'sm' }}>
              Cancel Edit
            </Button>
          </>
        )}
        <Button
          secondary
          onClick={toggleScheduleDialog}
          disabled={areButtonsDisabled}
          data-testid="off-platform-schedule-button"
        >
          {isInEditMode ? 'Reschedule' : 'Schedule'}
        </Button>
        <Button primary onClick={handlePublish} disabled={areButtonsDisabled} data-testid="off-platform-publish-button">
          {isInEditMode ? 'Save' : 'Publish'}
        </Button>
      </Group>
      {showDeleteModal && (
        <ConfirmationDialog
          title="Delete Off-Platform Collection"
          description="Are you sure you want to delete this Off-Platform Collection?"
          showDialog={setShowDeleteModal}
          onClickFunc={handleDeleteCollection}
          isLoading={isDeleteLoading}
          hint={deleteHint}
          disabled={!canDelete}
        />
      )}
    </>
  );
};

export default PublishingButtons;

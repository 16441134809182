import { useNewsletterListItemsQuery } from 'data/generated/graphql';

export const useNewsletterTypes = ({
  publicationKey,
  newsletterId
}: {
  publicationKey: string;
  newsletterId?: string;
}) => {
  const { data: newslettersTypes, isFetching } = useNewsletterListItemsQuery({
    publicationKey
  });

  return {
    newsletterType: newslettersTypes?.getNewsletterList.find((item) => item.id === newsletterId),
    newsletterTypes: newslettersTypes?.getNewsletterList,
    isFetching
  };
};

import { RefObject, useEffect, useState } from 'react';

import { ControlledAltSumm, DEFAULT_MEDIA_ALT_SUMM } from 'components/altsumm-modal/AltSummModal.utils';
import { MediaTypes } from 'components/altsumm-modal/components/media-input/MediaInput.utils';

interface UseMediaPreviewProps {
  imagePreviewRef: RefObject<HTMLImageElement>;
  setIsIdFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPreviewUrl: (url: string) => void;
  imageUrl?: string;
  videoUrl?: string;
  chartUrl?: string;
  chartId?: string;
  setInputType: (string: MediaTypes | '') => void;
  setControlledAltSum: React.Dispatch<React.SetStateAction<ControlledAltSumm>>;
}

export const useMediaPreview = ({
  setIsIdFormOpen,
  setPreviewUrl,
  imagePreviewRef,
  imageUrl,
  videoUrl,
  chartUrl,
  chartId,
  setInputType,
  setControlledAltSum
}: UseMediaPreviewProps) => {
  const [previewType, setPreviewType] = useState<MediaTypes>();
  const [isMediaLoaded, setIsMediaLoaded] = useState(!videoUrl);
  const [computedAspectRatio, setComputedAspectRatio] = useState(1);

  useEffect(() => {
    if (imageUrl) {
      setPreviewType('image');
      setPreviewUrl(imageUrl);
    } else if (videoUrl) {
      setPreviewType('video');
      setPreviewUrl(videoUrl);
    } else if (chartUrl) {
      setPreviewType('chart');
      setPreviewUrl(chartUrl);
    } else {
      setPreviewType(undefined);
      setPreviewUrl('');
    }
  }, [imageUrl, videoUrl, chartUrl, chartId, setPreviewUrl, setIsIdFormOpen]);

  const clearPreview = () => {
    setControlledAltSum((prev) => ({
      ...prev,
      ...DEFAULT_MEDIA_ALT_SUMM
    }));
    setPreviewUrl('');
    setInputType('');
  };

  const onResetPreview = () => {
    setIsIdFormOpen((prev) => !prev);
    setPreviewUrl('');
    setInputType(previewType ?? '');
    setControlledAltSum((prev) => ({
      ...prev,
      ...DEFAULT_MEDIA_ALT_SUMM
    }));
  };

  const onImageLoad = () => {
    const { naturalWidth = 1, naturalHeight = 1 } = imagePreviewRef.current ?? {};
    setComputedAspectRatio(naturalWidth / naturalHeight);
    setIsMediaLoaded(true);
  };

  return {
    onImageLoad,
    clearPreview,
    onResetPreview,
    isMediaLoaded,
    computedAspectRatio,
    previewType
  };
};

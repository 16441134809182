import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { Article } from 'components/datamodel/content/Article/Article';
import PageDraftTrash from 'components/page-draft-trash/PageDraftTrash';
import { ShowFilterOptions } from 'components/search-content/components/search-content-filters/SearchContentFiltersContainer';
import { SearchContent, SearchParams } from 'components/search-content/SearchContent';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DRAGGABLE_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { useDragAndDrop } from 'contexts/drag-and-drop/useDragAndDrop';
import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes, AllessehQuerySortOrderType } from 'hooks/useAllessehContentQuery';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import { NEWSLETTER_FILTERS } from 'utils/newsletters';

const ContentSearch = ({
  resultListClassName,
  newsletterName
}: {
  resultListClassName?: string;
  newsletterName?: string;
}) => {
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { getAllArticleIds, fromAllessehContent } = useDataModelContext();
  const { renderActions } = useContextMenuActions();
  const { generateDraggableId } = useDragAndDrop();
  const currentProperty = useConvertedProperty();

  const excludedArticleIds = getAllArticleIds();

  // using useMemo here to prevent linting errors
  let additionalFilters = useMemo(() => ({}), []);

  if (newsletterName) {
    if (
      // eslint-disable-next-line
      NEWSLETTER_FILTERS[currentProperty as string] &&
      // eslint-disable-next-line
      NEWSLETTER_FILTERS[currentProperty as string][newsletterName]
    ) {
      additionalFilters = NEWSLETTER_FILTERS[currentProperty as string][newsletterName];
    }
  }

  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: excludedArticleIds,
      ...additionalFilters
    }),
    [publicationSettingsResp?.publicationSetting.defaultContentTypes, excludedArticleIds, additionalFilters]
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: AllessehQuerySortKeyTypes.LiveDate,
    sortOrder: AllessehQuerySortOrderType.DESC
  });

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (
      newSearchParams.filters.contentTypes.length > 0 &&
      publicationSettingsResp?.publicationSetting.defaultContentTypes && // ensure contentTypes list has loaded and has at least 1 item
      !isEqual(newSearchParams.filters.excludedContentIds, excludedArticleIds)
    ) {
      newSearchParams.filters.excludedContentIds = excludedArticleIds;
      setSearchParams(newSearchParams);
    }
  }, [excludedArticleIds, publicationSettingsResp?.publicationSetting.defaultContentTypes, searchParams]);

  const handleSearchParamsChange = (changedParams: SearchParams) => {
    setSearchParams(changedParams);
  };

  const showFilters: ShowFilterOptions = {
    contentType: false
  };

  return (
    <div>
      <SearchContent
        beforeContent={<PageDraftTrash />}
        searchProps={{
          searchParams,
          defaultFilterState,
          showFilters,
          onSearchParamsChange: handleSearchParamsChange,
          resultListClassName,
          renderContentCard: (content: AllessehContent, index: number) => (
            <Draggable draggableId={generateDraggableId(DRAGGABLE_PREFIXES.MODULE_ITEM, { content })} index={index}>
              {(draggableProvided) => {
                const articleItem = fromAllessehContent(content);
                return (
                  <>
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <Article
                        data={articleItem}
                        renderActions={() =>
                          renderActions(MenuActionsKeys.ArticleSearch, { entity: articleItem, hierarchyId: '0--' })
                        }
                      />
                    </div>
                  </>
                );
              }}
            </Draggable>
          )
        }}
      />
    </div>
  );
};

export default ContentSearch;

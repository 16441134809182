import { useMemo } from 'react';
import { Button, IconExternalLink, Tooltip } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { appEnv, buildLiveCoverageUrl, buildNewspressUrl, getArticleEnv } from 'utils/cms';

interface OpenInNewsPressProps {
  content: AllessehContent;
}

export const OpenInNewsPress = ({ content }: OpenInNewsPressProps) => {
  const newspressUrl = useMemo(
    () => buildNewspressUrl(content.data.id, content.data.attributes.type),
    [content.data.id, content.data.attributes.type]
  );

  const liveCoverageUrl = useMemo(
    () => buildLiveCoverageUrl(content.data.attributes.source_url, content.data.attributes.product),
    [content.data.attributes.source_url, content.data.attributes.product]
  );

  const handleViewNewsPressUrl = () => {
    if (!newspressUrl) return;
    window.open(newspressUrl, '_blank');
  };

  const handleViewLiveCoverageUrl = () => {
    if (!liveCoverageUrl) return;
    window.open(liveCoverageUrl, '_blank');
  };

  const articleEnv = getArticleEnv(content.data.attributes.source_url);

  const isLiveCoverage = content.data.attributes.product.includes('Live Coverage');
  return newspressUrl ? (
    <Button
      data-testid="newspress-button-open-cms-button"
      tertiary
      icon={IconExternalLink as SvgComponent}
      iconPosition="right"
      onClick={handleViewNewsPressUrl}
    >
      Open in CMS
    </Button>
  ) : isLiveCoverage ? (
    liveCoverageUrl ? (
      <Button
        data-testid="newspress-button-open-cms-button"
        tertiary
        icon={IconExternalLink as SvgComponent}
        iconPosition="right"
        onClick={handleViewLiveCoverageUrl}
      >
        Open in CMS
      </Button>
    ) : (
      <Tooltip data-testid="edit-alt-summ-option-tooltip">
        <Tooltip.Content
          data-testid="edit-alt-summ-option-tooltip-content"
          id="altSumModalTooltip"
          position="bottom"
          size="lg"
        >
          {!liveCoverageUrl &&
            `Disabled: The article belongs to the ${articleEnv} environment, which does not match the current ${appEnv} environment.`}
        </Tooltip.Content>
        <Tooltip.Trigger data-testid="edit-alt-summ-option-tooltip-trigger" aria-describedby="altSumModalTooltip">
          <Button
            data-testid="newspress-button-open-cms-button"
            tertiary
            icon={IconExternalLink as SvgComponent}
            iconPosition="right"
            onClick={handleViewLiveCoverageUrl}
            disabled={!liveCoverageUrl}
          >
            Open in CMS
          </Button>
        </Tooltip.Trigger>
      </Tooltip>
    )
  ) : null;
};

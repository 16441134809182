import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { Banners } from 'features/banners/Banners';

const BannersPage = () => (
  <>
    <PageRootContainer>
      <NarrowPageWrapper>
        <Banners />
      </NarrowPageWrapper>
    </PageRootContainer>
  </>
);

export default BannersPage;

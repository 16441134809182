import { useAuthToken } from 'hooks/useAuthToken';
import env from 'utils/env';
import { prettifyGraphQLBadRequestError } from 'utils/errors';

export class GraphqlError extends Error {}

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const authToken = useAuthToken();

  return async (variables?: TVariables) => {
    let res;
    try {
      res = await fetch(`${env.API_URL}/graphql`, {
        method: 'POST',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
          ...options
        },
        body: JSON.stringify({
          query,
          variables
        })
      });
    } catch (e) {
      console.error('Unhandled error in backend request: ', e);
      return null;
    }

    /* eslint-disable */
    const json = await res.json();

    // TODO: error message could be WAY better
    if (json.errors) {
      const { message, extensions } = json.errors[0] || {};
      if (extensions.code === 'BAD_USER_INPUT' && extensions.response) {
        // returned error message
        const detailedError = prettifyGraphQLBadRequestError(extensions.response.message);
        throw new GraphqlError(detailedError);
      } else {
        throw new Error(message || 'Error...');
      }
    }

    return json.data;
    /* eslint-enable */
  };
};
